import { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

// DS
import MapleButton from 'Components/ds/MapleButton';
import MapleDivider from 'Components/ds/MapleDivider';

// Components
import { ReactComponent as IconAccount } from 'Components/ds/assets/icon-account.svg';
import { ReactComponent as IconDown } from 'Components/ds/assets/icon-arrow-down.svg';

// Context
import useAuth from 'Hooks/useAuth';

interface AuthButtonProps {
  showMenu: boolean;
  setShowMenu: (arg: boolean) => void;
  setAnchorEl: (arg: any) => void;
  showLabel: boolean;
}

const AuthButton = ({ showMenu, setShowMenu, setAnchorEl, showLabel }: AuthButtonProps): ReactElement => {
  const { user: dynamicUser } = useDynamicContext();
  const { impersonated } = useAuth();
  const user = impersonated || dynamicUser;

  const { palette } = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setShowMenu(!showMenu);
  };

  return (
    <MapleButton
      data-testid='headerLoginButton'
      size='medium'
      variant='outlined'
      color='tertiary'
      onClick={e => handleClick(e)}
      endIcon={showLabel && <IconDown />}
      sx={showLabel ? {} : { py: '5px', px: '10px' }}
      fullWidth
    >
      <Stack direction='row' spacing={1}>
        <Stack sx={{ svg: { fill: `${palette.ds.success[500]} !important` } }}>
          <IconAccount fill={palette.ds.success[500]} />
        </Stack>
        {showLabel && (
          <Stack direction='row'>
            <Stack sx={{ pr: 1 }}>{user?.email}</Stack>
            <MapleDivider orientation='vertical' />
          </Stack>
        )}
      </Stack>
    </MapleButton>
  );
};

export default AuthButton;
