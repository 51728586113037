import { useRef, useState, useLayoutEffect } from 'react';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { styled } from '@mui/material/styles';

// Styles
import { zIndex } from 'Components/ds/MapleTheme/designSystem';

const styles: { [key: string]: SxProps } = {
  gradientBoxTop: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '70px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    pointerEvents: 'none',
    zIndex: zIndex.low,
  },
  gradientBoxBottom: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '70px',
    background: 'linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    pointerEvents: 'none',
    zIndex: zIndex.low,
  },
};

const StyledCardContentWrapper = styled(Box)(() => ({
  position: 'relative',
}));

const StyledCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
  overflowX: 'visible',
  padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(3)} ${theme.spacing(4)}`,
}));

const MapleCardContent = ({ sx, children, ...props }: CardContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [displayGradientTopBox, setDisplayGradientTopBox] = useState(false);
  const [displayGradientBottomBox, setDisplayGradientBottomBox] = useState(false);
  const [unavailableSpace, setUnavailableSpace] = useState<boolean | number>(false);

  const handleScroll = ({ target }: any) => {
    const { scrollHeight, scrollTop, clientHeight } = target;

    const isBottom = Math.trunc(scrollHeight - scrollTop) === clientHeight;
    setDisplayGradientTopBox(scrollTop > 0);
    setDisplayGradientBottomBox(!isBottom);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      const $dialog = ref.current.closest('.MuiDialog-root');
      if ($dialog) {
        const outerDialogSpace = 72; // Space around the tx dialog
        const innerDialogSpace = 72; // Padding of the tx dialog
        const stepsSpace = 96; // Space of the tx dialog steps
        const buttonsSpace = 64; // Space of the tx dialog buttons
        const titleSpace = 100; // Space of the tx dialog title
        setUnavailableSpace(outerDialogSpace + innerDialogSpace + stepsSpace + buttonsSpace + titleSpace);
      }
    }
  }, [ref, children]);

  useLayoutEffect(() => {
    if (ref) {
      const scrollHeight = ref.current?.scrollHeight || 0;
      const clientHeight = ref.current?.clientHeight || 0;
      setDisplayGradientBottomBox(scrollHeight > clientHeight);
    }
  }, [ref, children]);

  return (
    <StyledCardContentWrapper ref={ref}>
      {displayGradientTopBox && <Box sx={styles.gradientBoxTop} />}
      {displayGradientBottomBox && <Box sx={styles.gradientBoxBottom} />}
      <StyledCardContent
        onScroll={handleScroll}
        sx={{
          ...sx,
          maxHeight: unavailableSpace ? `calc(100vh - ${unavailableSpace}px)` : 'auto',
          overflowY: unavailableSpace ? 'scroll' : 'visible',
        }}
        {...props}
      >
        {children}
      </StyledCardContent>
    </StyledCardContentWrapper>
  );
};

export default MapleCardContent;
