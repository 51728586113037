import { ReactElement } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components
import Typography, { StyledTypographyProps } from 'Components/ds/Typography';

// Utils
import { splitNumber } from 'Utils/numbers';

export interface ValueUnitV2Props {
  value: string;
  unit?: string;
  alignment?: 'left' | 'center' | 'right';
  variant?: 'paragraphMedium' | 'paragraphLarge' | 'h5';
  icon?: ReactElement;
}

const alignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

type VariantsType = {
  [key: string]: {
    integer: Pick<StyledTypographyProps, 'variant' | 'weight'>;
    dot: Pick<StyledTypographyProps, 'variant' | 'weight'>;
    decimal: Pick<StyledTypographyProps, 'variant' | 'weight'>;
    unit: Pick<StyledTypographyProps, 'variant' | 'weight'>;
  };
};

const variantsMap: VariantsType = {
  paragraphMedium: {
    integer: { variant: 'paragraphMedium', weight: 'bold' },
    dot: { variant: 'paragraphMedium', weight: 'regular' },
    decimal: { variant: 'paragraphMedium', weight: 'bold' },
    unit: { variant: 'paragraphMedium', weight: 'bold' },
  },
  paragraphLarge: {
    integer: { variant: 'paragraphLarge', weight: 'bold' },
    dot: { variant: 'paragraphLarge', weight: 'regular' },
    decimal: { variant: 'paragraphSmall', weight: 'regular' },
    unit: { variant: 'paragraphLarge', weight: 'bold' },
  },
  h5: {
    integer: { variant: 'h5', weight: 'extraBold' },
    dot: { variant: 'h6', weight: 'regular' },
    decimal: { variant: 'h7', weight: 'regular' },
    unit: { variant: 'h5', weight: 'regular' },
  },
};

const ValueUnitV2 = ({ value, unit, variant = 'paragraphLarge', alignment = 'right', icon }: ValueUnitV2Props) => {
  const { integer, dot, decimal } = splitNumber(value);
  const integerProps = variantsMap[variant].integer;
  const dotProps = variantsMap[variant].dot;
  const decimalProps = variantsMap[variant].decimal;
  const unitProps = variantsMap[variant].unit;

  return (
    <Box component='span' display='inline-block'>
      <Stack direction='row' alignItems='center' justifyContent={alignmentMap[alignment]} spacing={0.5}>
        <Stack direction='row' justifyContent='flex-start' alignItems='baseline'>
          <Typography variant={integerProps.variant} weight={integerProps.weight} component='span'>
            {integer}
          </Typography>
          {dot && (
            <Typography variant={dotProps.variant} weight={integerProps.weight} component='span'>
              {dot}
            </Typography>
          )}
          {decimal && (
            <Typography variant={decimalProps.variant} weight={decimalProps.weight} component='span'>
              {decimal}
            </Typography>
          )}
        </Stack>

        {unit && (
          <Typography variant={unitProps.variant} weight={unitProps.weight} component='span'>
            {unit}
          </Typography>
        )}
        {icon && <Box>{icon}</Box>}
      </Stack>
    </Box>
  );
};

export default ValueUnitV2;
