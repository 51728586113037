import { Web3ReactProvider } from '@web3-react/core';
import { Connector } from '@web3-react/types';
import { ReactNode, useEffect } from 'react';

// Constants
import { CHAIN_ID, WEB3_CONNECT_CACHED_PROVIDER } from 'Constants';

// Web3
import { ConnectionType, getConnection, PRIORITIZED_CONNECTORS } from 'Services/web3/connectors';

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate(CHAIN_ID);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.debug(`web3-react eager connection error: ${error}`);
  }
}

const eagerConnect = async () => {
  await connect(getConnection(ConnectionType.GNOSIS_SAFE).connector);

  const persistedConnection = localStorage.getItem(WEB3_CONNECT_CACHED_PROVIDER) as ConnectionType;
  if (!persistedConnection) return;

  await connect(getConnection(ConnectionType.WALLET_CONNECT).connector);
  await connect(getConnection(ConnectionType.COINBASE).connector);
  await connect(getConnection(ConnectionType.INJECTED).connector);
};

export const Web3ContextProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    eagerConnect();
  }, []);

  return (
    <Web3ReactProvider
      connectors={Object.values(PRIORITIZED_CONNECTORS).map(connector => [connector.connector, connector.hooks])}
    >
      {children}
    </Web3ReactProvider>
  );
};
