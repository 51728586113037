import { Fragment, ReactElement } from 'react';
import Box from '@mui/material/Box';
import { TypographyProps } from '@mui/material/Typography';

// Components
import AddressLink, { AddressLinkProps } from 'Components/AddressLink';
import InfoTooltip from 'Components/ds/InfoTooltip';
import MapleLink, { MapleLinkProps } from 'Components/ds/MapleLink';
import Typography, { StyledTypographyProps } from 'Components/ds/Typography';
import ValueUnitV2, { ValueUnitV2Props } from 'Components/ValueUnitV2';

interface CommonProps {
  copy: string;
}
interface CopyItem extends CommonProps {
  type: 'copy';
  tooltip?: string | ReactElement;
  tooltipColor?: 'normal' | 'link';
  localWeight?: StyledTypographyProps['weight'];
  localColor?: StyledTypographyProps['color'];
  localVariant?: StyledTypographyProps['variant'];
}
interface LinkItem extends CommonProps {
  type: 'link';
  url: string;
  localColor?: MapleLinkProps['link'];
  onClick?: () => void;
}
interface AddressLinkItem extends AddressLinkProps {
  type: 'address';
  onClick?: () => void;
}
interface Icon {
  type: 'icon';
  icon: ReactElement;
  url?: string;
  openInNewTab?: boolean;
  localColor?: StyledTypographyProps['color'];
}
interface BlockItem extends CommonProps {
  type: 'block';
}
interface ValueUnitV2Item extends CommonProps, Omit<ValueUnitV2Props, 'value'> {
  type: 'valueUnitV2';
  unit?: string;
  alignment?: ValueUnitV2Props['alignment'];
  variant?: ValueUnitV2Props['variant'];
  icon?: ReactElement;
}

export type TypographyItemProps = CopyItem | LinkItem | AddressLinkItem | Icon | BlockItem | ValueUnitV2Item;

export interface TypographyComplexProps {
  items: TypographyItemProps[];
  variant: StyledTypographyProps['variant'];
  globalWeight: StyledTypographyProps['weight'];
  component?: TypographyProps['component'];
  align?: StyledTypographyProps['align'];
  color?: StyledTypographyProps['color'];
  display?: TypographyProps['display'];
  inset?: Boolean;
}

const TypographyComplex = ({
  items,
  variant,
  globalWeight,
  component = 'p',
  align = 'center',
  color = 'ds.text.primary',
  display = 'inline-block',
  inset = true,
}: TypographyComplexProps) => {
  const getTypographyItem = (props: TypographyItemProps, index: number) => {
    if (props.type === 'copy') {
      const { copy, tooltip, localWeight, localColor, tooltipColor, localVariant } = props;

      let theTooltipColor = 'ds.primary.500';

      if (tooltipColor === 'normal') {
        theTooltipColor = 'ds.neutral.400';
      }

      return (
        <Typography
          component='span'
          variant={localVariant ? localVariant : variant}
          weight={localWeight ? localWeight : globalWeight}
          sx={{
            ...(!inset && index === 0
              ? {
                  mr: '2px',
                }
              : {
                  mx: '2px',
                }),
          }}
          color={localColor}
        >
          {copy}
          {tooltip && <InfoTooltip title={tooltip} color={theTooltipColor} textTooltip />}
        </Typography>
      );
    }

    if (props.type === 'link') {
      const { copy, url, localColor, onClick } = props;

      return (
        <MapleLink
          href={url}
          variant={variant}
          link={localColor || 'info'}
          target='_blank'
          rel='noopener noreferrer'
          onClick={onClick}
        >
          {copy}
        </MapleLink>
      );
    }

    if (props.type === 'address') {
      const { address, ...rest } = props;

      return <AddressLink variant={variant} address={address} {...rest} component='span' />;
    }

    if (props.type === 'icon') {
      const { url, icon, localColor, openInNewTab } = props;
      const newTabProps = openInNewTab ? { target: '_blank', rel: 'noopener noreferrer' } : {};

      const IconComponent = (offset = false) => {
        return (
          <Box
            component='span'
            sx={{
              height: '15px',
              width: '15px',
              mx: '2px',
              display: 'inline-block',
              svg: { fill: localColor },
              ...(offset && {
                position: 'relative',
                top: '2px',
                ml: 0.75,
              }),
            }}
          >
            {icon}
          </Box>
        );
      };

      return (
        <>
          {url ? (
            <MapleLink href={url} variant={variant} link='info' {...newTabProps}>
              {IconComponent(false)}
            </MapleLink>
          ) : (
            IconComponent(true)
          )}
        </>
      );
    }

    if (props.type === 'block') {
      const { copy } = props;

      return (
        <Typography component='span' variant={variant} sx={{ display: 'block', my: 1 }}>
          {copy}
        </Typography>
      );
    }

    if (props.type === 'valueUnitV2') {
      const { copy, unit, alignment, variant, icon } = props;

      return <ValueUnitV2 value={copy} unit={unit} alignment={alignment ?? 'left'} variant={variant} icon={icon} />;
    }
  };

  return (
    <Typography component={component} sx={{ width: '100%' }} align={align} color={color} display={display}>
      {items.map((props: TypographyItemProps, i) => (
        <Fragment key={i}>{getTypographyItem(props, i)}</Fragment>
      ))}
    </Typography>
  );
};

export default TypographyComplex;
