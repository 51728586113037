import Accordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';

const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
  background: 'transparent',
  borderBottom: `1px solid ${theme.palette.ds.neutral[200]}`,
  boxShadow: 'none',
  padding: theme.spacing(2),
  ':before': {
    height: 0,
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordionSummary-content': {
    margin: 0,
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    minHeight: 'auto',
    padding: 0,
    margin: 0,
  },
}));

export default StyledAccordion;
