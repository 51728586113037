import { ReactElement, cloneElement } from 'react';
import Link, { LinkProps } from '@mui/material/Link';
import { styled } from '@mui/material/styles';

// Fonts
import { StyledTypographyProps } from 'Components/ds/Typography';
import { ValueSansPro, Nexa } from 'Components/ds/MapleTheme/typography';

type Color = 'primary' | 'secondary' | 'tertiary' | 'info' | 'warning' | 'warningAlt' | 'success';
export interface MapleLinkProps extends LinkProps {
  startIcon?: ReactElement | null;
  endIcon?: ReactElement | null;
  link?: 'inherit' | Color;
  variant?: StyledTypographyProps['variant'];
}

interface LinksStylesInterface {
  text: string;
}

interface GenerateStylesProps {
  active: LinksStylesInterface;
  hover: LinksStylesInterface;
  focus: LinksStylesInterface;
  disabled: LinksStylesInterface;
}

const getStyles = (theme: any) => {
  const mediaQuery = theme.breakpoints.up('medium');

  return {
    defaultHeading: {
      fontFamily: Nexa,
    },
    defaultParagraph: {
      fontFamily: ValueSansPro,
      letterSpacing: '-0.02em',
    },
    displayLarge: {
      fontSize: '52px',
      lineHeight: '56px',
    },
    displaySmall: {
      fontSize: '44px',
      lineHeight: '48px',
    },
    h1: {
      fontSize: '36px',
      lineHeight: '44px',
      [mediaQuery]: {
        fontSize: '40px',
        lineHeight: '48px',
      },
    },
    h2: {
      fontSize: '32px',
      lineHeight: '40px',
      [mediaQuery]: {
        fontSize: '36px',
        lineHeight: '44px',
      },
    },
    h3: {
      fontSize: '28px',
      lineHeight: '36px',
      [mediaQuery]: {
        fontSize: '32px',
        lineHeight: '40px',
      },
    },
    h4: {
      fontSize: '24px',
      lineHeight: '32px',
      [mediaQuery]: {
        fontSize: '28px',
        lineHeight: '36px',
      },
    },
    h5: {
      fontSize: '20px',
      lineHeight: '28px',
      [mediaQuery]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    h6: {
      fontSize: '18px',
      lineHeight: '24px',
      [mediaQuery]: {
        fontSize: '20px',
        lineHeight: '28px',
      },
    },
    paragraphLarge: {
      fontSize: '18px',
      lineHeight: '28px',
    },
    paragraphMedium: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    paragraphSmall: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    paragraphXSmall: {
      fontSize: '12px',
      lineHeight: '20px',
    },
    overline: {
      fontSize: '14px',
      lineHeight: '12px',
    },
  };
};

const generateLinkStyles = ({ active, hover, focus, disabled }: GenerateStylesProps) => {
  return {
    color: active.text,
    textDecorationColor: active.text,
    '& svg': {
      fill: active.text,
    },
    '&:hover': {
      color: hover.text,
      textDecorationColor: hover.text,
      '& svg': {
        fill: hover.text,
      },
    },
    '&:focus': {
      color: focus.text,
      textDecorationColor: focus.text,
      '& svg': {
        fill: focus.text,
      },
    },
    '&.Mui-disabled': {
      color: disabled.text,
      textDecorationColor: disabled.text,
      opacity: 1,
      '& svg': {
        fill: disabled.text,
      },
    },
  };
};

const textSystemColor = (theme: any, color: Color) => {
  const stateColor = {
    primary: {
      active: theme.palette.ds.primary[500],
      hover: theme.palette.ds.primary[600],
      focus: theme.palette.ds.primary[700],
    },
    secondary: {
      active: theme.palette.ds.secondary[300],
      hover: theme.palette.ds.secondary[400],
      focus: theme.palette.ds.secondary[500],
    },
    tertiary: {
      active: theme.palette.ds.neutral[700],
      hover: theme.palette.ds.neutral[800],
      focus: theme.palette.ds.neutral[900],
    },
    info: {
      active: theme.palette.ds.info[500],
      hover: theme.palette.ds.info[600],
      focus: theme.palette.ds.info[700],
    },
    success: {
      active: theme.palette.ds.success[500],
      hover: theme.palette.ds.success[600],
      focus: theme.palette.ds.success[700],
    },
    warning: {
      active: theme.palette.ds.warning[500],
      hover: theme.palette.ds.warning[600],
      focus: theme.palette.ds.warning[700],
    },
    warningAlt: {
      active: theme.palette.ds.warningAlt[500],
      hover: theme.palette.ds.warningAlt[600],
      focus: theme.palette.ds.warningAlt[700],
    },
  };

  return generateLinkStyles({
    active: { text: stateColor[color].active },
    hover: { text: stateColor[color].hover },
    focus: { text: stateColor[color].focus },
    disabled: { text: theme.palette.ds.neutral[400] },
  });
};

const StyledLink = styled(Link)<MapleLinkProps>(({
  link = 'primary',
  variant = 'paragraphMedium',
  startIcon,
  endIcon,
  theme,
}) => {
  const styles = getStyles(theme);
  const defaultStyles =
    variant.includes('paragraph') || variant === 'overline' ? styles.defaultParagraph : styles.defaultHeading;

  return {
    // Variant Prop
    cursor: 'pointer',

    ...defaultStyles,
    ...(variant === 'displayLarge' && styles.displayLarge),
    ...(variant === 'displaySmall' && styles.displaySmall),
    ...(variant === 'h1' && styles.h1),
    ...(variant === 'h2' && styles.h2),
    ...(variant === 'h3' && styles.h3),
    ...(variant === 'h4' && styles.h4),
    ...(variant === 'h5' && styles.h5),
    ...(variant === 'h6' && styles.h6),
    ...(variant === 'paragraphLarge' && styles.paragraphLarge),
    ...(variant === 'paragraphMedium' && styles.paragraphMedium),
    ...(variant === 'paragraphSmall' && styles.paragraphSmall),
    ...(variant === 'paragraphXSmall' && styles.paragraphXSmall),
    // Color Prop
    ...(link === 'primary' && textSystemColor(theme, 'primary')),
    ...(link === 'secondary' && textSystemColor(theme, 'secondary')),
    ...(link === 'tertiary' && textSystemColor(theme, 'tertiary')),
    ...(link === 'info' && textSystemColor(theme, 'info')),
    ...(link === 'success' && textSystemColor(theme, 'success')),
    ...(link === 'warning' && textSystemColor(theme, 'warning')),
    ...(link === 'warningAlt' && textSystemColor(theme, 'warningAlt')),
    ...(link === 'inherit' &&
      generateLinkStyles({
        active: { text: 'inherit' },
        hover: { text: 'inherit' },
        focus: { text: 'inherit' },
        disabled: { text: 'inherit' },
      })),
    // Icons Styles
    ...(startIcon || endIcon ? { display: 'inlineFlex', alignItems: 'center' } : {}),
    '& .maple-link__start-icon': {
      marginRight: '6px',
      verticalAlign: 'middle',
    },
    '& .maple-link__end-icon': {
      marginLeft: '6px',
      verticalAlign: 'middle',
    },
  };
});

const MapleLink = ({ startIcon, endIcon, children, ...props }: MapleLinkProps) => {
  // Clone startIcon and endIcon to add a specific class
  const clonedStartIcon = startIcon && cloneElement(startIcon, { className: 'maple-link__start-icon' });
  const clonedEndIcon = endIcon && cloneElement(endIcon, { className: 'maple-link__end-icon' });

  return (
    <StyledLink {...props}>
      {clonedStartIcon}
      {children}
      {clonedEndIcon}
    </StyledLink>
  );
};

export default MapleLink;
