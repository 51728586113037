import { MouseEvent, useEffect, useMemo, useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useLocation } from 'react-router-dom';
import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';

// Assets
import { ReactComponent as IconDoubleArrow } from 'Components/ds/assets/icon-chevron-double-left.svg';
import { ReactComponent as IconExplorePools } from 'Components/ds/assets/icon-compass-circle.svg';
import { ReactComponent as IconDashboard } from 'Components/ds/assets/icon-dashboard.svg';
import { ReactComponent as IconAccount } from 'Components/ds/assets/icon-user-outlined.svg';
import { ReactComponent as IconAccountManagement } from 'Components/ds/assets/icon-cog.svg';
import { ReactComponent as IconDocument } from 'Components/ds/assets/icon-document-text.svg';
import { ReactComponent as XMPLIcon } from 'Components/ds/assets/icon-xmpl.svg';
import { ReactComponent as IconSyrup } from 'Components/ds/assets/icon-syrup.svg';
import { ReactComponent as IconAccountSignOut } from 'Components/ds/assets/icon-sign-out.svg';

// Components
import NavigationLink from 'Components/ds/NavigationLink';
import SidebarMobile from 'Components/ds/SidebarMobile';
import SidebarMapleIcon from 'Components/ds/SidebarMapleIcon';
import MapleDivider from 'Components/ds/MapleDivider';
import PageIcon from 'Components/PageIcon';
import MapleButton from 'Components/ds/MapleButton';

// Constants
import { isBaseDeployment, isConvertEnabled } from 'Constants';

// Contexts
import { UserDataContext } from 'Context/UserData/UserData';

// Styles
import { shadow, zIndex } from 'Components/ds/MapleTheme/designSystem';

// Hooks
import useAuth from 'Hooks/useAuth';

interface WrapperProps extends StackProps {
  isExpanded: Boolean;
  isDesktopDevice?: Boolean;
}

// Utility function to filter out non-DOM props
const shouldForwardProp = (prop: string) => prop !== 'isExpanded' && prop !== 'isDesktopDevice';

const ComponentWrapper = styled(Stack, { shouldForwardProp })<WrapperProps>(({
  isDesktopDevice,
  isExpanded,
  theme,
}) => {
  const desktopStyles = {
    height: '100vh',
    width: isExpanded ? '320px' : '65px',
    top: 0,
    left: 0,
    borderRight: `1px solid ${theme.palette.ds.neutral[200]}`,
  };

  const mobileStyles = {
    height: `calc(100vh - ${theme.spacing(6.75)})`,
    width: '260px',
    top: theme.spacing(7),
    right: 0,
    borderLeft: `1px solid ${theme.palette.ds.neutral[200]}`,
    transform: isExpanded ? 'translateX(0px)' : 'translateX(260px)',
  };

  return {
    ...(isDesktopDevice ? desktopStyles : mobileStyles),
    backgroundColor: theme.palette.ds.background.primary,
    position: 'fixed',
    zIndex: zIndex.high,
    cursor: 'pointer',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
    transition: 'width 0.2s, transform 0.2s',
    '&:hover': {
      boxShadow: shadow.medium,
      '.expand-arrow': {
        borderColor: theme.palette.ds.info[500],
        svg: {
          stroke: theme.palette.ds.info[500],
        },
      },
    },
  };
});

const LinksWrapper = styled(Stack, { shouldForwardProp })<WrapperProps>(({ isExpanded, isDesktopDevice, theme }) => {
  const desktopPadding = isExpanded ? theme.spacing(3) : theme.spacing(1.5);
  const mobilePadding = theme.spacing(3);
  const transition = 'padding 0.2s';

  if (isDesktopDevice) return { paddingLeft: desktopPadding, paddingRight: desktopPadding, transition };

  return { paddingLeft: mobilePadding, paddingRight: mobilePadding };
});

const OpenButton = styled(Stack, { shouldForwardProp })<WrapperProps>(({ isExpanded, theme }) => ({
  backgroundColor: theme.palette.ds.background.primary,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.palette.ds.neutral[300],
  boxShadow: shadow.medium,
  width: '30px',
  height: '30px',
  borderRadius: '24px',
  cursor: 'pointer',
  position: 'absolute',
  top: 'calc(50% - 15px)',
  right: '-15px',
  transform: isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
  '&:hover': {
    borderColor: theme.palette.ds.info[500],
    svg: {
      stroke: theme.palette.ds.info[500],
    },
  },
}));

export const BlackLayer = styled(Box)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  zIndex: zIndex.medium,
}));

const NavigationSidebar = ({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => void;
}) => {
  const location = useLocation();
  const theme = useTheme();
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));
  const { isAuthenticated, logout } = useAuth();
  const { account } = useWeb3React();
  const { org, trackLenderEvent } = useContext(UserDataContext);

  const navigationLinks = useMemo(() => {
    const managementLink = org
      ? { to: '/v2/lend/legal-entity', text: 'Legal Entity' }
      : { to: '/v2/lend/management', text: 'Account Management' };

    const items = [
      // Conditionally add the Dashboard item if authenticated
      ...(isAuthenticated
        ? [
            {
              to: '/v2/lend/dashboard',
              text: 'Dashboard',
              icon: <IconDashboard width={20} height={20} />,
            },
          ]
        : []),
      // This item is always present
      {
        to: '/v2/lend/pools',
        text: 'Explore Pools',
        icon: <IconExplorePools />,
      },
      // Conditionally add the Account Overview item if authenticated
      ...(isAuthenticated || account
        ? [
            {
              to: '/v2/lend/account',
              text: 'Account Overview',
              icon: <IconAccount />,
            },
          ]
        : []),
      // Conditionally add the Management Link item if authenticated
      ...(isAuthenticated
        ? [
            {
              ...managementLink,
              icon: <IconAccountManagement />,
            },
          ]
        : []),
    ];

    return items;
  }, [org, account, isAuthenticated]);

  const staticNavigationLinks = [
    {
      to: '/v2/borrow',
      text: 'Borrower Dashboard',
      icon: <PageIcon size='small' type='letter' letter='B' />,
    },
    {
      to: '/v2/delegate',
      text: 'Delegate Dashboard',
      icon: <PageIcon size='small' type='letter' letter='D' />,
    },
    ...(!isBaseDeployment && !isConvertEnabled ? [{ to: '/xmpl', text: 'XMPL', icon: <XMPLIcon /> }] : []),
    ...(!isBaseDeployment && isConvertEnabled
      ? [{ to: 'https://syrup.fi/convert', text: 'Syrup', icon: <IconSyrup />, target: '_blank' }]
      : []),
    {
      to: 'https://maplefinance.gitbook.io/maple/',
      text: 'Documentation',
      icon: <IconDocument />,
      target: '_blank',
    },
  ];

  useEffect(() => {
    setIsExpanded(false);
  }, [location]);

  const handleOnClickSidebar = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLElement;
    if (target.id === 'navigation-sidebar' && !isExpanded) {
      setIsExpanded(true);
    }
  };

  return (
    <>
      {!isDesktopDevice && <SidebarMobile isExpanded={isExpanded} setIsExpanded={setIsExpanded} />}

      <ComponentWrapper
        isExpanded={isExpanded}
        isDesktopDevice={isDesktopDevice}
        justifyContent='space-between'
        onClick={handleOnClickSidebar}
        id='navigation-sidebar'
      >
        {/* these are the controls to open / close the sidebar */}
        {isDesktopDevice && (
          <OpenButton
            alignItems='center'
            justifyContent='center'
            onClick={() => setIsExpanded(!isExpanded)}
            isExpanded={isExpanded}
            className='expand-arrow'
          >
            <IconDoubleArrow width={20} height={20} />
          </OpenButton>
        )}

        {/* this is the actual sidebar content */}
        <LinksWrapper spacing={1} isExpanded={isExpanded} isDesktopDevice={isDesktopDevice}>
          {/* Logo part of component (only display in desktop devices) */}

          {isDesktopDevice && (
            <Box sx={{ mb: '32px !important' }}>
              <SidebarMapleIcon isExpanded={isExpanded} />
            </Box>
          )}

          {/* User role select */}
          {/* we dont need this at the moment, but it might get added back in */}
          {/* <SidebarRoleSelect variant={variant} /> */}

          <Stack spacing={1}>
            {navigationLinks.length > 0 && (
              <Stack spacing={1}>
                {navigationLinks.map(({ text, to, icon }, index) => (
                  <NavigationLink
                    isExpanded={isExpanded}
                    key={`${to}-${index}`}
                    text={text}
                    to={to}
                    icon={icon}
                    onClick={() =>
                      isExpanded
                        ? trackLenderEvent(`Lender - Sidebar Expanded - ${text}`)
                        : trackLenderEvent(`Lender - Sidebar Collapsed - ${text}`)
                    }
                  />
                ))}
              </Stack>
            )}
            <MapleDivider />

            {staticNavigationLinks.map(({ text, to, icon, target }, index) => (
              <NavigationLink
                isExpanded={isExpanded}
                key={`${to}-${index}`}
                target={target}
                text={text}
                to={to}
                icon={icon}
              />
            ))}
          </Stack>
        </LinksWrapper>
        {!isDesktopDevice && (
          <Box sx={{ p: 3, width: '100%' }}>
            <MapleButton
              variant='outlined'
              size='medium'
              color='warningAlt'
              startIcon={<IconAccountSignOut />}
              onClick={() => logout()}
              fullWidth
            >
              Sign Out
            </MapleButton>
          </Box>
        )}
      </ComponentWrapper>
      {isExpanded && <BlackLayer onClick={() => setIsExpanded(!isExpanded)} />}
    </>
  );
};

export default NavigationSidebar;
