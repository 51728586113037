import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits, parseUnits } from '@ethersproject/units';

// Constants
import { PERCENTAGE_UI_DECIMALS, STABLECOIN_DECIMALS, WEI_DECIMALS, SECONDS_IN_DAY } from 'Constants';

// Utils
import { isStablecoin } from './assets';
import { buildValueInterfaceFromParsedValues } from 'Utils/valueInterface';
import { formatNumber } from './form';

// Types
import { ValueInterface } from 'Shared/interfaces';

export const convertPercentToValue = (num: number, decimalsToShow: number = PERCENTAGE_UI_DECIMALS): number => {
  const percentageValue = num * 100; // e.g. 0.051234 = 5.1234%
  const factor = Math.pow(10, decimalsToShow);
  const roundedValue = Math.round(percentageValue * factor) / factor;

  return +roundedValue.toFixed(decimalsToShow);
};

export const getFormattedNumber = ({
  bigNumber,
  decimals,
  decimalsToShow = 0,
}: {
  bigNumber: BigNumber;
  decimals: number;
  decimalsToShow?: number;
}) => {
  const formatUnitsString = formatUnits(bigNumber, decimals);
  const formatUnitsStringWithTrailingZeroes = addTrailingZerosToDecimalNumber(formatUnitsString, decimalsToShow);

  return formatNumber(formatUnitsStringWithTrailingZeroes, decimalsToShow);
};

export const addTrailingZerosToDecimalNumber = (decimalNumber: string, decimalsToShow: number) => {
  if (decimalsToShow > 0) {
    const isNotDecimalNumber = !decimalNumber.includes('.');
    if (isNotDecimalNumber) decimalNumber += '.0';
    const splitDecimal = decimalNumber.split('.');
    let fractionalNumbers = splitDecimal[1];
    if (fractionalNumbers.length < decimalsToShow) {
      while (fractionalNumbers.length < decimalsToShow) fractionalNumbers += '0';
      const integralNumbers = splitDecimal[0];
      decimalNumber = [integralNumbers, fractionalNumbers].join('.');
    }
  }

  return decimalNumber;
};

export const splitNumber = (number: string): { integer: string; dot: string; decimal: string } => {
  const [integer, decimal] = number.split('.');

  return {
    integer: integer ? integer : '',
    dot: decimal ? '.' : '',
    decimal: decimal ? decimal : '',
  };
};

export const i18nNumberFormat = (value: number, decimals = 0, language = 'en'): string =>
  new Intl.NumberFormat(language, { maximumFractionDigits: decimals }).format(value);

const getDecimals = (decimals: number, decimalNumber: string) => {
  if (decimals === 0) {
    return '';
  } else {
    return decimalNumber.substr(0, decimals);
  }
};

export const i18nStringifiedNumberFormat = (value: string, decimals = 0, language = 'en'): string => {
  const [integerNumber, decimalNumber] = value.replaceAll(',', '').split('.');

  if (decimalNumber) {
    const decimalSeparator = Intl.NumberFormat(language)
      .formatToParts(1.1)
      .find(({ type }) => type === 'decimal');
    const theDecimals = getDecimals(decimals, decimalNumber);

    const formatted = Intl.NumberFormat(language).format(+integerNumber);

    if (decimals === 0) {
      return `${formatted}`;
    } else {
      return `${formatted}${decimalSeparator ? decimalSeparator.value : '.'}${theDecimals}`;
    }
  }

  return Intl.NumberFormat(language, { maximumFractionDigits: decimals }).format(+integerNumber);
};

export const i18nNumberAbreviation = (value: number, language = 'en', maximumFractionDigits = 1): string =>
  new Intl.NumberFormat(language, {
    maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'short',
  }).format(value);

export const i18nNumberAbreviationFromParsedValue = (value: string, decimals: number): string => {
  const valueInterface = buildValueInterfaceFromParsedValues(value, decimals);
  const formattedValueToNumber = +valueInterface.formatted.replaceAll(',', '');

  return i18nNumberAbreviation(formattedValueToNumber);
};

export const i18nBigNumber = (number: BigNumber | string, decimals: number, decimalsToShow = 0): string => {
  let val = number;
  if (val === null) return '0';
  if (typeof number === 'string' && number.includes('.')) {
    val = number.split('.')[0];
  }

  return i18nStringifiedNumberFormat(formatUnits(val, decimals), decimalsToShow);
};

export const isDust = (amount: BigNumber, lSymbol: string): boolean => {
  const traceUsdc = parseUnits('1', STABLECOIN_DECIMALS);
  const traceWeth = parseUnits('0.001', WEI_DECIMALS);

  const isDustLeftover = isStablecoin(lSymbol) ? amount.lte(traceUsdc) : amount.lte(traceWeth);

  return isDustLeftover;
};

export const getFactors = (number: number, options?: { min?: number; max?: number }) => {
  if (number === 0) {
    return [];
  }

  const numberArr = [];
  const min = options?.min ? options.min : 0;
  const max = options?.max ? options.max : number + 1;
  for (let i = min; i <= max; i++) {
    numberArr.push(i);
  }

  return numberArr.filter(i => number % i === 0);
};

type DaysHours = Record<'days' | 'hours', ValueInterface>;

export const calcDaysAndHoursInSeconds = ({ days, hours }: DaysHours): string => {
  const daysInSeconds = +days.parsed * SECONDS_IN_DAY;
  let total = `${daysInSeconds}`;

  if (!hours.bigNumber.isZero()) {
    const hoursInSeconds = (+hours.parsed / 24) * SECONDS_IN_DAY;

    total = `${daysInSeconds + hoursInSeconds}`;
  }

  return total;
};

export const getFormattedDaysHours = ({ days, hours }: DaysHours): string => {
  const parsedCycleDays = parseInt(days.parsed);
  let formatted = `${parsedCycleDays}`;

  if (!hours.bigNumber.isZero()) {
    const cycleHoursAsFractionOfDays = (+hours.parsed / 24).toFixed(3);
    const decimals = cycleHoursAsFractionOfDays.substr(2, cycleHoursAsFractionOfDays.length);
    formatted = `${parsedCycleDays}.${decimals}`;
  }

  return formatted;
};

export const usdFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
