import { ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useLocation, LinkProps, Link } from 'react-router-dom';

// Components
import Typography from 'Components/ds/Typography';

// Styles
import { shadow } from 'Components/ds/MapleTheme/designSystem';

interface StyledButtonProps extends ButtonProps {
  state: 'selected' | 'unselected';
}

const Icon = styled(Stack)(() => ({
  width: '20px',
  height: '20px',
}));

const StyledLink = styled(Button)<StyledButtonProps>(({ theme, state }) => {
  const styles = {
    selected: {
      border: `2px solid ${theme.palette.ds.info[400]}`,
      backgroundColor: theme.palette.ds.info[50],
      color: theme.palette.ds.info[500],
      '& [type="letter"]': {
        borderColor: theme.palette.ds.info[500],
      },
    },
    unselected: {
      border: '2px solid transparent',
      backgroundColor: 'none',
      color: theme.palette.ds.neutral[900],
    },
  };

  return {
    ...styles[state],
    textTransform: 'none',
    height: '40px',
    borderRadius: '6px',
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.ds.info[500],
      backgroundColor: theme.palette.ds.info[50],
      borderColor: theme.palette.ds.info[400],
      boxShadow: shadow.small,
      '& [type="letter"]': {
        borderColor: theme.palette.ds.info[500],
      },
    },
  };
});

export interface NavigationLinkProps extends LinkProps {
  text: string;
  icon: ReactElement;
  isExpanded: Boolean;
  target?: string;
}

const NavigationLink = ({ to, text, icon, target, isExpanded, ...props }: NavigationLinkProps) => {
  const { pathname } = useLocation();

  const selectedState = pathname.includes(`${to}`) ? 'selected' : 'unselected';

  return (
    <Link to={to} style={{ textDecoration: 'none' }} target={target} {...props}>
      <Stack direction='row' spacing={2}>
        <StyledLink state={selectedState} fullWidth>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={isExpanded ? 'flex-start' : 'center'}
            spacing={1}
            sx={{ width: '100%' }}
          >
            <Icon alignItems='center'>{icon}</Icon>
            {isExpanded && (
              <Typography variant='paragraphSmall' weight='medium' sx={{ whiteSpace: 'nowrap' }}>
                {text}
              </Typography>
            )}
          </Stack>
        </StyledLink>
      </Stack>
    </Link>
  );
};

export default NavigationLink;
