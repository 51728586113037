import { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

// Components
import WalletConnectButtonV2 from 'Components/WalletConnectButtonV2';
import AppBar from 'Components/ds/AppBar';
import AuthMenu from 'Components/AuthMenu';
import PageTitle from 'Components/PageTitle';
import NotificationInbox from 'Components/NotificationInbox';
import MapleDivider from 'Components/ds/MapleDivider';
import { BlackLayer } from 'Components/ds/Sidebar';
import Typography from 'Components/ds/Typography';
import MapleButton from 'Components/ds/MapleButton';

// Hooks
import useAuth from 'Hooks/useAuth';

// Styles
import { zIndex } from 'Components/ds/MapleTheme/designSystem';

const sharedStyles = {
  position: 'sticky',
  boxShadow: 'none',
  borderBottom: '1px solid #EBEBF2',
  zIndex: zIndex.medium,
  py: 2.5,
};

const bannerStyles = {
  position: 'sticky',
  top: 0,
  zIndex: zIndex.high + 1,
  width: '100%',
  background: '#FFCCD8',
  marginLeft: '64px',
  padding: '4px 16px',
};

const desktopStyles = {
  ...sharedStyles,
  pr: 5,
  pl: 12,
};

const mobileStyles = {
  ...sharedStyles,
  mt: 7,
  px: 3,
};

const AppBarV3 = ({ isSidebarExpanded }: { isSidebarExpanded: boolean }): ReactElement => {
  const { isAuthenticated, impersonated } = useAuth();
  const theme = useTheme();
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      {impersonated && (
        <Stack direction='row' alignItems='center' sx={{ ...bannerStyles }}>
          <Typography variant='paragraphSmall'>WARNING: Impersonator mode</Typography>
        </Stack>
      )}
      <AppBar sx={isDesktopDevice ? desktopStyles : mobileStyles}>
        <Stack sx={{ width: '100%' }} direction='row' alignItems='center' justifyContent='space-between'>
          <PageTitle />
          <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
            {isDesktopDevice && (
              <>
                {isAuthenticated ? (
                  <>
                    <Stack spacing={2} direction='row'>
                      <NotificationInbox />
                      <Box>
                        <MapleDivider orientation='vertical' />
                      </Box>
                    </Stack>

                    <WalletConnectButtonV2 showLabel withIcon />
                    <AuthMenu />
                  </>
                ) : (
                  <>
                    <WalletConnectButtonV2 showLabel withIcon />
                    <MapleButton href='/#/login' size='medium' sx={{ minWidth: '120px' }}>
                      Log In
                    </MapleButton>
                  </>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </AppBar>
      {isSidebarExpanded && <BlackLayer />}
    </>
  );
};

export default AppBarV3;
