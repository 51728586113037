import { FC, ReactElement } from 'react';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

// Components
import Typography from 'Components/ds/Typography';

interface SectionHeaderProps {
  subtitle: string;
  title: string | ReactElement;
  text: string;
}

const SectionHeader: FC<SectionHeaderProps> = ({ subtitle, title, text }: SectionHeaderProps): ReactElement => {
  return (
    <>
      <Grid item justifyContent='center'>
        <Stack direction='column' spacing={2} alignItems='center'>
          <Typography variant='paragraphSmall' color='ds.primary.500'>
            {subtitle}
          </Typography>
          {typeof title === 'string' ? (
            <Typography variant='h2' weight='bold' color='ds.neutral.900'>
              {title}
            </Typography>
          ) : (
            <>{title}</>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} md={8} sx={{ mb: 4 }}>
        <Typography variant='paragraphLarge' color='ds.neutral.700' align='center' sx={{ px: 10 }}>
          {text}
        </Typography>
      </Grid>
    </>
  );
};

export default SectionHeader;
