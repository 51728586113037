import { FC, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// Components
import { SectionHeader, ContactIcon } from 'Pages/PoolsV2Transition';
import MapleContainer from 'Components/ds/MapleContainer';

const Contact: FC = (): ReactElement => {
  return (
    <Box sx={{ backgroundColor: 'ds.neutral.50' }} id='contact'>
      <MapleContainer maxWidth='xl' sx={{ py: 10 }}>
        <Grid container spacing={5} justifyContent='center'>
          <SectionHeader
            subtitle='Contact Us'
            title='More Questions? Stay Connected'
            text='For up to date news and updates, follow us on socials:'
          />
          <Grid item xs={12} md={8}>
            <Stack direction='row' spacing={5} justifyContent='center'>
              <ContactIcon icon='twitter' />
              <ContactIcon icon='message' />
            </Stack>
          </Grid>
        </Grid>
      </MapleContainer>
    </Box>
  );
};

export default Contact;
