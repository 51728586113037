// Color scheme from Figma file

/*
/ Brand colors
/********************************
*/

export interface PaletteColor {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const mapleOrange = {
  50: '#FFF2E7',
  100: '#FDE7D3',
  200: '#FFCFA3',
  300: '#FFB877',
  400: '#FF983A',
  500: '#FF7900',
  600: '#E06900',
  700: '#B85600',
  800: '#8F4300',
  900: '#663000',
};

export const mapleCobalt = {
  50: '#EEE8FF',
  100: '#DACCFD',
  200: '#AC8DF8',
  300: '#8B60F1',
  400: '#6D37F1',
  500: '#5113E8',
  600: '#4711D0',
  700: '#3A0EAA',
  800: '#2D0B84',
  900: '#20085E',
};

export const mapleButter = {
  50: '#FFFAEE',
  100: '#FFF3DA',
  200: '#FFE8B8',
  300: '#FFD987',
  400: '#FFCA59',
  500: '#FDBA2B',
  600: '#FDB10D',
  700: '#DE9902',
  800: '#B67D02',
  900: '#8D6101',
};

export const mapleViolet = {
  50: '#F4EBF5',
  100: '#EAD7ED',
  200: '#E2C2E7',
  300: '#DDABE5',
  400: '#DA93E5',
  500: '#DA6DEA',
  600: '#D14CE6',
  700: '#C829E0',
  800: '#AE1CC4',
  900: '#8E17A1',
};

/*
/ Semantic colors
/********************************
*/

export const successColor = {
  50: '#F8FCF9',
  100: '#EEF8F2',
  200: '#C6EBD6',
  300: '#95DAB3',
  400: '#5FC88D',
  500: '#3EB672',
  600: '#34985F',
  700: '#2A7A4C',
  800: '#1F5B38',
  900: '#153D25',
};

export const informationColor = {
  50: '#F5FBFF',
  100: '#E8F7FF',
  200: '#CDEDFE',
  300: '#9BDBFD',
  400: '#69C9FC',
  500: '#16ABFA',
  600: '#059EF0',
  700: '#0483C8',
  800: '#0369A0',
  900: '#024F78',
};

export const warningColor = {
  50: '#FFFBEB',
  100: '#FFF6D6',
  200: '#FFEDAD',
  300: '#FFE485',
  400: '#FFDC5C',
  500: '#FFD333',
  600: '#FFCA0A',
  700: '#F5C000',
  800: '#E0B000',
  900: '#CCA000',
};

export const destructiveColor = {
  50: '#FFF5F7',
  100: '#FFE6EC',
  200: '#FFCCD8',
  300: '#FF8FAA',
  400: '#FF4C77',
  500: '#FF0A45',
  600: '#E00034',
  700: '#B8002B',
  800: '#8F0021',
  900: '#660018',
};

export const neutralColor = {
  50: '#F9F9FC',
  100: '#F5F5FA',
  200: '#EBEBF2',
  300: '#CACAD9',
  400: '#B0B0BF',
  500: '#8E8E9B',
  600: '#72727E',
  700: '#575764',
  800: '#393941',
  900: '#1A1A1C',
};

/*
/ Context colors
/********************************
*/

export const textColors = {
  mapleDeepBlack: '#0F0F0F',
  darkLiver: '#575764',
  lavenderGrey: '#B5B5C8',
};

export const backgroundColors = {
  ghostWhite: '#F5F5FA',
  cultured: '#F9F9FC',
  mapleWhite: '#FFFFFF',
};

export const lineColors = {
  platinum: '#E3E5E7',
  steel: '#9E9E9F',
};

export const shadow = {
  innerXsmall: '0px 1px 1px 0px rgba(16, 24, 40, 0.40) inset',
  xsmall: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  small: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1)',
  medium: '0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1)',
  large: '0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1)',
  xlarge: '0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)',
  xxlarge: '0px 25px 50px -12px rgba(16, 24, 40, 0.25)',
  xxxlarge: '0px 10px 15px rgba(16, 24, 40, 0.1), 0px 4px 6px rgba(16, 24, 40, 0.1);',
};

export const blur = {
  small: 'blur(8px)',
  medium: 'blur(16px)',
  large: 'blur(24px)',
  xlarge: 'blur(40px)',
};

export const gradient = {
  linear1: 'linear-gradient(98.41deg, #34108C 0%, #5113E8 50%, #DA93E5 100%)',
  linear6: 'linear-gradient(90deg, #ff7900 0%, #fdba2b 100%)',
};

export const zIndex = {
  low: 10,
  medium: 20,
  high: 30,
};
