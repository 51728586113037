import { useState, useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { CourierProvider } from '@trycourier/react-provider';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

// Components
import AppBarV3 from 'Components/AppBarV3';
import NavigationSidebar from 'Components/ds/Sidebar';
import FooterV2 from 'Components/FooterV2';
import ErrorFallback from 'Components/ErrorFallback';

// Constants
import { COURIER_CLIENT_KEY, isProtocolPaused } from 'Constants';

// Context
import { AuthContext } from 'Context/Auth';

// Pages
import { ProtocolPaused } from 'Pages/ProtocolPaused';

// Router
import { Router } from 'Router';

const PageContainer = () => {
  const { pathname } = useLocation();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const { user } = useDynamicContext();
  const { courierAuth } = useContext(AuthContext);

  const isSignupLoginPage = pathname.includes('signup') || pathname.includes('login');

  return (
    <CourierProvider
      userId={courierAuth && user?.userId}
      clientKey={courierAuth && COURIER_CLIENT_KEY}
      authorization={courierAuth}
    >
      {!isSignupLoginPage && (
        <>
          <NavigationSidebar isExpanded={isSidebarExpanded} setIsExpanded={setIsSidebarExpanded} />
          <AppBarV3 isSidebarExpanded={isSidebarExpanded} />
        </>
      )}

      <Box
        sx={{
          minHeight: 'calc(100vh - 96px)',
          pl: { sm: 0, md: isSignupLoginPage ? 0 : 8 },
        }}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {isProtocolPaused ? <ProtocolPaused /> : <Router />}
        </ErrorBoundary>
      </Box>

      {!isSignupLoginPage && <FooterV2 />}
    </CourierProvider>
  );
};

export default PageContainer;
