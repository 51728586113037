import { FC, ReactElement, useEffect, useState, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Assets
import { ReactComponent as IconWallet } from 'Components/ds/assets/icon-wallet.svg';

// Components
import MapleButton, { MapleButtonProps } from 'Components/ds/MapleButton';
import MapleLink, { MapleLinkProps } from 'Components/ds/MapleLink';

// Constants
import { CHAIN_ID } from 'Constants';

// Context
import { useWalletContext } from 'Context/Wallet/index';

// Hooks
import useIsGnosisSafe from 'Hooks/useIsGnosisSafe';

// Utils
import { truncateAddress } from 'Utils/string';

interface WalletConnectButtonProps extends Pick<MapleButtonProps, 'color' | 'variant' | 'fullWidth' | 'size'> {
  type?: 'link' | 'button';
  copy?: string;
  linkVariant?: MapleLinkProps['variant'];
  withIcon?: boolean;
  showLabel?: boolean;
}

const WalletConnectButton: FC<WalletConnectButtonProps> = ({
  copy,
  type = 'button',
  variant = 'outlined',
  color = 'tertiary',
  size = 'medium',
  fullWidth = false,
  withIcon = false,
  showLabel = true,
  linkVariant,
}: WalletConnectButtonProps): ReactElement | null => {
  const { connector, isActive, account, chainId } = useWeb3React<Web3Provider>();
  const { showModal, setShowModal, walletIsActivating } = useWalletContext();
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const gnosisConnectionType = useIsGnosisSafe();
  const { breakpoints, palette } = useTheme();
  const isDesktopDevice = useMediaQuery(breakpoints.up('md'));

  const isUnsupportedChainIdSelected = useMemo(() => {
    if (!isActive || !chainId) return false;

    return chainId !== CHAIN_ID;
  }, [chainId, isActive]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  const isConnected = useMemo(() => {
    if (isActive || ['gnosis-default', 'gnosis-wallet-connect'].includes(gnosisConnectionType)) {
      return true;
    } else {
      return false;
    }
  }, [isActive, account]);

  const buttonStyles = useMemo(() => {
    return {
      svg: { fill: palette.ds.success[500] },
      ':focus': {
        svg: { fill: palette.ds.success[500] },
      },
      ...(isDesktopDevice ? {} : { pl: 2, pr: 0.5 }),
    };
  }, [isDesktopDevice, palette]);

  if (isUnsupportedChainIdSelected) {
    return null;
  }

  if (isConnected) {
    return (
      <MapleButton
        onClick={() => setShowModal(!showModal)}
        variant='outlined'
        color='tertiary'
        size={size}
        data-testid='connected-wallet-button'
        sx={{ ...buttonStyles, ...(showLabel ? { py: '5px', px: '10px' } : { p: 1.2 }) }}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          <IconWallet />
          {showLabel && account && <span>{truncateAddress(account)}</span>}
        </Stack>
      </MapleButton>
    );
  }

  if (type === 'link') {
    return (
      <MapleLink
        underline='always'
        onClick={() => setShowModal(!showModal)}
        link={color as MapleLinkProps['link']}
        variant={linkVariant}
        data-testid='connect-wallet'
      >
        {copy || 'Connect Wallet'}
      </MapleLink>
    );
  }

  return (
    <MapleButton
      onClick={() => setShowModal(!showModal)}
      variant={variant}
      color={color}
      size={size}
      fullWidth={fullWidth}
      disabled={!isActive && walletIsActivating}
      data-testid='connect-wallet'
      sx={showLabel ? {} : { py: '5px', px: '10px' }}
    >
      <Stack direction='row' spacing={1} alignItems='center'>
        {withIcon && <IconWallet />}
        {showLabel && <span>{copy || 'Connect Wallet'}</span>}
      </Stack>
    </MapleButton>
  );
};

export default WalletConnectButton;
