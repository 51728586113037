import AccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

// Assets
import { ReactComponent as ArrowIcon } from 'Components/ds/assets/icon-arrow.svg';

const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(({ theme }) => ({
  padding: 0,
  margin: 0,
  minHeight: 'auto',
  '&.Mui-expanded': {
    minHeight: 'auto',
    '.accordion-arrow': {
      transform: 'rotate(180deg)',
    },
  },
  '&.MuiAccordionSummary-content.Mui-expanded': {
    minHeight: 'auto',
  },
  '.accordion-arrow': {
    position: 'absolute',
    width: '24px',
    height: '24px',
    top: '4px',
    right: 0,
    fill: theme.palette.ds.text.primary,
  },
}));

const MapleAccordionSummary = ({ children, ...props }: AccordionSummaryProps) => {
  return (
    <StyledAccordionSummary {...props}>
      {children}
      <ArrowIcon className='accordion-arrow' />
    </StyledAccordionSummary>
  );
};

export default MapleAccordionSummary;
