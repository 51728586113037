import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';

// Types
import { Connection, ConnectionType, onConnectionError } from 'Services/web3/connectors';

// Constants
import { CHAIN_ID, NETWORKS_V2_RPC_URLS, WALLET_CONNECT_PROJECT_ID } from 'Constants';

export function buildWalletConnectConnector() {
  const [web3WalletConnect, web3WalletConnectHooks] = initializeConnector<WalletConnect>(
    actions =>
      new WalletConnect({
        actions,
        options: {
          projectId: WALLET_CONNECT_PROJECT_ID,
          chains: [CHAIN_ID],
          showQrModal: true,
          qrModalOptions: {
            themeMode: 'light',
          },
          rpcMap: NETWORKS_V2_RPC_URLS,
        },
        defaultChainId: CHAIN_ID,
        onError: onConnectionError,
      }),
  );

  const walletConnectConnection: Connection<WalletConnect> = {
    connector: web3WalletConnect,
    hooks: web3WalletConnectHooks,
    type: ConnectionType.WALLET_CONNECT,
  };

  return walletConnectConnection;
}
