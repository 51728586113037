import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

interface MapleDialogActionsProps extends DialogActionsProps {
  justifyContent?: StackProps['justifyContent'];
  spacing?: number;
}

const StyledDialogActions = styled(DialogActions)(() => ({
  padding: '0',
}));

const MapleDialogActions = ({ children, justifyContent, spacing = 2, ...props }: MapleDialogActionsProps) => {
  return (
    <StyledDialogActions {...props}>
      <Stack direction='row' spacing={spacing} sx={{ width: '100%' }} justifyContent={justifyContent}>
        {children}
      </Stack>
    </StyledDialogActions>
  );
};

export default MapleDialogActions;
