import Dialog, { DialogProps } from '@mui/material/Dialog';
import { SxProps } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Components
import MapleButton from 'Components/ds/MapleButton';

export interface MapleDialogProps extends DialogProps {
  type?: 'transaction';
  width?: number;
}

const styles: { [key: string]: SxProps } = {
  closeButton: {
    position: 'absolute',
    top: '34px',
    right: '34px',
    padding: 0,
    minWidth: 'unset',
    width: '26px',
    height: '26px !important',
    borderRadius: '24px',
    span: {
      margin: 0,
    },
  },
};

const StyledDialog = styled(Dialog)<MapleDialogProps>(({ theme, type, width }) => ({
  padding: theme.spacing(4.5),
  // borderRadius: '10px',
  background: 'rgba(15, 15, 15, 0.3)',
  '.MuiBackdrop-root': {
    backdropFilter: 'unset',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '.MuiBackdrop-paperFullScreen': {
    backgroundColor: theme.palette.ds.neutral[50],
  },
  '.MuiDialog-paper': {
    overflowY: 'hidden',
    maxHeight: '100%',
    borderRadius: '10px',
    padding: theme.spacing(4.5),
  },
  '.MuiDialog-paperWidthMd': { width: '595px' },
  '.MuiDialog-paperWidthLg': { width: '697px' },
  '.MuiDialog-paperWidthXl': { width: '1033px' },
  '[role=dialog]': {
    backgroundColor: theme.palette.ds.background.primary,
    borderRadius: '6px',
    padding: theme.spacing(5),
    maxHeight: '100%',
  },
  '.MuiBox-root[appearance=groupedButtons]': {
    padding: '0 32px !important',
  },
  '.MuiBox-root[appearance=standard]': {
    marginTop: theme.spacing(5),
    padding: '0 !important',
  },
  ...(width && { '[role=dialog]': { width: width } }),
  ...(type === 'transaction' && {
    padding: `${theme.spacing(7.5)} ${theme.spacing(7.5)} ${theme.spacing(1.5)} ${theme.spacing(7.5)}`,
    '[role=dialog]': {
      backgroundColor: theme.palette.ds.neutral[50],
    },
    '.MuiCardContent-root': {
      padding: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(5)}`,
    },
  }),
  // Mobile & Tablet styles
  [theme.breakpoints.down('md')]: {
    padding: 0,
  },
}));

const MapleDialog = ({ children, onClose, ...props }: MapleDialogProps) => {
  return (
    <StyledDialog onClose={onClose} {...props}>
      {children}
      <MapleButton
        variant='outlined'
        color='tertiary'
        onClick={onClose as () => void}
        endIcon={<CloseIcon />}
        shadow='small'
        sx={styles.closeButton}
        data-testid='close-maple-dialog-btn'
      />
    </StyledDialog>
  );
};

export default MapleDialog;
