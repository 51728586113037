import {
  addresses,
  ContractTypes,
  environmentMocks,
  fixedTermLoan,
  fixedTermLoanManager,
  loanManager,
  loanV2,
  MapleAddressMapping,
  mapleGlobalsV201,
  mapleToken,
  migrationHelpers,
  openTermLoan,
  openTermLoanManager,
  poolManager,
  poolManagerV2,
  poolPermissionManager,
  poolV2,
  poolV201,
  poolV3,
  withdrawalManager,
  withdrawalManagerQueue,
  xmpl,
} from '@maplelabs/maple-js';
import { getLocalhostAddress } from './localhostAddresses';
import { PROJECT } from 'Constants';

export enum Contracts {
  LoanV2Factory = 'LoanV2Factory',

  // PoolV2
  FeeManager = 'FeeManager',
  LoanManager = 'LoanManager',
  LoanManagerFactory = 'LoanManagerFactory',
  LoanManagerInitializer = 'LoanManagerInitializer',
  MapleGlobalsV2 = 'MapleGlobalsV2',
  NonTransparentProxy = 'NonTransparentProxy',
  PoolV2 = 'PoolV2',
  PoolManager = 'PoolManager',
  PoolManagerFactory = 'PoolManagerFactory',
  PoolManagerInitializer = 'PoolManagerInitializer',
  WithdrawalManager = 'WithdrawalManager',
  WithdrawalManagerFactory = 'WithdrawalManagerFactory',
  WithdrawalManagerInitializer = 'WithdrawalManagerInitializer',

  // PoolV201
  FixedTermLoan = 'FixedTermLoan',
  FixedTermLoanInitializer = 'FixedTermLoanInitializer',
  FixedTermLoanManager = 'FixedTermLoanManager',
  FixedTermLoanRefinancer = 'FixedTermLoanRefinancer',

  OpenTermLoan = 'OpenTermLoan',
  OpenTermLoanFactory = 'OpenTermLoanFactory',
  OpenTermLoanInitializer = 'OpenTermLoanInitializer',
  OpenTermLoanManager = 'OpenTermLoanManager',
  OpenTermLoanManagerFactory = 'OpenTermLoanManagerFactory',
  OpenTermLoanRefinancer = 'OpenTermLoanRefinancer',
  PoolDeployerV2 = 'PoolDeployerV2',
  PoolManagerV2 = 'PoolManagerV2',
  MapleGlobalsV201 = 'MapleGlobalsV201',

  // PoolV3
  PoolPermissionManager = 'PoolPermissionManager',
  PoolDeployerV3 = 'PoolDeployerV3',
  WithdrawalManagerQueue = 'WithdrawalManagerQueue',
  WithdrawalManagerQueueFactory = 'WithdrawalManagerQueueFactory',
  FixedTermLoanFactoryV2 = 'FixedTermLoanFactoryV2',
  // Migrations - Pool V1 to Pool V2 migration
  MigrationHelper = 'MigrationHelper',
  MigrationHelperProxy = 'MigrationHelperProxy',

  // Tokens
  AAVE = 'AAVE',
  BToken = 'BToken',
  DAI = 'DAI',
  kycUSDC = 'kycUSDC',
  kycWETH = 'kycWETH',
  LINK = 'LINK',
  MapleToken = 'MapleToken',
  MPL = 'MapleToken',
  USDC = 'USDC',
  USDCk1 = 'USDCk1',
  'USDC-MPL' = 'USDC-MPL',
  USDT = 'USDT',
  WBTC = 'WBTC',
  WETH = 'WETH9',
  WETH9 = 'WETH9',
  xMPL = 'xMPL',
}

export type ContractName =
  | Contracts.FixedTermLoan
  | Contracts.FixedTermLoanInitializer
  | Contracts.FixedTermLoanManager
  | Contracts.FixedTermLoanRefinancer
  | Contracts.kycUSDC
  | Contracts.kycWETH
  | Contracts.LoanManager
  | Contracts.LoanV2Factory
  | Contracts.MapleGlobalsV201
  | Contracts.MapleToken
  | Contracts.MigrationHelper
  | Contracts.OpenTermLoan
  | Contracts.OpenTermLoanFactory
  | Contracts.OpenTermLoanInitializer
  | Contracts.OpenTermLoanManager
  | Contracts.OpenTermLoanManagerFactory
  | Contracts.OpenTermLoanRefinancer
  | Contracts.PoolV2
  | Contracts.PoolDeployerV2
  | Contracts.PoolManager
  | Contracts.PoolManagerV2
  | Contracts.PoolPermissionManager
  | Contracts.PoolDeployerV3
  | Contracts.FixedTermLoanFactoryV2
  | Contracts.USDC
  | Contracts.USDCk1
  // eslint-disable-next-line prettier/prettier
  | (typeof Contracts)['USDC-MPL']
  | Contracts.USDT
  | Contracts.WBTC
  | Contracts.WETH
  | Contracts.WithdrawalManager
  | Contracts.WithdrawalManagerQueue
  | Contracts.xMPL;

/* prettier-ignore */
export type ContractType<T extends ContractName> =
T extends Contracts.xMPL ? ContractTypes['xmpl'] :
T extends Contracts.MapleToken ? ContractTypes['mapleToken'] :
T extends Contracts.LoanV2Factory ? ContractTypes['loanV2Factory'] :
T extends Contracts.MapleGlobalsV201 ? ContractTypes['mapleGlobalsV201'] :
T extends Contracts.WETH ? ContractTypes['erc20'] :
T extends Contracts.kycUSDC ? ContractTypes['kycerc20'] :
T extends Contracts.kycWETH ? ContractTypes['kycerc20'] :
T extends Contracts.WBTC ? ContractTypes['erc20'] :
T extends Contracts.USDC ? ContractTypes['erc20'] :
T extends Contracts.USDCk1 ? ContractTypes['kycerc20'] :
T extends typeof Contracts['USDC-MPL'] ? ContractTypes['erc20'] :
T extends Contracts.USDT ? ContractTypes['usdt'] :
T extends Contracts.PoolV2 ? ContractTypes['poolV2'] :
T extends Contracts.PoolManager ? ContractTypes['poolManager'] :
T extends Contracts.WithdrawalManager ? ContractTypes['withdrawalManager'] :
T extends Contracts.WithdrawalManagerQueue ? ContractTypes['withdrawalManagerQueue'] :
T extends Contracts.MigrationHelper ? ContractTypes['migrationHelpers'] :
T extends Contracts.FixedTermLoan ? ContractTypes['fixedTermLoan'] :
T extends Contracts.FixedTermLoanInitializer ? ContractTypes['fixedTermLoanInitializer'] :
T extends Contracts.FixedTermLoanManager ? ContractTypes['fixedTermLoanManager'] :
T extends Contracts.FixedTermLoanRefinancer ? ContractTypes['fixedTermLoanRefinancer'] :
T extends Contracts.OpenTermLoanManagerFactory ? ContractTypes['openTermLoanManagerFactory'] :
T extends Contracts.OpenTermLoan ? ContractTypes['openTermLoan'] :
T extends Contracts.OpenTermLoanRefinancer ? ContractTypes['openTermLoanRefinancer'] :
T extends Contracts.OpenTermLoanInitializer ? ContractTypes['openTermLoanInitializer'] :
T extends Contracts.OpenTermLoanFactory ? ContractTypes['openTermLoanFactory'] :
T extends Contracts.OpenTermLoanManager ? ContractTypes['openTermLoanManager'] :
T extends Contracts.OpenTermLoanRefinancer ? ContractTypes['openTermLoanRefinancer'] :
T extends Contracts.LoanManager ? ContractTypes['loanManager'] :
T extends Contracts.PoolDeployerV2 ? ContractTypes['poolDeployerV2'] :
T extends Contracts.PoolManagerV2 ? ContractTypes['poolManagerV2'] :
T extends Contracts.PoolPermissionManager ? ContractTypes['poolPermissionManager'] :
T extends Contracts.PoolDeployerV3 ? ContractTypes['poolDeployerV3'] :
T extends Contracts.FixedTermLoanFactoryV2 ? ContractTypes['fixedTermLoanFactoryV2'] :
never;

const contractTypeToTypechain: Record<string, any> = {
  [Contracts.LoanV2Factory]: loanV2.factory,

  [Contracts.FixedTermLoan]: fixedTermLoan.core,
  [Contracts.FixedTermLoanInitializer]: fixedTermLoan.initializer,
  [Contracts.FixedTermLoanRefinancer]: fixedTermLoan.refinancer,
  [Contracts.FixedTermLoanFactoryV2]: fixedTermLoan.factory,

  [Contracts.OpenTermLoanManagerFactory]: openTermLoanManager.factory,
  [Contracts.OpenTermLoan]: openTermLoan.core,
  [Contracts.OpenTermLoanRefinancer]: openTermLoan.refinancer,
  [Contracts.OpenTermLoanInitializer]: openTermLoan.initializer,
  [Contracts.OpenTermLoanFactory]: openTermLoan.factory,

  [Contracts.MapleToken]: mapleToken.core,
  [Contracts.MapleGlobalsV201]: mapleGlobalsV201.core,
  [Contracts.PoolManager]: poolManager.core,
  [Contracts.PoolManagerV2]: poolManagerV2.core,
  [Contracts.PoolDeployerV2]: poolV201.deployer,
  [Contracts.PoolPermissionManager]: poolPermissionManager.core,
  [Contracts.PoolDeployerV3]: poolV3.deployer,

  [Contracts.WithdrawalManager]: withdrawalManager.core,
  [Contracts.WithdrawalManagerQueue]: withdrawalManagerQueue.core,

  [Contracts.MigrationHelper]: migrationHelpers.core,

  [Contracts.LoanManager]: loanManager.core,
  [Contracts.FixedTermLoanManager]: fixedTermLoanManager.core,
  [Contracts.OpenTermLoanManager]: openTermLoanManager.core,

  [Contracts.USDC]: environmentMocks.erc20,
  [Contracts.USDCk1]: environmentMocks.kycerc20,
  [Contracts['USDC-MPL']]: environmentMocks.erc20,
  [Contracts.USDT]: environmentMocks.usdt,
  [Contracts.WETH]: environmentMocks.erc20,
  [Contracts.kycUSDC]: environmentMocks.kycerc20,
  [Contracts.kycWETH]: environmentMocks.kycerc20,
  [Contracts.WBTC]: environmentMocks.erc20,
  [Contracts.DAI]: environmentMocks.erc20,

  [Contracts.xMPL]: xmpl.factory,

  [Contracts.PoolV2]: poolV2.core,
};

export interface IArtifacts {
  abi: any;
  address: string;
  contract?: Contracts | null;
  typechainContract: any | null;
}

export function getAddress(contract: Contracts): string {
  // NonTransparentProxies use implementation contract ABI at proxy instance address
  if ([Contracts.MapleGlobalsV201].includes(contract)) return getAddress(Contracts.NonTransparentProxy);
  if (contract === Contracts.MigrationHelper) return getAddress(Contracts.MigrationHelperProxy);

  const address =
    PROJECT === 'localhost'
      ? getLocalhostAddress(contract as keyof MapleAddressMapping)
      : addresses[PROJECT][<Extract<Contracts, keyof MapleAddressMapping>>contract];

  return address;
}

const nullArtifacts: IArtifacts = { abi: [], address: '', contract: null, typechainContract: null };

export function getArtifacts(contract: Contracts, contractAddress = ''): IArtifacts {
  let address = contractAddress;

  if (!PROJECT) {
    if (process.env.NODE_ENV !== 'test') console.error('Error: the network has not been set in web3/artifacts');

    return nullArtifacts;
  }

  const typechainContract = contractTypeToTypechain[contract];

  if (!typechainContract) {
    console.error(`Error: the ${contract}  is not available`);

    return nullArtifacts;
  }

  if (!address) {
    address = getAddress(contract);
  }

  return { abi: typechainContract.abi, address, contract, typechainContract };
}
