import { ReactElement, ReactNode, createContext, useState, useEffect, useRef } from 'react';

// Components
import TypeformDialog from 'Components/TypeformDialog';

export type HiddenData = { [attribute: string]: string };

export type FormSubmitted = { [formId: string]: boolean };

interface TypeFormProviderProps {
  children: ReactNode;
}

interface OpenFormDialogProps {
  formId?: string;
  hiddenData?: HiddenData;
  submitAction?: () => void;
}

interface TypeForm {
  isFormDialogOpen: boolean;
  submittedForms: FormSubmitted;
  openFormDialog: (openFormDialogProps: OpenFormDialogProps) => void;
}

export const TypeFormContext = createContext<TypeForm>({
  isFormDialogOpen: false,
  submittedForms: {},
  openFormDialog: () => {
    return;
  },
});

export const TypeFormProvider = ({ children }: TypeFormProviderProps): ReactElement => {
  const [widgetId, setWidgetId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsRedy] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [callTimeout, setCallTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
  const [timeoutFinished, setTimeoutFinished] = useState(false);
  const [widgetHiddenData, setWidgetHiddenData] = useState<HiddenData>({});
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);

  const submittedFormsRef = useRef<FormSubmitted>({});
  const actionOnFormSubmit = useRef<OpenFormDialogProps['submitAction'] | null>(null);
  const footerButtonCopy = useRef('Cancel');

  const handleFormSubmit = (formId: string, isSubmitted: boolean) => {
    submittedFormsRef.current = { ...submittedFormsRef.current, [formId]: isSubmitted };
    footerButtonCopy.current = 'Close';
    if (actionOnFormSubmit.current) actionOnFormSubmit.current();
  };

  const handleOnReady = () => {
    clearTimeout(callTimeout);
    setIsRedy(true);
    setIsLoading(false);
    setHasError(false);
  };

  const handleClose = () => {
    setIsFormDialogOpen(false);
    setIsRedy(false);
    setIsLoading(false);
    setHasError(false);
    setTimeoutFinished(false);
    actionOnFormSubmit.current = null;
  };

  const openFormDialog = ({ formId, hiddenData, submitAction }: OpenFormDialogProps) => {
    if (formId) {
      setWidgetId(formId);

      setWidgetHiddenData({ ...hiddenData, source: 'webapp' });

      if (submitAction) actionOnFormSubmit.current = submitAction;

      setIsLoading(true);
      setCallTimeout(setTimeout(() => setTimeoutFinished(true), 10_000)); // Error if Typeform not load in 10 seconds
    } else {
      setHasError(true);
    }

    setIsFormDialogOpen(true);
  };

  useEffect(() => {
    if (timeoutFinished && !isReady) {
      setIsLoading(false);
      setHasError(true);
    }
  }, [timeoutFinished, isReady]);

  return (
    <TypeFormContext.Provider
      value={{
        isFormDialogOpen,
        submittedForms: submittedFormsRef.current,
        openFormDialog,
      }}
    >
      {children}
      <TypeformDialog
        isFormDialogOpen={isFormDialogOpen}
        onClose={handleClose}
        isLoading={isLoading}
        hasError={hasError}
        isReady={isReady}
        widgetId={widgetId}
        widgetHiddenData={widgetHiddenData}
        onSubmit={handleFormSubmit}
        onReady={handleOnReady}
      />
    </TypeFormContext.Provider>
  );
};
