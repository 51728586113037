import { ReactNode, useMemo } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

// Components
import Typography, { StyledTypographyProps } from 'Components/ds/Typography';

type Size = 'small' | 'medium' | 'large';
interface CommonProps {
  size: Size;
}

interface PageIconWithLetter extends CommonProps {
  type: 'letter';
  letter: string;
}

interface PageIconWithIcon extends CommonProps {
  type: 'icon';
  icon: ReactNode;
}

type PageIconProps = PageIconWithLetter | PageIconWithIcon;

interface IconProps extends StackProps {
  size: Size;
  type: PageIconProps['type'];
}

const dimension: Record<Size, string> = {
  small: '20px',
  medium: '32px',
  large: '40px',
};

const borderWidth: Record<Size, string> = {
  small: '1px',
  medium: '2px',
  large: '3px',
};

const typographyVariant: Record<Size, StyledTypographyProps['variant']> = {
  small: 'paragraphXSmall',
  medium: 'h6',
  large: 'h5',
};

const Icon = styled(Stack)<IconProps>(({ theme, size, type }) => {
  return {
    width: dimension[size],
    height: dimension[size],
    svg: {
      width: dimension[size],
      height: dimension[size],
    },

    ...(type === 'letter' && {
      border: `${borderWidth[size]} solid ${theme.palette.ds.neutral[900]}`,
      borderRadius: '20px',
    }),
  };
});

const PageIcon = (props: PageIconProps) => {
  const getPageIconItem = useMemo(() => {
    if (props.type === 'icon') {
      return props.icon;
    }

    if (props.type === 'letter') {
      const { size, letter } = props;

      return (
        <Typography variant={typographyVariant[size]} component='span' sx={{ transform: 'translateY(1px)' }}>
          {letter}
        </Typography>
      );
    }
  }, [props]);

  return (
    <Icon alignItems='center' justifyContent='center' size={props.size} type={props.type}>
      {getPageIconItem}
    </Icon>
  );
};

export default PageIcon;
