import DialogActions, { DialogActionsProps } from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';

export interface ModalFooterProps extends DialogActionsProps {
  withLogo?: boolean;
}

const StyledDialogActions = styled(DialogActions)<ModalFooterProps>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.ds.neutral[200]}`,
  display: 'block',
}));

const ModalFooter = ({ children, ...props }: ModalFooterProps) => {
  return <StyledDialogActions {...props}>{children}</StyledDialogActions>;
};

export default ModalFooter;
