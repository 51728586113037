import { useNavigate } from 'react-router-dom';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SxProps } from '@mui/material/styles';

// Assets
import { ReactComponent as IconAccountSignOut } from 'Components/ds/assets/icon-sign-out.svg';
import { ReactComponent as IconAccount } from 'Components/ds/assets/icon-account.svg';

// Components
import Typography from 'Components/ds/Typography';

// Hooks
import useAuth from 'Hooks/useAuth';

// Styles
import { shadow } from 'Components/ds/MapleTheme/designSystem';
import { useWeb3React } from '@web3-react/core';

const styles: { [key: string]: SxProps } = {
  menu: {
    boxShadow: shadow.small,
    ul: { padding: 0 },
  },
  menuItem: {
    py: 1.2,
    svg: {
      width: '16px',
      height: '16px',
      fill: '#575764',
      marginRight: '8px',
    },
  },
  menuItemWithBorder: {
    py: 1.2,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'ds.neutral.200',
    svg: {
      width: '16px',
      height: '16px',
      fill: '#575764',
      marginRight: '8px',
    },
  },
};

interface AuthDropdownProps {
  anchorEl: null | HTMLElement;
  showMenu: boolean;
  setAnchorEl: (arg: any) => void;
  setShowMenu: (arg: boolean) => void;
}

const AuthDropdown = ({ anchorEl, showMenu, setAnchorEl, setShowMenu }: AuthDropdownProps) => {
  const { account } = useWeb3React();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleClose = () => {
    setShowMenu(false);
    setAnchorEl(null);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    handleClose();
  };

  const handleSignOutClick = () => {
    logout();
    handleClose();
  };

  const originProps: { anchorOrigin: MenuProps['anchorOrigin']; transformOrigin: MenuProps['transformOrigin'] } = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    transformOrigin: { vertical: 'top', horizontal: 'left' },
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={showMenu}
      onClose={handleClose}
      {...originProps}
      sx={{
        ...styles.menu,
        transform: 'translateY(6px)',
        '.MuiPaper-root': {
          minWidth: anchorEl?.offsetWidth || 0,
        },
      }}
    >
      {account && (
        <MenuItem onClick={() => handleNavigate('/v2/lend/account')} sx={styles.menuItemWithBorder}>
          <IconAccount />
          <Typography variant='paragraphSmall' component='span'>
            Account Overview
          </Typography>
        </MenuItem>
      )}
      <MenuItem onClick={() => handleSignOutClick()} sx={styles.menuItem}>
        <IconAccountSignOut />
        <Typography variant='paragraphSmall' component='span'>
          Sign Out
        </Typography>
      </MenuItem>
    </Menu>
  );
};

export default AuthDropdown;
