import { ReactElement, useState } from 'react';
import Box from '@mui/material/Box';

// Components
import AuthButton from 'Components/AuthButton';
import AuthDropdown from 'Components/AuthDropdown';
interface AuthMenuProps {
  showLabel?: boolean;
}

const AuthMenu = ({ showLabel = true }: AuthMenuProps): ReactElement => {
  const [showMenu, setShowMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <Box>
      <AuthButton showLabel={showLabel} showMenu={showMenu} setShowMenu={setShowMenu} setAnchorEl={setAnchorEl} />
      <AuthDropdown showMenu={showMenu} setShowMenu={setShowMenu} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Box>
  );
};

export default AuthMenu;
