import DialogTitle, { DialogTitleProps } from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Asets
import { ReactComponent as MapleIsotype } from 'Components/ds/assets/icon-maple-isotype.svg';

export interface ModalHeaderProps extends DialogTitleProps {
  withLogo?: boolean;
}

const StyledDialogTitle = styled(DialogTitle)<ModalHeaderProps>(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.ds.neutral[200]}`,
}));

const Icon = styled(Stack)(({ theme }) => ({
  width: '32px',
  height: '32px',
  svg: {
    fill: theme.palette.ds.neutral[400],
  },
}));

const CloseButtonSpace = styled(Box)(() => ({
  width: '36px',
  height: '36px',
}));

const ModalHeader = ({ children, withLogo, ...props }: ModalHeaderProps) => {
  return (
    <StyledDialogTitle {...props}>
      <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={3}>
        <Icon>{withLogo && <MapleIsotype />}</Icon>

        {children}

        <CloseButtonSpace />
      </Stack>
    </StyledDialogTitle>
  );
};

export default ModalHeader;
