import { SxProps } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

// Components
import PoolDelegateLogo from 'Components/PoolDelegateLogo';
import PoolLogoWithAsset from 'Components/PoolLogoWithAsset';
import Typography from 'Components/ds/Typography';
import MapleButton, { MapleButtonProps } from 'Components/ds/MapleButton';

// Types
import { AssetSymbol } from 'Components/ds/CoinIcon';

interface PoolNameLinkProps {
  id: string;
  poolName: string;
  poolDetails?: string;
  poolLink?: string;
  color?: MapleButtonProps['color'];
  disabled?: boolean;
  underline?: boolean;
  logoSize?: number;
  asset?: AssetSymbol;
}

const styles: { [key: string]: SxProps } = {
  logoIcon: {
    padding: '1px',
    backgroundColor: 'ds.background.primary',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'ds.line.primary',
    display: 'flex',
    alignItems: 'center',
  },
};

const PoolNameLink = ({
  id,
  poolName,
  poolDetails,
  poolLink,
  color = 'info',
  underline = true,
  disabled = false,
  logoSize = 20,
  asset,
}: PoolNameLinkProps) => {
  const logoStyles = {
    ...styles.logoIcon,
    width: `${logoSize}px`,
    height: `${logoSize}px`,
  };

  return (
    <MapleButton
      variant='text'
      color={color}
      size='medium'
      href={poolLink}
      disabled={disabled || !poolLink}
      sx={{ padding: 0, ...(underline ? { textDecoration: 'underline' } : {}) }}
      startIcon={
        asset ? (
          <PoolLogoWithAsset id={id} asset={asset} assetSize='sm' width={logoSize} height={logoSize} />
        ) : (
          <Stack sx={logoStyles} alignItems='center' justifyContent='center'>
            <PoolDelegateLogo id={id} width={18} height={18} />
          </Stack>
        )
      }
    >
      <Stack>
        {poolName}
        {poolDetails && (
          <Typography variant='paragraphXSmall' sx={{ fontStyle: 'italic' }} color='ds.neutral.500'>
            {poolDetails}
          </Typography>
        )}
      </Stack>
    </MapleButton>
  );
};

export default PoolNameLink;
