import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material';
import { Inbox, InboxTheme } from '@trycourier/react-inbox';

// Components
import Typography from 'Components/ds/Typography';
import MapleButton from 'Components/ds/MapleButton';

// Styles
import Nexa from 'Components/ds/MapleTheme/typography';
import ValueSansPro from 'Components/ds/MapleTheme/typography';

const buildTheme = (theme: Theme): InboxTheme => ({
  icon: {
    background: 'transparent',
    height: '40px',
    width: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    path: {
      fill: '#575764',
    },
  },
  unreadIndicator: {
    backgroundColor: theme.palette.ds.primary[500],
    top: 2,
    left: 20,
    fontFamily: ValueSansPro,
    height: '16px',
    lineHeight: '17px',
  },
  emptyState: {
    background: theme.palette.ds.background.primary,
  },
  root: {
    background: theme.palette.ds.background.primary,
    fontFamily: ValueSansPro,
    color: theme.palette.ds.text.primary,
  },
  container: {
    background: theme.palette.ds.background.primary,
  },
  messageList: {
    container: {
      background: theme.palette.ds.background.primary,
    },
  },
  message: {
    textElement: {
      fontFamily: ValueSansPro,
    },
    title: {
      fontFamily: Nexa,
      fontSize: '14px',
    },
    container: {
      padding: '10px 24px',
      '&:not(.read)': {
        background: theme.palette.ds.background.secondary,
      },
      '&:hover': {
        background: '#FFF2E6',
      },
    },
    unreadIndicator: {
      backgroundColor: theme.palette.ds.primary[500],
    },
  },
});

const NotificationInbox = () => {
  const theme = useTheme();

  return (
    <MapleButton
      data-testid='headerNotificationButton'
      size='medium'
      variant='outlined'
      color='tertiary'
      sx={{ height: '40px', width: '40px', px: 0 }}
      fullWidth
    >
      <Inbox
        renderHeader={() => (
          <Stack sx={{ padding: '16px 24px' }}>
            <Typography variant='h6' align='left'>
              Notifications
            </Typography>
          </Stack>
        )}
        renderNoMessages={() => (
          <Stack sx={{ height: '100%', justifyContent: 'center' }}>
            <Typography align='center'>You're all caught up</Typography>
          </Stack>
        )}
        theme={buildTheme(theme)}
        showUnreadMessageCount
        openLinksInNewTab={false}
      />
    </MapleButton>
  );
};

export default NotificationInbox;
