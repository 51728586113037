import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
}));

export default StyledContainer;
