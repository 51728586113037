import { useContext } from 'react';

import { AuthContext } from 'Context/Auth';

const useAuth = () => {
  const result = useContext(AuthContext);
  if (!result) throw new Error('Context used outside of its Provider!');

  return result;
};

export default useAuth;
