import { FC, ReactElement } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

// Assets
import { ReactComponent as MapleLogo } from 'Components/ds/assets/icon-logo-maple-full.svg';

// Components
import MapleContainer from 'Components/ds/MapleContainer';
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';

import { webappVersion } from 'Constants';

interface FooterLink {
  href: string;
  text: string;
}

interface FooterLinksProps {
  links: FooterLink[];
}

const FooterLinks: FC<FooterLinksProps> = ({ links }: FooterLinksProps): ReactElement => {
  return (
    <>
      {links.map(({ href, text }) => (
        <MapleLink
          key={href}
          href={href}
          underline='none'
          target='_blank'
          rel='noopener'
          sx={{ color: 'ds.text.primary' }}
          variant='paragraphSmall'
        >
          {text}
        </MapleLink>
      ))}
    </>
  );
};

const FooterComponent: FC = (): ReactElement => {
  const isDesktopDevice = useMediaQuery(useTheme().breakpoints.up('md'));
  const footerLinks = [
    {
      href: 'https://jobs.lever.co/maple-finance',
      text: 'Careers',
    },
    {
      href: 'mailto:contact@maple.finance',
      text: 'Contact',
    },
    {
      href: 'https://maplefinance.gitbook.io/maple/legal/interface-terms-of-use',
      text: 'Terms of Service',
    },
    {
      href: 'https://maplefinance.gitbook.io/maple/legal/privacy-policy',
      text: 'Privacy Policy',
    },
  ];

  return (
    <Box sx={{ backgroundColor: 'ds.background.primary' }} data-webapp-version={webappVersion}>
      <MapleContainer maxWidth='xl'>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
          sx={{ height: { xs: 'auto', md: '72px' }, py: { xs: 2 } }}
          spacing={2}
        >
          {isDesktopDevice && (
            <Link href='/' sx={{ lineHeight: 0 }}>
              <MapleLogo height={32} />
            </Link>
          )}
          {isDesktopDevice ? (
            <Stack direction='row' spacing={4} alignItems='center'>
              <FooterLinks links={footerLinks} />
            </Stack>
          ) : (
            <Stack direction='row' spacing={3}>
              <Stack direction='column' spacing={1} alignItems='flex-start'>
                <FooterLinks links={[footerLinks[0], footerLinks[1]]} />
              </Stack>
              <Stack direction='column' spacing={1} alignItems='flex-start'>
                <FooterLinks links={[footerLinks[2], footerLinks[3]]} />
              </Stack>
            </Stack>
          )}
          <Typography variant='paragraphSmall' sx={{ color: 'ds.text.default' }} component='span'>
            © 2023 Maple. All rights reserved.
          </Typography>
        </Stack>
      </MapleContainer>
    </Box>
  );
};

export default FooterComponent;
