const WalsheimBoldSrc = [
  `url('https://downloads.eth.maple.finance/fonts/GT-Walsheim-Bold.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/GT-Walsheim-Bold.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/GT-Walsheim-Bold.woff2') format('woff2')`,
];

const ValueSansProRegularSrc = [
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Regular.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Regular.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Regular.woff2') format('woff2')`,
];

const ValueSansProMediumSrc = [
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Medium.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Medium.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Medium.woff2') format('woff2')`,
];

const ValueSansProBoldSrc = [
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Bold.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Bold.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/ValueSansPro-Bold.woff2') format('woff2')`,
];

const NexaLightSrc = [
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Light.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Light.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Light.woff2') format('woff2')`,
];

const NexaRegularSrc = [
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Regular.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Regular.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Regular.woff2') format('woff2')`,
];

const NexaBoldSrc = [
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Bold.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Bold.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-Bold.woff2') format('woff2')`,
];

const NexaExtraBoldSrc = [
  `url('https://downloads.eth.maple.finance/fonts/Nexa-ExtraBold.ttf') format('truetype')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-ExtraBold.woff') format('woff')`,
  `url('https://downloads.eth.maple.finance/fonts/Nexa-ExtraBold.woff2') format('woff2')`,
];

const CssBaseline = () => {
  return {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Walsheim Bold';
          src: ${WalsheimBoldSrc.join(', ')};
          font-display: block;
        }

        @font-face {
          font-family: 'ValueSansPro';
          src: ${ValueSansProRegularSrc.join(', ')};
          font-weight: normal;
          font-display: block;
        }

        @font-face {
          font-family: 'ValueSansPro';
          src: ${ValueSansProMediumSrc.join(', ')};
          font-weight: 500;
          font-display: block;
        }

        @font-face {
          font-family: 'ValueSansPro';
          src: ${ValueSansProBoldSrc.join(', ')};
          font-weight: bold;
          font-display: block;
        }

        @font-face {
          font-family: 'Nexa';
          src: ${NexaLightSrc.join(', ')};
          font-weight: 200;
          font-display: block;
        }

        @font-face {
          font-family: 'Nexa';
          src: ${NexaRegularSrc.join(', ')};
          font-weight: normal;
          font-display: block;
        }

        @font-face {
          font-family: 'Nexa';
          src: ${NexaBoldSrc.join(', ')};
          font-weight: bold;
          font-display: block;
        }


        @font-face {
          font-family: 'Nexa';
          src: ${NexaExtraBoldSrc.join(', ')};
          font-weight: 800;
          font-display: block;
        }

      `,
    },
  };
};

export default CssBaseline;
