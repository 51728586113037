import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { SnackbarContent, SnackbarMessage, SnackbarKey, useSnackbar, CustomContentProps } from 'notistack';

// Assets
import { ReactComponent as CloseIcon } from 'Assets/icon-close.svg';
import { ReactComponent as AlertIcon } from 'Assets/icon-alert.svg';

// Components
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';

// Constants
import { etherscanUrl } from 'Constants';

const lineStyles = (backgroundColor: string) => ({
  height: '100%',
  width: '8px',
  backgroundColor,
  borderRadius: '10px',
  margin: '0 15px 0 0',
});

const variantBackgroundColor: any = {
  error: { faded: '#FFE6EC', solid: '#FF023F' },
  warning: { faded: '#FFFBEE', solid: '#FFCE21' },
  success: { faded: '#EEF8F2', solid: '#3EB672' },
};

interface MapleNotificationProps extends CustomContentProps {
  id: SnackbarKey;
  message?: SnackbarMessage;
  title?: string;
  transactionId?: string;
}

const MapleNotification = forwardRef<HTMLDivElement, MapleNotificationProps>(
  ({ id, message, title, transactionId, variant }: MapleNotificationProps, ref) => {
    const { closeSnackbar } = useSnackbar();
    const { t } = useTranslation(['transactions']);

    const { faded, solid } = variantBackgroundColor[variant];

    return (
      <SnackbarContent ref={ref}>
        <Box
          sx={{
            backgroundColor: faded,
            borderRadius: '5px',
            boxShadow: '0px 10px 20px 5px rgba(218, 217, 227, 0.31)',
            maxWidth: '600px',
          }}
        >
          <Stack flexDirection='row' sx={{ padding: '20px' }}>
            <Stack flexDirection='row' alignItems='center' sx={{ padding: '0 17px 0 0' }}>
              <Box sx={lineStyles(solid)} />
              <Box sx={{ color: solid, display: 'flex', alignItems: 'center' }}>
                <AlertIcon />
              </Box>
            </Stack>
            <Stack flexDirection='column' sx={{ padding: '0 24px 0 0' }}>
              {title && <Typography weight='bold'>{title}</Typography>}
              {message && (
                <Typography variant='paragraphXSmall' sx={{ wordBreak: 'break-word' }}>
                  {message}
                </Typography>
              )}
              {transactionId && (
                <Typography>
                  {t('general.viewTxOn')}{' '}
                  <MapleLink href={`${etherscanUrl}/tx/${transactionId}`} target='_blank' rel='noreferrer'>
                    {t('general.etherscan')}
                  </MapleLink>
                </Typography>
              )}
            </Stack>
            <Stack alignItems='flex-start'>
              <div style={{ cursor: 'pointer' }}>
                <CloseIcon onClick={() => closeSnackbar(id)} />
              </div>
            </Stack>
          </Stack>
          <Box
            sx={{
              height: '4px',
              animation: `$bounceEffect 15000ms ease-in-out`,
              width: 0,
              backgroundColor: variantBackgroundColor[variant]?.solid,
            }}
          />
        </Box>
      </SnackbarContent>
    );
  },
);

export default MapleNotification;
