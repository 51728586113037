import { createContext, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { ApolloQueryResult } from '@apollo/client';
import { useLocation } from 'react-router-dom';

// Constants
import { POLLING_INTERVAL } from 'Constants/index';

// Hooks
import { useAnalytics } from 'Context/SegmentAnalytics';
import useAuth from 'Hooks/useAuth';

// Types
import { CreateProfileSteps } from 'Pages/Lend/CreateAccountModal';
import {
  OrgFullFragment,
  PoolVerificationStatus,
  Role,
  SubmissionStatus,
  UserVerificationFragment,
  useGetUserDetailsQuery,
  useGetPoolPositionsLazyQuery,
  AccountType,
  GetUserDetailsQuery,
  WalletStatus,
  TaxResidency,
  VerificationSource,
  WalletFragment,
} from 'Graphql/schema-v2';

// Utils
import { getNow } from 'Utils/dates';

export type KycType = 'KYC' | 'KYB';

export type CreateAccountStatus = CreateProfileSteps;

interface OrgOrUserData {
  accountId: string | null;
  kycType: KycType;
  wallets: WalletFragment[];
  lendingPools: PoolVerificationStatus[];
  verification: UserVerificationFragment;
  securitizeId: string | null;
  isUsaTaxResident: boolean;
}

export interface OnboardingObj {
  createAccountStatus: CreateAccountStatus;
  userTypeCompleted: boolean;
  basicInfoCompleted: boolean;
  kycCompleted: boolean;
  accreditationCompleted: boolean;
  mlaCompleted: boolean;
  taxCompleted: boolean;
  allVerificationsStarted: boolean;
  walletsAdded: boolean;
  connectedWalletAdded: boolean;
  progressValue: number;
}

interface EventTracking {
  setLoginInitiated: (v: boolean) => void;
  trackLenderEvent: (event: string, additionalFields?: Record<string, string>) => void;
}

type UserData = OrgOrUserData &
  EventTracking & {
    // API data
    userId: string;
    email: string;
    accountType: AccountType | null;
    firstName: string;
    lastName: string;
    legalEntityCountry: string;
    legalEntityName: string;
    org: OrgFullFragment | null;
    hasLended: boolean;

    // Roles
    isSuperAdmin: boolean;
    isDelegate: boolean;
    isLender: boolean;
    isOrgOwner: boolean;
    isOrgAdmin: boolean;
    isOrgMember: boolean;

    // Fetching
    loading: boolean;
    refetch: () => Promise<ApolloQueryResult<GetUserDetailsQuery>>;
    pollData: (action: 'start' | 'stop') => void;
    onboarding: OnboardingObj;

    selectedWallet: string;
    setSelectedWallet: (value: string) => void;

    // modals
    isAddWalletModalOpen: boolean;
    setIsAddWalletModalOpen: (value: boolean) => void;
    isKycModalOpen: boolean;
    setIsKycModalOpen: (value: boolean) => void;
  };

const defaultVerification: UserVerificationFragment = {
  accreditedInvestor: SubmissionStatus.NotSubmitted,
  accreditedInvestorSource: VerificationSource.None,
  accreditedInvestorUpdatedAt: getNow(),
  createdAt: '',
  id: '',
  kyc: SubmissionStatus.NotSubmitted,
  kycSource: VerificationSource.None,
  kycUpdatedAt: getNow(),
  tax: SubmissionStatus.NotSubmitted,
  taxUpdatedAt: getNow(),
  accountMLA: SubmissionStatus.NotSubmitted,
  accountMLAUpdatedAt: getNow(),
  updatedAt: '',
  __typename: 'UserVerification',
};

const defaultOrgOrUserData: OrgOrUserData = {
  accountId: null,
  kycType: 'KYC',
  lendingPools: [],
  verification: defaultVerification,
  wallets: [],
  securitizeId: null,
  isUsaTaxResident: false,
};

const defaultUserData: UserData = {
  ...defaultOrgOrUserData,
  trackLenderEvent: () => {},
  setLoginInitiated: () => {},

  userId: '',
  email: '',
  accountType: null,
  firstName: '',
  lastName: '',
  legalEntityCountry: '',
  legalEntityName: '',
  org: null,
  hasLended: false,

  isSuperAdmin: false,
  isDelegate: false,
  isLender: false,
  isOrgOwner: false,
  isOrgAdmin: false,
  isOrgMember: false,

  loading: false,
  refetch: () => Promise.resolve({} as ApolloQueryResult<GetUserDetailsQuery>),
  pollData: () => {},

  onboarding: {
    createAccountStatus: 'accountType',
    userTypeCompleted: false,
    basicInfoCompleted: false,
    kycCompleted: false,
    accreditationCompleted: false,
    mlaCompleted: false,
    taxCompleted: false,
    allVerificationsStarted: false,
    walletsAdded: false,
    connectedWalletAdded: false,
    progressValue: 0,
  },

  selectedWallet: '',
  setSelectedWallet: () => {},

  // modals
  isAddWalletModalOpen: false,
  setIsAddWalletModalOpen: () => {},
  isKycModalOpen: false,
  setIsKycModalOpen: () => {},
};

export const UserDataContext = createContext<UserData>(defaultUserData);
UserDataContext.displayName = 'UserDataContext';

type UserDataProps = {
  children: ReactNode;
};

export const UserDataProvider = ({ children }: UserDataProps): ReactElement => {
  const { account, isActive } = useWeb3React<Web3Provider>();
  const { pathname } = useLocation();
  const { user: dynamicUser } = useDynamicContext();
  const { impersonated } = useAuth();
  const user = impersonated || dynamicUser;

  const analytics = useAnalytics();
  const [isAddWalletModalOpen, setIsAddWalletModalOpen] = useState(false);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState('');
  const [loginInitiated, setLoginInitiated] = useState(false);

  const onAuthRoute = ['/login', '/signup'].includes(pathname);

  const { data, loading, refetch, startPolling, stopPolling } = useGetUserDetailsQuery({
    variables: { id: user?.userId || 'no-user' },
    skip: !user?.userId || onAuthRoute,
    fetchPolicy: 'cache-and-network',
    // eslint-disable-next-line no-nested-ternary
    pollInterval: onAuthRoute ? 0 : user?.userId ? POLLING_INTERVAL : 0,
  });

  const [getPoolPositions, getPoolPositionsResponse] = useGetPoolPositionsLazyQuery();

  const accountType = data?.userById?.accountType ?? null;

  const userById = data?.userById ?? null;
  const firstName = data?.userById?.firstName ?? '';
  const lastName = data?.userById?.lastName ?? '';
  const email = data?.userById?.email ?? '';
  const userId = data?.userById?.id ?? '';

  const org = data?.userById?.org ?? null;
  const legalEntityName = org?.name ?? '';
  const legalEntityCountry = org?.countryOfOperation ?? '';

  const orgOrUserData = useMemo((): OrgOrUserData => {
    switch (accountType) {
      case AccountType.Individual:
        return {
          kycType: 'KYC',
          accountId: userById?.id || null,
          wallets: (userById?.wallets ?? []).filter(({ status }) => status === WalletStatus.Pass),
          lendingPools: userById?.lendingPools ?? [],
          verification: userById?.verification ?? defaultVerification,
          securitizeId: userById?.securitizeId ?? null,
          isUsaTaxResident: data?.userById?.taxResidency === TaxResidency.Usa,
        };
      case AccountType.LegalEntity:
        return {
          kycType: 'KYB',
          accountId: org?.id || null,
          wallets: (org?.wallets ?? []).filter(({ status }) => status === WalletStatus.Pass),
          lendingPools: org?.lendingPools ?? [],
          verification: org?.verification ?? defaultVerification,
          securitizeId: org?.securitizeId ?? null,
          isUsaTaxResident: data?.userById?.taxResidency === TaxResidency.Usa,
        };
      default:
        return defaultOrgOrUserData;
    }
  }, [accountType, org, userById]);

  const onboarding = useMemo(() => {
    let createAccountStatus: CreateAccountStatus = 'accountType';
    const userTypeCompleted = Boolean(accountType);
    let basicInfoCompleted = false;
    const hasIndividualInformation = Boolean(firstName);
    const hasLegalEntityInformation = Boolean(org);
    const { verification, wallets } = orgOrUserData;

    if (accountType === AccountType.Individual) {
      basicInfoCompleted = hasIndividualInformation;
      createAccountStatus = basicInfoCompleted ? 'basicInfoComplete' : 'individual';
    } else if (accountType === AccountType.LegalEntity) {
      basicInfoCompleted = hasIndividualInformation && hasLegalEntityInformation;
      const createAccountIndividualStatus = hasIndividualInformation ? 'legalEntity' : 'individual';
      createAccountStatus = hasLegalEntityInformation ? 'basicInfoComplete' : createAccountIndividualStatus;
    }

    const kycCompleted = verification.kyc === SubmissionStatus.Passed;
    const accreditationCompleted = verification.accreditedInvestor === SubmissionStatus.Passed;
    const mlaCompleted = verification.accountMLA === SubmissionStatus.Passed;
    const taxCompleted = verification.tax === SubmissionStatus.Passed;

    const allVerificationsStarted = [
      verification.kyc,
      verification.accreditedInvestor,
      verification.accountMLA,
      verification.tax,
    ].every(submissionStatus => submissionStatus !== SubmissionStatus.NotSubmitted);

    const walletsAdded = wallets.length > 0;
    const connectedWalletAdded = wallets.some(
      ({ address }: WalletFragment) => address.toLowerCase() === account?.toLowerCase(),
    );

    let progressValue = 0;
    const stepProgressValue = 33.33;
    if (basicInfoCompleted) progressValue += stepProgressValue;
    if (kycCompleted && accreditationCompleted && mlaCompleted && taxCompleted) progressValue += stepProgressValue;
    if (walletsAdded) progressValue += stepProgressValue;

    return {
      createAccountStatus,
      userTypeCompleted,
      basicInfoCompleted,
      kycCompleted,
      accreditationCompleted,
      mlaCompleted,
      taxCompleted,
      allVerificationsStarted,
      walletsAdded,
      connectedWalletAdded,
      progressValue: Math.round(progressValue),
      account,
    };
  }, [accountType, firstName, org, orgOrUserData, isActive, account]);

  const userRoles = useMemo(() => {
    const roles = data?.userById?.roles ?? [];
    const isSuperAdmin = roles.some(({ role }) => role === Role.Admin);

    return {
      isSuperAdmin,
      isDelegate: isSuperAdmin || roles.some(({ role }) => role === Role.Delegate),
      isLender: !!user,
      isOrgOwner: roles.some(({ role }) => role === Role.OrgOwner),
      isOrgAdmin: roles.some(({ role }) => role === Role.OrgAdmin),
      isOrgMember: roles.some(({ role }) => role === Role.OrgMember),
    };
  }, [data?.userById?.roles, user]);

  const pollData = (action: 'start' | 'stop') => {
    if (action === 'start') return startPolling(POLLING_INTERVAL);
    else return stopPolling();
  };

  const mixPanelProfile = useMemo(
    () => ({
      firstName,
      lastName,
      email: userById?.email,
      Entity: accountType === AccountType.LegalEntity ? 'yes' : 'no',
      ...(accountType === AccountType.LegalEntity ? { 'Entity Name': legalEntityName } : {}),
      "KYC'd": onboarding.kycCompleted ? 'yes' : 'no',
      Accredited: onboarding.accreditationCompleted ? 'yes' : 'no',
    }),
    [
      firstName,
      lastName,
      userById?.email,
      accountType,
      legalEntityName,
      onboarding.kycCompleted,
      onboarding.accreditationCompleted,
    ],
  );

  const mixPanelIdentify = useCallback(() => {
    if (!analytics.instance?.initialized || impersonated) return;

    analytics.identify(userById?.id, { ...mixPanelProfile });
  }, [analytics.instance, mixPanelProfile, userById?.id, impersonated]);

  const trackLenderEvent = useCallback(
    (eventName: string, additionalFields: Record<string, string> = {}) => {
      if (!analytics.instance?.initialized) return;

      analytics.track(eventName, {
        ...mixPanelProfile,
        ...additionalFields,
      });
    },
    [analytics.instance, mixPanelProfile],
  );

  useEffect(() => {
    if (userById) mixPanelIdentify();
  }, [userById]);

  useEffect(() => {
    if (email) {
      stopPolling();
    }
  }, [email]);

  useEffect(() => {
    const { wallets } = orgOrUserData;
    if (wallets.length) {
      const accounts = wallets.map(({ address }) => address.toLowerCase());
      getPoolPositions({ variables: { accounts } });
    }
  }, [orgOrUserData.wallets]);

  useEffect(() => {
    if (loginInitiated && !!userById) {
      trackLenderEvent('Lender - Login');
      setLoginInitiated(false);
    }
  }, [loginInitiated, !!userById]);

  useEffect(() => {
    if (isKycModalOpen) trackLenderEvent('Lender - KYC & Acc Modal - Opened');
  }, [isKycModalOpen]);

  useEffect(() => {
    if (isAddWalletModalOpen) trackLenderEvent('Lender - Add Wallet Modal - Opened');
  }, [isAddWalletModalOpen]);

  return (
    <UserDataContext.Provider
      value={{
        accountType,

        ...orgOrUserData,

        email,
        userId,
        firstName,
        lastName,
        legalEntityCountry,
        legalEntityName,
        onboarding,
        selectedWallet,
        setSelectedWallet,
        loading,
        org,
        hasLended: Boolean(getPoolPositionsResponse.data?.poolV2Positions.length),
        ...userRoles,

        refetch,
        pollData,
        isAddWalletModalOpen,
        isKycModalOpen,
        setIsAddWalletModalOpen,
        setIsKycModalOpen,

        trackLenderEvent,
        setLoginInitiated,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
