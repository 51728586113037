import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, Theme } from '@mui/material/styles';

// Font
import { ValueSansPro } from 'Components/ds/MapleTheme/typography';

// Styles
import { shadow as Shadow } from 'Components/ds/MapleTheme/designSystem';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    warningAlt: true;
  }

  interface ButtonPropsSizeOverrides {
    xs: true;
  }
}

type LoaderSize = 'xs' | 'small' | 'medium' | 'large';
type Color = 'primary' | 'secondary' | 'tertiary' | 'info' | 'warning' | 'warningAlt' | 'error' | 'success';
type ShadowProp = keyof typeof Shadow;

export interface MapleButtonProps extends ButtonProps {
  color?: 'inherit' | Color;
  loading?: boolean;
  shadow?: ShadowProp;
  shape?: 'standard' | 'round';
}

interface ButtonStylesInterface {
  bg: string;
  border?: string;
  borderWidth?: string;
  shadow?: string;
  text: string;
}

interface GenerateStylesProps {
  active: ButtonStylesInterface;
  hover: ButtonStylesInterface;
  focus: ButtonStylesInterface;
}

const loaderSizeMapping: { [size in LoaderSize]: number } = {
  xs: 10,
  small: 12,
  medium: 14,
  large: 16,
};

const generateDisabledStyles = (input: ButtonStylesInterface) => {
  return {
    '&.Mui-disabled': {
      backgroundColor: `${input.bg} !important`,
      borderColor: input.border || input.bg,
      color: `${input.text} !important`,
      opacity: 1,
      boxShadow: 'none',
      '& svg': {
        fill: input.text,
      },
    },
  };
};

const generateButtonStyles = ({ active, hover, focus }: GenerateStylesProps) => {
  return {
    backgroundColor: active.bg,
    borderColor: active.border || active.bg,
    borderWidth: active.borderWidth || '1px',
    color: active.text,
    boxShadow: 'none',
    '& svg': {
      fill: active.text,
    },
    '&:hover': {
      backgroundColor: hover.bg,
      borderColor: hover.border || hover.bg,
      borderWidth: hover.borderWidth || '1px',
      color: hover.text,
      boxShadow: hover.shadow ?? 'none',
      '& svg': {
        fill: hover.text,
      },
    },
    '&:focus': {
      backgroundColor: focus.bg,
      borderColor: focus.border || focus.bg,
      borderWidth: focus.borderWidth || '1px',
      color: focus.text,
      boxShadow: focus.shadow ?? 'none',
      '& svg': {
        fill: focus.text,
      },
    },
  };
};

const getStateColor = (theme: Theme, color: Color, isTertiary?: boolean) => {
  const stateColor = {
    primary: {
      active: theme.palette.ds.primary[500],
      hover: theme.palette.ds.primary[600],
      focus: theme.palette.ds.primary[700],
    },
    secondary: {
      active: theme.palette.ds.secondary[300],
      hover: theme.palette.ds.secondary[400],
      focus: theme.palette.ds.secondary[500],
    },
    tertiary: isTertiary
      ? {
          active: theme.palette.ds.neutral[300],
          hover: theme.palette.ds.neutral[400],
          focus: theme.palette.ds.neutral[700],
        }
      : {
          active: theme.palette.ds.neutral[600],
          hover: theme.palette.ds.neutral[700],
          focus: theme.palette.ds.neutral[800],
        },
    info: {
      active: theme.palette.ds.info[500],
      hover: theme.palette.ds.info[600],
      focus: theme.palette.ds.info[700],
    },
    warning: {
      active: theme.palette.ds.warning[500],
      hover: theme.palette.ds.warning[600],
      focus: theme.palette.ds.warning[700],
    },
    warningAlt: {
      active: theme.palette.ds.warningAlt[500],
      hover: theme.palette.ds.warningAlt[600],
      focus: theme.palette.ds.warningAlt[700],
    },
    error: {
      active: theme.palette.ds.error[500],
      hover: theme.palette.ds.error[600],
      focus: theme.palette.ds.error[700],
    },
    success: {
      active: theme.palette.ds.success[500],
      hover: theme.palette.ds.success[600],
      focus: theme.palette.ds.success[700],
    },
  };

  return stateColor[color];
};

const containedSystemColor = (theme: Theme, color: Color, shadow: undefined | ShadowProp) => {
  const stateColor = getStateColor(theme, color);

  return generateButtonStyles({
    active: {
      bg: stateColor.active,
      text: theme.palette.ds.text.contrast,
    },
    hover: {
      bg: stateColor.hover,
      text: theme.palette.ds.text.contrast,
      shadow: shadow ? Shadow[shadow] : Shadow.small,
    },
    focus: {
      bg: stateColor.focus,
      text: theme.palette.ds.text.contrast,
      shadow: shadow ? Shadow[shadow] : Shadow.small,
    },
  });
};

const outlinedSystemColor = (theme: any, color: Color, shadow: undefined | ShadowProp) => {
  const isTertiary = color === 'tertiary';
  const stateColor = getStateColor(theme, color, isTertiary);

  return generateButtonStyles({
    active: {
      bg: isTertiary ? theme.palette.ds.background.primary : 'transparent',
      border: stateColor.active,
      text: isTertiary ? theme.palette.ds.neutral[700] : stateColor.active,
    },
    hover: {
      bg: isTertiary ? theme.palette.ds.background.primary : 'transparent',
      border: stateColor.hover,
      text: isTertiary ? theme.palette.ds.neutral[700] : stateColor.hover,
      shadow: shadow ? Shadow[shadow] : Shadow.small,
    },
    focus: {
      bg: isTertiary ? theme.palette.ds.background.primary : 'transparent',
      border: stateColor.focus,
      text: isTertiary ? theme.palette.ds.neutral[700] : stateColor.focus,
      shadow: shadow ? Shadow[shadow] : Shadow.small,
    },
  });
};

const textSystemColor = (theme: any, color: Color) => {
  const stateColor = getStateColor(theme, color);

  return generateButtonStyles({
    active: {
      bg: 'transparent',
      borderWidth: '0',
      text: stateColor.active,
    },
    hover: { bg: 'transparent', borderWidth: '0', text: stateColor.hover },
    focus: { bg: 'transparent', borderWidth: '0', text: stateColor.focus },
  });
};

const disabledNonTertiary = (variant: MapleButtonProps['variant'], theme: Theme) => {
  switch (variant) {
    case 'text':
      return generateDisabledStyles({
        text: theme.palette.ds.neutral[400],
        bg: 'transparent',
        border: theme.palette.ds.background.primary,
      });
    case 'contained':
      return generateDisabledStyles({
        text: theme.palette.ds.neutral[400],
        bg: theme.palette.ds.background.default,
        border: theme.palette.ds.background.default,
      });
    case 'outlined':
      return generateDisabledStyles({
        text: theme.palette.ds.neutral[400],
        bg: theme.palette.ds.background.primary,
        border: theme.palette.ds.neutral[300],
      });
  }
};

const disabledTertiary = (variant: MapleButtonProps['variant'], theme: Theme) => {
  switch (variant) {
    case 'outlined':
      return generateDisabledStyles({
        text: theme.palette.ds.neutral[400],
        bg: theme.palette.ds.background.primary,
        border: theme.palette.ds.neutral[100],
      });
    case 'contained':
      return generateDisabledStyles({
        text: theme.palette.ds.neutral[400],
        bg: theme.palette.ds.background.primary,
        border: theme.palette.ds.neutral[300],
      });
    case 'text':
      return generateDisabledStyles({
        text: theme.palette.ds.neutral[400],
        bg: 'transparent',
        border: theme.palette.ds.background.primary,
      });
  }
};

const StyledButton = styled(Button)<MapleButtonProps>(
  ({ color = 'primary', variant = 'contained', size = 'large', shape = 'standard', shadow, fullWidth, theme }) => ({
    textTransform: 'none',
    fontFamily: ValueSansPro,
    fontStyle: 'normal',
    fontWeight: 500,
    borderRadius: '6px',
    borderWidth: '1px',
    borderStyle: 'solid',
    // whiteSpace: 'nowrap',
    minWidth: 'auto',
    // Sizes
    ...(size === 'large' && {
      fontSize: '16px',
      lineHeight: '24px',
      height: '48px !important',
      width: fullWidth ? '100% !important' : 'auto',
    }),
    ...(size === 'medium' && {
      fontSize: '14px',
      lineHeight: '20px',
      height: '40px',
    }),
    ...(size === 'small' && {
      fontSize: '12px',
      lineHeight: '20px',
      height: '32px',
      padding: '12px',
    }),
    ...(size === 'xs' && {
      fontSize: '10px',
      lineHeight: '20px',
      height: '24px',
      borderRadius: '4px',
    }),
    // Button Shape
    ...(shape === 'round' && {
      borderRadius: '48px',
    }),
    // Primary Color (Maple Orange)
    ...(color === 'primary' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'primary', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'primary', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'primary')),
    }),
    // Secondary Color (Maple Cobalt)
    ...(color === 'secondary' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'secondary', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'secondary', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'secondary')),
    }),
    // Tertiary Color (gray)
    ...(color === 'tertiary' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'tertiary', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'tertiary', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'tertiary')),
    }),
    // Info Color (blue)
    ...(color === 'info' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'info', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'info', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'info')),
    }),
    // Success Color (green)
    ...(color === 'success' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'success', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'success', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'success')),
    }),
    // Warning Color (dark red)
    ...(color === 'warning' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'warning', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'warning', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'warning')),
    }),
    // Warning Alternate Color (light red)
    ...(color === 'error' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'error', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'error', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'error')),
    }),
    // Warning Alternate Color (light red)
    ...(color === 'warningAlt' && {
      ...(variant === 'contained' && containedSystemColor(theme, 'warningAlt', shadow)),
      ...(variant === 'outlined' && outlinedSystemColor(theme, 'warningAlt', shadow)),
      ...(variant === 'text' && textSystemColor(theme, 'warningAlt')),
    }),
    // Disabled
    ...(color === 'tertiary' && disabledTertiary(variant, theme)),
    ...(color !== 'tertiary' && disabledNonTertiary(variant, theme)),
  }),
);

const MapleButton = ({ loading, ...props }: MapleButtonProps) => {
  if (loading)
    return (
      <StyledButton
        {...props}
        disabled={true}
        startIcon={
          <CircularProgress
            size={props.size ? loaderSizeMapping[props.size] : loaderSizeMapping.large}
            color='inherit'
          />
        }
      />
    );

  return <StyledButton {...props} />;
};

export default MapleButton;
