import Card, { CardProps } from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import { shadow } from 'Components/ds/MapleTheme/designSystem';

export interface MapleCardProps extends CardProps {
  hoverable?: boolean;
}

const StyledCard = styled(Card)<MapleCardProps>(({ theme, variant }) => ({
  padding: '0',
  boxShadow: shadow.medium,
  backgroundColor: 'ds.background.primary',
  borderRadius: '12px',
  ...(variant === 'outlined' && {
    border: `1px solid ${theme.palette.ds.line.primary}`,
    boxShadow: 'none',
  }),
}));

const StyledHoverableCard = styled(StyledCard)<MapleCardProps>(({ theme }) => ({
  cursor: 'pointer',
  border: '1px solid transparent',
  '&:hover': {
    borderColor: theme.palette.ds.primary[500],
    boxShadow: `0px 0px 0px 4px ${theme.palette.ds.primary[100]} !important`,
  },
}));

const MapleCard = ({ hoverable = false, ...props }: MapleCardProps) => {
  if (hoverable) return <StyledHoverableCard {...props} />;

  return <StyledCard {...props} />;
};

export default MapleCard;
