import { FC, ReactElement, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Assets
import { ReactComponent as CoinbaseIcon } from 'Components/ds/assets/icon-coinbase-wallet.svg';
import { ReactComponent as MetamaskIcon } from 'Components/ds/assets/icon-metamask.svg';
import { ReactComponent as WalletConnectIcon } from 'Components/ds/assets/icon-wallet-connect.svg';

// Components
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';
import MapleButton from 'Components/ds/MapleButton';
import ModalContent from 'Components/ds/ModalContent';
import ModalHeader from 'Components/ds/ModalHeader';
import ModalFooter from 'Components/ds/ModalFooter';

// Web3
import { ConnectionType } from 'Services/web3/connectors';

// Hooks
import useConnectWalletV2 from 'Hooks/useConnectWalletV2';

enum StepType {
  selectWallet = 'selectWallet',
  installMetamask = 'installMetamask',
}

const WalletConnectModalV2: FC = (): ReactElement => {
  const { connectWallet, walletConnectContent } = useConnectWalletV2();
  const isDesktopDevice = useMediaQuery(useTheme().breakpoints.up('md'));

  const [step, setStep] = useState<StepType>(StepType.selectWallet);

  const walletTypes = useMemo(() => {
    const wallets = [
      {
        title: 'MetaMask',
        icon: <MetamaskIcon />,
        onClick: () =>
          (window as any).ethereum?.isMetaMask
            ? connectWallet(ConnectionType.INJECTED)
            : setStep(StepType.installMetamask),
      },
      {
        title: 'WalletConnect',
        icon: <WalletConnectIcon />,
        onClick: () => connectWallet(ConnectionType.WALLET_CONNECT),
        connectionType: ConnectionType.WALLET_CONNECT,
      },
      {
        title: 'Coinbase',
        icon: <CoinbaseIcon width={24} height={24} />,
        onClick: () => connectWallet(ConnectionType.COINBASE),
        connectionType: ConnectionType.COINBASE,
      },
    ];

    return wallets;
  }, [window]);

  const { title, message } = walletConnectContent;

  return (
    <>
      <ModalHeader withLogo>
        <Typography variant={isDesktopDevice ? 'h4' : 'h6'} weight='extraBold' align='center'>
          {title ? title : 'Connect Your Wallet'}
        </Typography>
      </ModalHeader>

      {step === StepType.selectWallet ? (
        <ModalContent>
          <Stack direction='column' spacing={3} alignItems='center'>
            <Typography variant='paragraphSmall' component='span'>
              {message ? message : 'Select Wallet type to connect.'}
            </Typography>
            <Stack direction={isDesktopDevice ? 'row' : 'column'} spacing={2}>
              {walletTypes.map(({ title, icon, onClick }) => (
                <MapleButton
                  key={title}
                  variant='outlined'
                  color='tertiary'
                  data-testid={title}
                  startIcon={icon}
                  onClick={onClick}
                  size='medium'
                  sx={{ width: '160px' }}
                  shadow='small'
                >
                  {title}
                </MapleButton>
              ))}
            </Stack>
          </Stack>
        </ModalContent>
      ) : (
        <ModalContent>
          <Stack direction='column' spacing={3} alignItems='center'>
            <Typography variant='paragraphSmall' component='span'>
              {message ? message : "Don't have MetaMask installed?"}
            </Typography>
            <MapleButton
              variant='outlined'
              color='tertiary'
              startIcon={<MetamaskIcon />}
              size='medium'
              shadow='small'
              onClick={() => window.open('https://metamask.io/download.html', '_blank')}
            >
              Get MetaMask
            </MapleButton>
            <MapleLink
              variant='paragraphSmall'
              underline='always'
              link='info'
              onClick={() => setStep(StepType.selectWallet)}
            >
              Go Back
            </MapleLink>
          </Stack>
        </ModalContent>
      )}

      <ModalFooter>
        <Typography variant='paragraphSmall' align='center'>
          Need help getting started?{' '}
          <MapleLink href='mailto:contact@maple.finance' underline='always' link='info' variant='paragraphSmall'>
            Contact Maple
          </MapleLink>
        </Typography>
      </ModalFooter>
    </>
  );
};

export default WalletConnectModalV2;
