import { useState, ReactElement, ReactNode } from 'react';

// Context
import WalletContext from 'Context/Wallet/WalletContext';
import initState from 'Context/Wallet/initState';

// Types
import { IWalletProviderProps } from 'Context/Wallet/types';

export default function WalletProvider({ children }: IWalletProviderProps): ReactElement {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [walletIsActivating, setWalletIsActivating] = useState<boolean>(false);
  const [walletIsDeactivating, setWalletIsDeactivating] = useState<boolean>(false);
  const [walletConnectContent, setWalletConnectContent] = useState<{
    isError?: boolean;
    title?: string | ReactNode;
    message?: string | ReactNode;
  }>({ isError: false, title: undefined, message: undefined });

  const handleSetShowModal = (
    show: boolean,
    options?: { isError?: boolean; title?: string | ReactNode; message: string | ReactNode },
  ) => {
    setShowModal(show);
    setWalletConnectContent({
      isError: options?.isError ?? false,
      title: options?.title,
      message: options?.message,
    });
  };

  const state = {
    ...initState,
    showModal,
    setShowModal: handleSetShowModal,
    walletIsActivating,
    setWalletIsActivating,
    walletIsDeactivating,
    setWalletIsDeactivating,
    walletConnectContent,
  };

  return <WalletContext.Provider value={state}>{children}</WalletContext.Provider>;
}
