import { FC, ReactElement } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

// Components
import MapleDialog from 'Components/ds/MapleDialog';
import MapleDialogContent from 'Components/ds/MapleDialogContent';
import MapleDialogTitle from 'Components/ds/MapleDialogTitle';
import MapleDialogActions from 'Components/ds/MapleDialogActions';
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';

const ErrorFallback: FC<FallbackProps> = (): ReactElement => {
  const { t } = useTranslation('common');

  return (
    <MapleDialog onClose={() => null} open={true} data-testid='errorboundary'>
      <MapleDialogTitle>{t('app.error.500.title')}</MapleDialogTitle>
      <MapleDialogContent>
        <Typography>{t('app.error.500.message')}</Typography>
      </MapleDialogContent>
      <MapleDialogActions>
        <MapleLink href='/' rel='noreferrer' underline='none'>
          {t('app.error.goHome')}
        </MapleLink>
      </MapleDialogActions>
    </MapleDialog>
  );
};

export default ErrorFallback;
