import { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';

interface SeoMetaTagsProps {
  title?: string;
  description?: string;
  ogImage?: string;
  twitterImage?: string;
}

const globalTitle = 'Maple Finance';
const globalDescription =
  'Maple Finance is a decentralized corporate debt marketplace that allows corporates & institutions to borrow in a capital-efficient way from Liquidity Pools';
const globalImage = 'https://maple.finance/maple.webp';

const SeoMetaTags: FC<SeoMetaTagsProps> = ({
  title = globalTitle,
  description = globalDescription,
  ogImage = globalImage,
  twitterImage = globalImage,
}: SeoMetaTagsProps): ReactElement => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      {/* Open Graph tags */}
      <meta property='og:type' content='website' />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:url' content='https://app.maple.finance/' />
      <meta property='og:site_name' content='Maple Finance' />
      {/* Twitter tags */}
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={twitterImage} />
      <meta name='twitter:site' content='@maplefinance' />
      <meta name='twitter:creator' content='@maplefinance' />
    </Helmet>
  );
};

export default SeoMetaTags;
