import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HashRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// Providers
import { DynamicProvider } from 'Context/Dynamic/Dynamic';

// Import i18n JSON
import 'I18n';

// Modules
import App from 'App';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_PROJECT ?? 'unknown',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  denyUrls: [/localhost/],
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <HashRouter>
    <DynamicProvider>
      <App />
    </DynamicProvider>
  </HashRouter>,
);
