import dayjs from 'dayjs';
import { DATE_FORMAT } from 'Constants';
import { CountdownToTimestamp } from 'Shared/interfaces';
import { pluralize } from 'Utils/string';

export const transformDaysToSeconds = (days: number): number => {
  return days * 24 * 60 * 60;
};

export const transformMinutesToMilliseconds = (minutes: number): number => {
  return minutes * 60 * 1000;
};

export const transformDaysToMilliseconds = (days: number): number => {
  return transformDaysToSeconds(days) * 1000;
};

export const i18nTimestampDate = (timestamp: number | string, options = {}, language = 'en') => {
  return new Intl.DateTimeFormat(language, options).format(new Date(timestamp));
};

export const getNow = (): string => {
  const now = new Date();

  return now.toISOString();
};

export const getTimestamp = (dateString: string): number => {
  const date = new Date(dateString);

  return Math.round(date.getTime() / 1000); // timestamp in seconds
};

export const getNowTimestamp = (): number => {
  const now = getNow();

  return getTimestamp(now);
};

// Util takes in difference between now date and countdown date timestamp in MILLISECONDS
export const getCountdownToTimestamp = (
  timeUntilDateInMilliseconds: number,
  showAllTimes?: boolean,
): CountdownToTimestamp => {
  const days = Math.floor(timeUntilDateInMilliseconds / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeUntilDateInMilliseconds / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeUntilDateInMilliseconds / 1000 / 60) % 60);
  const seconds = Math.floor((timeUntilDateInMilliseconds / 1000) % 60);

  if (showAllTimes) return { days, hours, minutes, seconds };

  if (days !== 0) {
    return {
      days,
      hours,
    };
  } else if (hours !== 0) {
    return {
      hours,
      minutes,
    };
  } else {
    return {
      minutes,
      seconds,
    };
  }
};

export const getFormattedDateAndTime = (timestamp: number) => {
  const dateObj = new Date(timestamp);

  const date = i18nTimestampDate(timestamp, DATE_FORMAT);
  const time = dateObj.toLocaleTimeString('en-US');

  return { date, time, timestamp };
};

export const getBlockTimestamp = async (provider: any) => {
  try {
    const blockNumber = await provider.getBlockNumber();
    const block = await provider.getBlock(blockNumber ?? 0);

    return block.timestamp;
  } catch (error) {
    console.error('error', error);

    return getNowTimestamp();
  }
};

export const getMonthName = (month: number) => {
  const date = new Date();
  date.setMonth(month - 1);

  return i18nTimestampDate(date.getTime(), { month: 'long' });
};

/**
 * Get last day of the month given the year and the month
 * @param  {number} year Year in full number format (ie: 2022)
 * @param  {number} month Month number (1: January, 2: Februrary, ...)
 * @return {number} Returns the last number of the month (ie: 30, 31, 28 or 29)
 */
export const getLastDayOfMonth = (year: number, month: number) => {
  return new Date(year, month, 0).getDate();
};

/**
 * Get stringified countdown based on the time period
 * @param  {number} time Time period (in milliseconds). ie (86400000 is 1 day)
 * @return {string} Returns the coundwon. ie. "1 day 4 hours 2 minutes 84 seconds"
 */
export const getCountdown = (time: number) => {
  if (time <= 0) return '0 Days 0 Hours 0 Minutes 0 Seconds';

  const { days, hours, minutes, seconds } = getCountdownToTimestamp(time);
  const daysCopy = days ? `${days} ${pluralize(days, 'Day')} ` : '';
  const hoursCopy = hours ? `${hours} ${pluralize(hours, 'Hour')} ` : '';
  const minutesCopy = minutes ? `${minutes} ${pluralize(minutes, 'Minute')} ` : '';
  const secondsCopy = seconds ? `${seconds} ${pluralize(seconds, 'Second')} ` : '';

  return `${daysCopy}${hoursCopy}${minutesCopy}${secondsCopy}`.trim();
};

/**
 * Get time range based on seconds amount
 * Returns days, hours or minutes based on:
 * 1. X Days: if more than 48 hours
 * 2. X Hours: if more than 1 hour but less than 48 hours (we sum also dust hours if needed)
 * 3. X Minutes: if less than 1 hour
 * 4. 0 Hours: if less than 1 minute
 * @param  {number} time Time period (in seconds).
 * @return {[value: string]: number, [symbol: string]: string} Returns the coundwon. ie. "1 day 4 hours 2 minutes 84 seconds"
 */
export const getWithdrawTimeRange = (timeRange: number) => {
  const { days, hours, minutes } = getCountdownToTimestamp(timeRange * 1000);

  if (days && days >= 2) return { value: days, symbol: 'Days' };

  if (days && days < 2) return { value: days * 24 + (hours ?? 0), symbol: 'Hours' };

  if (hours) return { value: hours, symbol: hours === 1 ? 'Hour' : 'Hours' };

  if (minutes) return { value: minutes, symbol: minutes === 1 ? 'Minute' : 'Minutes' };

  return { value: 0, symbol: 'Hours' };
};

/**
 * Combines a date and a time string into a single Unix timestamp.
 *
 * @param {string} dateStr - The date string in ISO format (e.g., "2023-12-19T00:00:00.000Z").
 * @param {string} timeStr - The time string in ISO format (e.g., "2023-12-10T15:34:24.383Z").
 * @returns {number} The Unix timestamp representing the combined date and time.
 */
export const combineDateAndTimeToTimestamp = (dateStr: string, timeStr: string): number => {
  if (!dateStr || !timeStr) return 0;

  // Extract the date part from the date string
  const datePart = dayjs.utc(dateStr).format('YYYY-MM-DD');
  // Extract the HH and mm part from the time string
  const timePart = dayjs.utc(timeStr).format('HH:mm');

  // Combine the date and time parts
  const combinedDateTime = `${datePart}T${timePart}:00.000Z`;

  // Convert the combined date and time to a Unix timestamp and return it
  return getTimestamp(combinedDateTime);
};

// Example usage:
// const timestamp = combineDateAndTimeToTimestamp("2023-12-19T00:00:00.000Z", "2023-12-10T15:34:24.383Z");

/**
 * Formats a time range given in days and hours
 * @param {number} days - The number of days
 * @param {number} hours - The number of hours
 * @return {string} Formatted time range string
 */
export const formatTimeRange = (days: number, hours: number): string => {
  const daysString = days > 0 ? `${days} ${pluralize(days, 'Day')}` : '';
  const hoursString = hours > 0 ? `${hours} ${pluralize(hours, 'Hour')}` : '';

  if (daysString && hoursString) {
    return `${daysString}, ${hoursString}`;
  } else {
    return daysString || hoursString || '0 Hours';
  }
};
