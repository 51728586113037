import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

import { shadow } from 'Components/ds/MapleTheme/designSystem';

const StyledAppBar = styled(AppBar)(() => ({
  boxShadow: shadow.medium,
  backgroundColor: '#FFFFFF',
  color: '#1C1616 !important',
}));

export default StyledAppBar;
