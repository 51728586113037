import { useRef, useState, useLayoutEffect } from 'react';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

// Styles
import { zIndex } from 'Components/ds/MapleTheme/designSystem';

const styles: { [key: string]: SxProps } = {
  gradientBoxTop: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '70px',
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    pointerEvents: 'none',
    zIndex: zIndex.low,
  },
  gradientBoxBottom: {
    position: 'absolute',
    bottom: '24px',
    width: '100%',
    height: '70px',
    background: 'linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
    pointerEvents: 'none',
    zIndex: zIndex.low,
  },
};

const StyledDialogContentWrapper = styled(Box)(() => ({
  position: 'relative',
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: 0,
  marginBottom: theme.spacing(3),
  overflowY: 'scroll',
  overflowX: 'visible',
}));

const MapleDialogContent = ({ sx, children, ...props }: DialogContentProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [displayGradientTopBox, setDisplayGradientTopBox] = useState(false);
  const [displayGradientBottomBox, setDisplayGradientBottomBox] = useState(false);
  const [unavailableSpace, setUnavailableSpace] = useState(540);

  const handleScroll = ({ target }: any) => {
    const { scrollHeight, scrollTop, clientHeight } = target;

    const isBottom = Math.trunc(scrollHeight - scrollTop) === clientHeight;
    setDisplayGradientTopBox(scrollTop > 0);
    setDisplayGradientBottomBox(!isBottom);
  };

  useLayoutEffect(() => {
    if (ref.current) {
      const { parentElement } = ref.current;
      const outerDialogSpace = 142; // Space around the dialog
      const innerDialogSpace = 80; // Padding of the dialog
      const innerMargin = 48; // Bottom margin of title and content
      let innerElementsHeight = 0;

      if (parentElement) {
        for (let i = 0; i < parentElement?.children.length; i++) {
          const child = parentElement.children[i];
          if (child !== ref.current && child.getAttribute('type') !== 'button') {
            innerElementsHeight += child.clientHeight;
          }
        }
      }
      setUnavailableSpace(outerDialogSpace + innerDialogSpace + innerMargin + innerElementsHeight);
    }
  }, [ref, children]);

  useLayoutEffect(() => {
    if (ref) {
      const scrollHeight = ref.current?.scrollHeight || 0;
      const clientHeight = ref.current?.clientHeight || 0;
      setDisplayGradientBottomBox(scrollHeight > clientHeight);
    }
  }, [ref, children]);

  return (
    <StyledDialogContentWrapper ref={ref}>
      {displayGradientTopBox && <Box sx={styles.gradientBoxTop} />}
      {displayGradientBottomBox && <Box sx={styles.gradientBoxBottom} />}
      <StyledDialogContent
        onScroll={handleScroll}
        sx={{ ...sx, maxHeight: `calc(100vh - ${unavailableSpace}px)` }}
        {...props}
      >
        {children}
      </StyledDialogContent>
    </StyledDialogContentWrapper>
  );
};

export default MapleDialogContent;
