declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    small: true;
    medium: true;
    large: true;
  }
}

const breakpoints = {
  values: {
    // Default values
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 3000,

    //
    // Design System
    small: 0,
    medium: 600,
    large: 1136,
  },
};

export default breakpoints;
