import { ReactElement } from 'react';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

// Assets
import { ReactComponent as ExternalLinkIcon } from 'Components/ds/assets/icon-external-link.svg';

// Components
import InfoTooltip from 'Components/ds/InfoTooltip';
import Typography, { StyledTypographyProps } from 'Components/ds/Typography';

// Types

type SizeProp = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';
type ColorProp = 'primary' | 'contrast';

interface SizeMapping {
  label: StyledTypographyProps;
  value: StyledTypographyProps;
  symbol: StyledTypographyProps;
  description: StyledTypographyProps;
}

interface ColorMapping {
  label: string;
  value: string;
}

const sizeMapping: { [size in SizeProp]: SizeMapping } = {
  xxl: {
    label: {
      variant: 'paragraphLarge',
      weight: 'medium',
    },
    value: {
      variant: 'displayLarge',
      weight: 'bold',
    },
    symbol: {
      variant: 'h6',
      weight: 'bold',
    },
    description: {
      variant: 'paragraphLarge',
      weight: 'regular',
    },
  },
  xl: {
    label: {
      variant: 'paragraphLarge',
      weight: 'medium',
    },
    value: {
      variant: 'h3',
      weight: 'bold',
    },
    symbol: {
      variant: 'h6',
      weight: 'bold',
    },
    description: {
      variant: 'paragraphSmall',
      weight: 'regular',
    },
  },
  l: {
    label: {
      variant: 'paragraphSmall',
      weight: 'regular',
    },
    value: {
      variant: 'h4',
      weight: 'extraBold',
    },
    symbol: {
      variant: 'h4',
      weight: 'regular',
    },
    description: {
      variant: 'paragraphSmall',
      weight: 'regular',
    },
  },
  m: {
    label: {
      variant: 'paragraphXSmall',
      weight: 'regular',
    },
    value: {
      variant: 'h5',
      weight: 'extraBold',
    },
    symbol: {
      variant: 'h5',
      weight: 'regular',
    },
    description: {
      variant: 'paragraphXSmall',
      weight: 'regular',
    },
  },
  s: {
    label: {
      variant: 'paragraphSmall',
      weight: 'regular',
    },
    value: {
      variant: 'h6',
      weight: 'extraBold',
    },
    symbol: {
      variant: 'h6',
      weight: 'regular',
    },
    description: {
      variant: 'paragraphSmall',
      weight: 'regular',
    },
  },
  xs: {
    label: {
      variant: 'paragraphXSmall',
      weight: 'regular',
    },
    value: {
      variant: 'h6',
      weight: 'extraBold',
    },
    symbol: {
      variant: 'h6',
      weight: 'regular',
    },
    description: {
      variant: 'paragraphSmall',
      weight: 'regular',
    },
  },
};

export interface StatProps {
  label?: string;
  labelTooltip?: string | ReactElement;
  labelIcon?: ReactElement;
  labelLink?: string;
  value: string | number | ReactElement;
  align?: 'flex-start' | 'flex-end' | 'center';
  symbol?: string;
  description?: string;
  size?: SizeProp;
  color?: ColorProp;
  icon?: ReactElement;
  testId?: string;
}

const colorMapping: { [color in ColorProp]: ColorMapping } = {
  primary: {
    label: 'ds.text.secondary',
    value: 'ds.text.primary',
  },
  contrast: {
    label: 'ds.text.contrast',
    value: 'ds.text.contrast',
  },
};

const Stat = ({
  label,
  labelTooltip,
  labelIcon,
  labelLink,
  value,
  symbol,
  description,
  icon,
  size = 's',
  color = 'primary',
  align = 'flex-start',
  testId,
}: StatProps) => {
  const theme = useTheme();

  return (
    <Stack
      direction='row'
      justifyContent={{ xs: 'center', md: 'flex-start' }}
      alignItems='center'
      spacing={{ xs: 2, md: 3 }}
    >
      {icon && icon}

      <Stack direction='column' justifyContent='flex-start' alignItems={align} sx={{ width: '100%' }}>
        <Stack direction='row' spacing={1} alignItems='center'>
          {labelIcon}
          {label && (
            <Stack direction='row' spacing={0.5}>
              {' '}
              <Typography
                {...sizeMapping[size].label}
                color={colorMapping[color].label}
                textAlign={{ xs: 'center', md: 'left' }}
                component='span'
              >
                {label}
              </Typography>
              {labelTooltip && (
                <InfoTooltip title={labelTooltip} color={color === 'primary' ? 'ds.neutral.400' : 'ds.text.contrast'} />
              )}
            </Stack>
          )}
          {labelLink && (
            <Link href={labelLink} target='_blank' rel='noopener noreferrer' sx={{ lineHeight: '16px !important' }}>
              <ExternalLinkIcon fill={theme.palette.ds.info[500]} width='16px' height='16px' />
            </Link>
          )}
        </Stack>

        <Stack spacing={1} direction='row' alignItems='baseline' justifyContent={{ xs: 'center', md: 'flex-start' }}>
          <Typography
            {...sizeMapping[size].value}
            color={colorMapping[color].value}
            component='span'
            data-testid={testId}
          >
            {value}
          </Typography>
          {symbol && (
            <Typography {...sizeMapping[size].symbol} color={colorMapping[color].value} component='span'>
              {symbol}
            </Typography>
          )}
        </Stack>
        {description && (
          <Typography {...sizeMapping[size].label} color={colorMapping[color].label} component='span' sx={{ mt: 0.2 }}>
            {description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Stat;
