// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    // @TODO uncomment above props when we complete migration to our design system
    // Not longer usefull props
    // subtitle1: false;
    // subtitle2: false;
    // body1: false;
    // body2: false;
    // button: false;
    // caption: false;
    // New heading props
    displayLarge: true;
    displaySmall: true;
    h7: true;
    // New paragraph props
    paragraphLarge: true;
    paragraphMedium: true;
    paragraphSmall: true;
    paragraphXSmall: true;
  }
}

export const Inter = ['Inter', 'Helvetica', 'Calibri', 'Trebuchet MS', 'sans-serif'].join(',');

export const WalsheimBold = ['Walsheim Bold', 'Helvetica', 'Calibri', 'Trebuchet MS', 'sans-serif'].join(',');

export const ValueSansPro = ['ValueSansPro', 'Helvetica', 'Calibri', 'Trebuchet MS', 'sans-serif'].join(',');

export const Nexa = ['Nexa', 'Helvetica', 'Calibri', 'Trebuchet MS', 'sans-serif'].join(',');

const typography = {
  fontFamily: Inter,
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemibold: 700,
  fontWeightBold: 700,
  fontWeightExtrabold: 800,
  h1: {
    lineHeight: '64px',
    fontSize: '56px',
  },
  h2: {
    lineHeight: '54px',
    fontSize: '46px',
  },
  h3: {
    lineHeight: '46px',
    fontSize: '38px',
  },
  h4: {
    lineHeight: '38px',
    fontSize: '30px',
  },
  h5: {
    lineHeight: '32px',
    fontSize: '24px',
  },
  h6: {
    lineHeight: '28px',
    fontSize: '20px',
  },
  subtitle1: {
    lineHeight: '24px',
    fontSize: '18px',
    fontWeight: 700,
  },
  subtitle2: {
    lineHeight: '22px',
    fontSize: '14px',
    fontWeight: 700,
  },
  body1: {
    lineHeight: '22px',
    fontSize: '16px',
  },
  body2: {
    lineHeight: '22px',
    fontSize: '14px',
  },
  caption: {
    lineHeight: '20px',
    fontSize: '12px',
  },
  button: {
    lineHeight: '22px',
    fontSize: '16px',
    fontWeight: 500,
  },
};

export default typography;
