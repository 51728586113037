import { Web3ReactHooks } from '@web3-react/core';

import { Connector } from '@web3-react/types';
import { GnosisSafe } from '@web3-react/gnosis-safe';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { CHAIN_ID } from 'Constants';

// Utils
import { buildGnosisSafeConnector } from 'Services/web3/gnosis';
import { buildInjectedConnector } from 'Services/web3/injected';
import { buildWalletConnectConnector } from 'Services/web3/walletConnect';
import { buildCoinbaseConnector } from 'Services/web3/coinbase';

export interface Connection<T extends Connector = Connector> {
  connector: T;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

type ConnectorMap = {
  [ConnectionType.GNOSIS_SAFE]: GnosisSafe;
  [ConnectionType.WALLET_CONNECT]: WalletConnect;
  [ConnectionType.INJECTED]: MetaMask;
  [ConnectionType.COINBASE]: CoinbaseWallet;
};

export enum ConnectionType {
  GNOSIS_SAFE = 'GNOSIS_SAFE',
  WALLET_CONNECT = 'WALLET_CONNECT',
  INJECTED = 'INJECTED',
  COINBASE = 'COINBASE',
}

export const PRIORITIZED_CONNECTORS: { [key in ConnectionType]: Connection<ConnectorMap[key]> } = {
  [ConnectionType.GNOSIS_SAFE]: buildGnosisSafeConnector(),
  [ConnectionType.WALLET_CONNECT]: buildWalletConnectConnector(),
  [ConnectionType.INJECTED]: buildInjectedConnector(),
  [ConnectionType.COINBASE]: buildCoinbaseConnector(),
};

export function getConnection<T extends ConnectionType>(c: Connector | T): Connection<ConnectorMap[T]> {
  if (c instanceof Connector) {
    const connection = Object.values(PRIORITIZED_CONNECTORS).find(connection => connection.connector === c);
    if (!connection) {
      throw Error('Unsupported Connector');
    }

    return connection as Connection<ConnectorMap[T]>;
  } else {
    return PRIORITIZED_CONNECTORS[c] as Connection<ConnectorMap[T]>;
  }
}

export function onConnectionError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

export const tryActivateConnector = async (connector: Connector): Promise<ConnectionType | undefined> => {
  try {
    await connector.activate(CHAIN_ID);
    const connectionType = getConnection(connector).type;

    return connectionType;
  } catch (error) {
    console.error(error);

    return undefined;
  }
};

export const tryDeactivateConnector = async (connector: Connector): Promise<null | undefined> => {
  try {
    await connector.deactivate?.();
    await connector.resetState();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return null;
};
