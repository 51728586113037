import { FC, ReactElement } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';

// Asets
import LaptopImage from 'Assets/laptop.png';

// Components
import MapleContainer from 'Components/ds/MapleContainer';
import MapleButton from 'Components/ds/MapleButton';
import Typography from 'Components/ds/Typography';

const style: { [component: string]: SxProps } = {
  heroWrapper: { position: 'relative', overflow: 'hidden' },
  grid: { minHeight: { xs: 'auto', lg: 'calc(100vh + 32px)' } },
  textWrapper: { height: { xs: 'auto', lg: '100%' } },
  typography: { textAlign: { xs: 'center', lg: 'left' } },
  buttonsWrapper: { pt: 3 },
  imageWrapper: {
    height: '600px',
    transform: {
      lg: 'translateX(450px)',
      xl: 'translateX(228px)',
    },
    position: 'absolute',
    marginTop: 'calc((100vh - 700px)/2)',
    top: 0,
    right: 0,
    display: { xs: 'none', lg: 'block' },
  },
  videoWrapper: {
    position: 'absolute',
    top: '22px',
    left: '102px',
    width: '800px',
    height: '530px',
    overflow: 'hidden',
    video: { width: '100%', transform: 'translateY(-135px)' },
  },
};

const Hero: FC = (): ReactElement => {
  return (
    <Box sx={style.heroWrapper}>
      <MapleContainer maxWidth='xl' sx={{ mt: { xs: 10, lg: -13 }, mb: { xs: 10, lg: 0 } }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12} lg={7} xl={7} sx={style.grid}>
            <Stack direction='column' spacing={3} justifyContent='center' sx={style.textWrapper}>
              <Typography variant='displayLarge' weight='extraBold' sx={{ color: 'ds.neutral.900' }}>
                Check back soon
              </Typography>
              <Typography variant='paragraphLarge' weight='regular' sx={{ color: 'ds.neutral.700', maxWidth: '70%' }}>
                Access to the Maple WebApp is currently disabled while we prepare a new feature launch. We’ll be live
                again today, so please check back soon. If you have any questions or concerns, reach out below.
              </Typography>
              <MapleButton color='secondary' href='mailto:contact@maple.finance' sx={{ maxWidth: '180px' }}>
                Contact Maple
              </MapleButton>
            </Stack>
          </Grid>
        </Grid>
      </MapleContainer>
      <Box sx={style.imageWrapper}>
        <img src={LaptopImage} height='100%' />
        <Box sx={style.videoWrapper}>
          <video muted={true} playsInline={false} loop={true} autoPlay={true}>
            <source
              src='https://videos.ctfassets.net/qnx1f79oa09i/36Nd5KqWq9RCuoVQWLnn43/10ec3a3187125cf5e4ef2ad47f57e606/Homepage_Header_Desktop_1080p.mp4'
              type='video/mp4'
            />
            Your browser does not support the video tag.
          </video>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
