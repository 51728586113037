import Dialog, { DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Components
import MapleButton from 'Components/ds/MapleButton';

export interface ModalProps extends DialogProps {
  disableClose?: boolean;
  width?: number;
}

const StyledDialog = styled(Dialog)<ModalProps>(({ width }) => ({
  '.MuiModal-backdrop': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '.MuiDialog-paper': {
    borderRadius: '12px',
    ...(width ? { width: `${width}px` } : { width: '600px' }),
  },
}));

const CloseButton = styled(MapleButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(5),
  right: theme.spacing(5),
  padding: 0,
  minWidth: 'unset',
  width: '36px',
  height: '36px !important',
  borderRadius: '18px',
  span: {
    margin: 0,
  },
  zIndex: 99999,
}));

const Modal = ({ children, onClose, disableClose, ...props }: ModalProps) => {
  return (
    <StyledDialog
      onClose={disableClose ? undefined : onClose}
      disableEscapeKeyDown={disableClose}
      maxWidth={false}
      {...props}
    >
      {!disableClose && (
        <CloseButton
          variant='outlined'
          color='tertiary'
          onClick={onClose as () => void}
          endIcon={<CloseIcon />}
          shadow='medium'
          data-testid='modal-close-btn'
          className='modal-close-btn'
        />
      )}
      {children}
    </StyledDialog>
  );
};

export default Modal;
