import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

// Font
import { ValueSansPro, Nexa } from 'Components/ds/MapleTheme/typography';

export interface StyledTypographyProps extends TypographyProps {
  weight?: 'light' | 'regular' | 'medium' | 'bold' | 'extraBold';
  decoration?: 'underline' | 'lineThrough';
  component?: any;
}

export interface StyledTypographyDisplayProps extends StyledTypographyProps {
  displayvariant: StyledTypographyProps['variant'];
}

export interface StyledTypographyParagraphProps extends StyledTypographyProps {
  paragraphvariant: StyledTypographyProps['variant'];
}

const getStyles = (theme: any) => {
  const mediaQuery = theme.breakpoints.up('medium');

  return {
    default: {
      fontFamily: ValueSansPro,
      fontSize: '16px',
      lineHeight: '24px',
    },
    heading: {
      default: {
        fontFamily: Nexa,
        letterSpacing: '-0.02em',
      },
      displayLarge: {
        fontSize: '52px',
        lineHeight: '56px',
      },
      displaySmall: {
        fontSize: '44px',
        lineHeight: '48px',
      },
      h1: {
        fontSize: '36px',
        lineHeight: '44px',
        [mediaQuery]: {
          fontSize: '40px',
          lineHeight: '48px',
        },
      },
      h2: {
        fontSize: '32px',
        lineHeight: '40px',
        [mediaQuery]: {
          fontSize: '36px',
          lineHeight: '44px',
        },
      },
      h3: {
        fontSize: '28px',
        lineHeight: '36px',
        [mediaQuery]: {
          fontSize: '32px',
          lineHeight: '40px',
        },
      },
      h4: {
        fontSize: '24px',
        lineHeight: '32px',
        [mediaQuery]: {
          fontSize: '28px',
          lineHeight: '36px',
        },
      },
      h5: {
        fontSize: '20px',
        lineHeight: '28px',
        [mediaQuery]: {
          fontSize: '24px',
          lineHeight: '32px',
        },
      },
      h6: {
        fontSize: '18px',
        lineHeight: '24px',
        [mediaQuery]: {
          fontSize: '20px',
          lineHeight: '28px',
        },
      },
      h7: {
        fontSize: '16px',
        lineHeight: '24px',
        [mediaQuery]: {
          fontSize: '18px',
          lineHeight: '28px',
        },
      },
    },
    paragraph: {
      large: {
        fontSize: '18px',
        lineHeight: '28px',
      },
      medium: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      small: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      xSmall: {
        fontSize: '12px',
        lineHeight: '20px',
      },
      overline: {
        fontSize: '14px',
        lineHeight: '12px',
      },
    },
    weight: {
      light: 200,
      regular: 400,
      medium: 500,
      bold: 700,
      extraBold: 800,
    },
    decoration: {
      underline: 'underline',
      lineThrough: 'line-through',
    },
  };
};

const StyledTypographyDisplay = styled(Typography)<StyledTypographyDisplayProps>(({
  weight,
  decoration,
  theme,
  displayvariant,
}) => {
  const styles = getStyles(theme);

  return {
    ...styles.default,
    ...styles.heading.default,
    ...(displayvariant === 'displayLarge' && styles.heading.displayLarge),
    ...(displayvariant === 'displaySmall' && styles.heading.displaySmall),
    ...(weight && { fontWeight: styles.weight[weight] }),
    ...(decoration && { textDecoration: styles.decoration[decoration] }),
  };
});

const StyledTypographyParagraph = styled(Typography)<StyledTypographyParagraphProps>(({
  weight,
  decoration,
  theme,
  paragraphvariant,
}) => {
  const styles = getStyles(theme);

  return {
    ...styles.default,
    ...(paragraphvariant === 'paragraphLarge' && styles.paragraph.large),
    ...(paragraphvariant === 'paragraphMedium' && styles.paragraph.medium),
    ...(paragraphvariant === 'paragraphSmall' && styles.paragraph.small),
    ...(paragraphvariant === 'paragraphXSmall' && styles.paragraph.xSmall),
    ...(weight && { fontWeight: styles.weight[weight] }),
    ...(decoration && { textDecoration: styles.decoration[decoration] }),
  };
});

const StyledTypographyDefault = styled(Typography)<StyledTypographyProps>(({ variant, weight, decoration, theme }) => {
  const styles = getStyles(theme);
  const isHeadingVariant = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7'].includes(variant ?? '');
  const defaultStyles = {
    ...styles.default,
    ...(isHeadingVariant && styles.heading.default),
  };

  return {
    ...defaultStyles,
    ...(variant === 'h1' && styles.heading.h1),
    ...(variant === 'h2' && styles.heading.h2),
    ...(variant === 'h3' && styles.heading.h3),
    ...(variant === 'h4' && styles.heading.h4),
    ...(variant === 'h5' && styles.heading.h5),
    ...(variant === 'h6' && styles.heading.h6),
    ...(variant === 'h7' && styles.heading.h7),
    ...(variant === 'overline' && styles.paragraph.overline),
    ...(weight && { fontWeight: styles.weight[weight] }),
    ...(decoration && { textDecoration: styles.decoration[decoration] }),
  };
});

/**
 * Need to map custom variants to original variants to ensure correct HTML semantic rendering:
 * - displayX variants --> h1 variant--> <h1>
 * - paragraphX variants --> body1 variant --> <p>
 */
const CustomTypography = ({ variant, ...props }: StyledTypographyProps) => {
  if (variant?.includes('display')) return <StyledTypographyDisplay variant='h1' displayvariant={variant} {...props} />;
  if (variant?.includes('paragraph'))
    return <StyledTypographyParagraph variant='body1' paragraphvariant={variant} {...props} />;

  return <StyledTypographyDefault variant={variant} {...props} />;
};

export default CustomTypography;
