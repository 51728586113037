import { ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Info as InfoIcon } from '@mui/icons-material';

// Font
import { ValueSansPro } from 'Components/ds/MapleTheme/typography';

interface TypographyTooltipProps {
  title: string | ReactElement;
  textTooltip?: boolean;
  color?: string;
}

const StyledIconButton = styled(IconButton)(() => ({
  backgroundColor: 'transparent !important',
  width: '20px',
  height: '20px',
  '&:hover': { backgroundColor: 'transparent !important' },
}));

const PopperProps = {
  sx: {
    fontFamily: ValueSansPro,
    '.MuiTooltip-tooltip': {
      fontFamily: ValueSansPro,
      fontSize: '12px',
      lineHeight: '20px',
      backgroundColor: 'ds.text.secondary',
      opacity: '1',
    },
    '.MuiTooltip-arrow': {
      color: 'ds.text.secondary',
    },
  },
};

const TypographyTooltip = ({ title, textTooltip, color = 'ds.background.icon' }: TypographyTooltipProps) => {
  return (
    <Tooltip title={title} arrow PopperProps={PopperProps} tabIndex={-1}>
      <StyledIconButton
        aria-label='tooltip'
        sx={{ ...(textTooltip && { transform: 'translate(2px, -2px)' }), color, fill: color }}
      >
        <InfoIcon sx={{ width: '16px', height: '16px' }} />
      </StyledIconButton>
    </Tooltip>
  );
};

export default TypographyTooltip;
