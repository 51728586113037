import { BigNumber } from '@ethersproject/bignumber';
import { i18nStringifiedNumberFormat } from 'Utils/numbers';
import { parseUnits } from '@ethersproject/units';

export const formatNumber = (number: string, decimals: number) => {
  const trimmedNumber = number.trim();
  const value = trimmedNumber.replaceAll(',', '');
  const [, numberDecimals] = trimmedNumber.split('.');

  if (value.endsWith('.')) {
    const oldValue = value.slice(0, -1);

    return decimals === 0 ? i18nStringifiedNumberFormat(oldValue) : `${i18nStringifiedNumberFormat(oldValue)}.`;
  }

  if (isNaN(+value)) {
    const numberValue = value.replace(/[^\d.-]/g, '');

    return i18nStringifiedNumberFormat(numberValue, decimals);
  }

  if (numberDecimals?.length > 0 && numberDecimals?.length > decimals) {
    const oldValue = value.slice(0, -1);

    return i18nStringifiedNumberFormat(oldValue, decimals);
  }

  return i18nStringifiedNumberFormat(value, decimals);
};

export const parseUserInput = (userInputs: string, unitDecimals?: number) => {
  const userInputWithoutComas = userInputs.replaceAll(',', '');
  let value;
  if (userInputWithoutComas.endsWith('.')) {
    value = parseUnits(userInputWithoutComas.slice(0, -1), unitDecimals);
  } else {
    value = parseUnits(userInputWithoutComas, unitDecimals);
  }

  return value;
};

export const parseUserInputWithDecimals = (userInputs: string, unitDecimals: number): BigNumber => {
  const trimmedUserInputs = userInputs.trim();
  const userInputWithoutComas = trimmedUserInputs.replaceAll(',', '');
  const [, userInputDecimals] = userInputWithoutComas.split('.');
  let value;
  if (userInputWithoutComas.endsWith('.') || userInputDecimals?.length > (unitDecimals ?? 0)) {
    value = parseUnits(userInputWithoutComas.slice(0, -1), unitDecimals);
  } else {
    value = parseUnits(userInputWithoutComas, unitDecimals);
  }

  return value;
};
