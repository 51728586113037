import { BigNumber } from '@ethersproject/bignumber';

import { ValueInterface } from 'Shared/interfaces';
import { getFormattedNumber } from 'Utils/numbers';

const buildValueInterfaceFromBigNumberValues = (
  bigNumber: BigNumber,
  decimals: number,
  decimalsToShow = 0,
): ValueInterface => {
  let formatted = '';
  let parsed = '';

  if (bigNumber) {
    formatted = getFormattedNumber({ bigNumber, decimals, decimalsToShow });
    parsed = bigNumber.toString();
  }

  return {
    formatted,
    bigNumber,
    parsed,
  };
};

const buildPercentageValueInterfaceFromBigNumber = (
  bigNumber: BigNumber,
  decimals: number,
  decimalsToShow = 0,
): ValueInterface => {
  let formatted = '';
  let parsed = '';

  if (bigNumber) {
    formatted = getFormattedNumber({ bigNumber: bigNumber.mul(100), decimals, decimalsToShow });
    parsed = bigNumber.toString();
  }

  return {
    formatted, // ready to display percentage as 10%
    bigNumber, // ready to use for calculations as BigNumber.from(0.1) with 18 decimals
    parsed,
  };
};

const buildValueInterfaceFromParsedValues = (parsed: string, decimals: number, decimalsToShow = 0): ValueInterface => {
  let formatted = '';
  let bigNumber = BigNumber.from(0);

  if (parsed !== '') {
    bigNumber = BigNumber.from(parsed);
    formatted = getFormattedNumber({ bigNumber, decimals, decimalsToShow });
  }

  return {
    formatted,
    bigNumber,
    parsed,
  };
};

export {
  buildValueInterfaceFromBigNumberValues,
  buildPercentageValueInterfaceFromBigNumber,
  buildValueInterfaceFromParsedValues,
};

export const hasChanged = (current: ValueInterface, proposed: ValueInterface) => {
  return !current.bigNumber.eq(proposed.bigNumber);
};

export const isValueInterface = (val: ValueInterface | string): val is ValueInterface => {
  return (val as ValueInterface).formatted !== undefined;
};
