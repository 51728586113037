export const getAppUrl = (project = 'mainnet-prod') => {
  switch (project) {
    case 'mainnet-dev':
      return 'https://app.eth.maple-dev.finance';
    case 'mainnet-prod':
      return 'https://app.maple.finance';
    case 'base-mainnet-prod':
      return 'https://base.maple.finance';
    case 'sepolia-dev':
      return 'https://app.sepolia.eth.maple-dev.finance';
    case 'sepolia-prod':
      return 'https://app.sepolia.eth.maple.finance';
    case 'sol-devnet':
      return 'https://devnet.solana.maple.finance';
    case 'sol-mainnet':
      return 'https://solana.maple.finance';
    default:
      return 'http://localhost:3000';
  }
};

export const getWithdrawGitbookLink = (isTreasuryPool: boolean | null | undefined) => {
  return isTreasuryPool
    ? 'https://maplefinance.gitbook.io/maple/cash-management-pool/withdrawals'
    : 'https://maplefinance.gitbook.io/maple/maple-for-lenders/withdrawal-process';
};

export const getSecuritizeUrl = (project = 'mainnet-prod') => {
  switch (project) {
    case 'mainnet-dev':
    case 'mainnet-prod':
    case 'base-mainnet-prod':
      return 'https://id.securitize.io';
    case 'sepolia-dev':
    case 'sepolia-prod':
    case 'localhost':
      return 'https://id.sandbox.securitize.io';
    default:
      return 'https://id.sandbox.securitize.io';
  }
};
