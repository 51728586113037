import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

// Font
import { Nexa } from 'Components/ds/MapleTheme/typography';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  color: theme.palette.ds.text.primary,
  fontFamily: Nexa,
  fontWeight: 800,
  fontSize: '24px',
  lineHeight: '32px',
  marginBottom: theme.spacing(3),
  padding: 0,
}));

export default StyledDialogTitle;
