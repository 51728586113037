export const capitalizeWord = (word: string): string => {
  return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

export const truncateAddress = (address: string, length = 5) => {
  return `${address.substring(0, length)}...${address.substring(address.length - length, address.length)}`;
};

export const copyTextToClipboard = (text: string): Promise<void> => navigator.clipboard.writeText(text);

export const pluralize = (value: number, singular: string, plural?: string) => {
  if (!value) return '';
  if (value === 1) return singular;

  return plural || `${singular}s`; // plural is provided ("children"), regular plural ("apples") and zero cases (zero "minutes")
};

export const splitCapitals = (str: string) => {
  const parts = str.split(/(?=[A-Z])/);
  const validParts = parts.map(strSection => strSection.trim()).filter(strSection => strSection.length);

  return validParts.join(' ');
};

export const splitUnderscore = (s: any) => {
  return s.toString().replaceAll('_', ' ');
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
  const [localPart] = email.split('@');

  return emailRegex.test(email) && localPart.length <= 64;
};
