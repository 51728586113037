import { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SxProps } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Assets
import { ReactComponent as IconRight } from 'Components/ds/assets/icon-arrow-right.svg';
import { ReactComponent as IconExplorePools } from 'Components/ds/assets/icon-compass-circle.svg';

// Components
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';
import MapleIconButton from 'Components/ds/MapleIconButton';
import PoolNameLink from 'Components/PoolNameLink';

// Styles
import { shadow } from 'Components/ds/MapleTheme/designSystem';

// Utils
import { filterAndSortPoolV2Overview } from 'Utils/pool';

// Types
import { AssetSymbol } from 'Components/ds/CoinIcon';
import { useGetAllPoolsV2EarnPageQuery } from 'Graphql/schema-v2';

// Constants
import { SYRUP_IDS } from 'Constants/index';

const styles: { [key: string]: SxProps } = {
  icon: {
    width: '20px',
    height: '20px',
    border: '1px solid #EBEBF2',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'ds.primary.100',
    },
  },
  menu: {
    '.MuiPaper-root': {
      marginTop: 2,
      padding: 0,
      boxShadow: shadow.medium,
      ul: {
        padding: 0,
        li: {
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderBottomColor: 'ds.neutral.100',
          '&:last-child': {
            borderBottomWidth: '0px',
          },
        },
      },
    },
  },
};

const PoolDashboardNav = () => {
  const { data } = useGetAllPoolsV2EarnPageQuery();
  const { pathname } = useLocation();
  const { account } = useWeb3React();

  const theme = useTheme();
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  const fontVariant = isDesktopDevice ? 'h4' : 'paragraphSmall';

  const pageTitle = isDesktopDevice ? 'Explore Lending Pools' : 'Explore Pools';

  const [, , , , poolId] = pathname.split('/');

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (url: string) => {
    setAnchorEl(null);
    navigate(url);
  };

  const selectedPool = useMemo(() => {
    if (poolId && data?.poolV2S.length) {
      const pool = data?.poolV2S.find(({ id }) => id.toLowerCase() == poolId.toLowerCase());

      return pool;
    }

    return undefined;
  }, [data?.poolV2S, poolId]);

  const poolName = selectedPool?.poolMeta?.poolName ?? '';
  const safePoolName = isDesktopDevice ? poolName : poolName.substring(0, 20);
  const thePoolName = SYRUP_IDS.includes(poolId) ? 'Syrup' : safePoolName;

  const availablePools = useMemo(() => {
    if (data?.poolV2S) {
      return filterAndSortPoolV2Overview(data?.poolV2S, poolId, account);
    }

    return [];
  }, [data?.poolV2S, poolId]);

  return (
    <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
      <IconExplorePools height={24} width={24} fill='#8E8E9B' />
      <MapleLink
        href='/#/v2/lend/pools'
        variant={fontVariant}
        sx={{ color: 'ds.neutral.500' }}
        underline='none'
        fontWeight='bold'
      >
        {pageTitle}
      </MapleLink>

      {selectedPool && (
        <>
          <IconRight height={16} width={16} fill='#B0B0BF' />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            spacing={1}
            sx={{ cursor: 'pointer' }}
            onClick={(event: any) => setAnchorEl(event.currentTarget)}
          >
            <Typography variant={fontVariant} weight='bold' color='primary'>
              {thePoolName}
            </Typography>
            <MapleIconButton sx={styles.icon}>
              <ArrowDropDownIcon color='primary' />
            </MapleIconButton>
          </Stack>
        </>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)} sx={styles.menu}>
        {availablePools.map(({ id, poolMeta, delegate, asset }, i) => (
          <MenuItem key={id} onClick={() => handleClick(`/v2/lend/pool/${id}`)} disabled={i === 0}>
            <PoolNameLink
              id={id}
              poolName={poolMeta?.poolName ?? ''}
              poolDetails={`${delegate.delegateMeta?.companyName} - ${asset.symbol}`}
              poolLink={`/#/v2/lend/pool/${id}`}
              color='tertiary'
              underline={false}
              logoSize={32}
              disabled={i === 0}
              asset={asset.symbol as AssetSymbol}
            />
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default PoolDashboardNav;
