import { useRef, useLayoutEffect, useState } from 'react';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

interface GradientBoxProps extends BoxProps {
  variant: 'top' | 'bottom';
  opacity: number;
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingBottom: theme.spacing(1),
  paddingTop: `${theme.spacing(1)} !important`,
  marginTop: theme.spacing(5),
  marginBottom: `${theme.spacing(3)} !important`,
  '.modal-close-btn + &': {
    marginTop: theme.spacing(12),
  },
  '.MuiDialogTitle-root + &': {
    marginTop: theme.spacing(3),
  },
}));

const GradientBox = styled(Box)<GradientBoxProps>(({ theme, variant, opacity }) => {
  const styles = {
    top: {
      top: '0px',
      background: 'linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
      transform: `translateY(-${theme.spacing(1)})`,
    },
    bottom: {
      bottom: '0px',
      background: 'linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
      transform: `translateY(${theme.spacing(1)})`,
    },
  };

  return {
    position: 'sticky',
    top: '0px',
    zIndex: 2,
    paddingBottom: theme.spacing(5),
    pointerEvents: 'none',
    opacity,
    ...styles[variant],
  };
});

const ChildrenWrapper = styled(Stack)(({ theme }) => ({
  minHeight: '40px',
  marginTop: `-${theme.spacing(5)}`,
  marginBottom: `-${theme.spacing(5)}`,
}));

const ModalContent = ({ children, ...props }: DialogContentProps) => {
  const ref = useRef<HTMLElement>(null);
  const [displayGradientBoxTop, setDisplayGradientBoxTop] = useState(false);
  const [displayGradientBoxBottom, setDisplayGradientBoxBottom] = useState(false);

  useLayoutEffect(() => {
    if (ref.current) {
      const { clientHeight, scrollHeight } = ref.current;
      setDisplayGradientBoxBottom(clientHeight < scrollHeight);
    }
  }, [ref]);

  useLayoutEffect(() => {
    if (ref.current && displayGradientBoxBottom) {
      const { clientHeight, scrollHeight } = ref.current;
      setDisplayGradientBoxBottom(clientHeight < scrollHeight);
    }
  }, [ref, displayGradientBoxBottom]);

  const handleScroll = (target: HTMLElement) => {
    const { scrollTop, clientHeight, scrollHeight } = target;
    setDisplayGradientBoxTop(scrollTop !== 0);
    setDisplayGradientBoxBottom(scrollTop + clientHeight < scrollHeight);
  };

  return (
    <StyledDialogContent ref={ref} onScroll={({ target }) => handleScroll(target as HTMLElement)} {...props}>
      <GradientBox variant='top' opacity={+displayGradientBoxTop} />
      <ChildrenWrapper justifyContent='center'>{children}</ChildrenWrapper>
      <GradientBox variant='bottom' opacity={+displayGradientBoxBottom} />
    </StyledDialogContent>
  );
};

export default ModalContent;
