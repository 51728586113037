import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import Box from '@mui/material/Box';

// Assets
import { ReactComponent as MapleLogotype } from 'Components/ds/assets/icon-maple-logotype.svg';
import { ReactComponent as MapleBase } from 'Components/ds/assets/icon-maple-base.svg';
import { ReactComponent as MapleEth } from 'Components/ds/assets/icon-maple-eth.svg';

// Components
import MapleButton from 'Components/ds/MapleButton';
import WalletConnectButtonV2 from 'Components/WalletConnectButtonV2';
import NotificationInbox from 'Components/NotificationInbox';

// Constants
import { isBaseDeployment } from 'Constants';

// Hooks
import useAuth from 'Hooks/useAuth';

// Styles
import { zIndex } from 'Components/ds/MapleTheme/designSystem';

const MobileBar = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  backgroundColor: theme.palette.ds.background.primary,
  position: 'fixed',
  width: '100%',
  height: '56px',
  zIndex: zIndex.high,
  top: 0,
  borderBottom: `1px solid ${theme.palette.ds.neutral[200]}`,
}));

interface SidebarMobileProps {
  isExpanded: boolean;
  setIsExpanded: (val: boolean) => void;
}

const SidebarMobile = ({ isExpanded, setIsExpanded }: SidebarMobileProps) => {
  const { isAuthenticated } = useAuth();

  return (
    <MobileBar direction='row' justifyContent='space-between' alignItems='center'>
      <Link to='/v2/lend'>
        <Stack direction='row' spacing={2} alignItems='center'>
          {isBaseDeployment ? <MapleBase /> : <MapleEth />}
          <MapleLogotype />
        </Stack>
      </Link>
      <Stack direction='row' spacing={1}>
        {isAuthenticated && (
          <Box sx={{ zIndex: zIndex.high }}>
            <NotificationInbox />
          </Box>
        )}

        <WalletConnectButtonV2 showLabel={false} withIcon />
        <MapleButton
          onClick={() => setIsExpanded(!isExpanded)}
          variant='outlined'
          color='tertiary'
          size='medium'
          sx={{ pl: '12px', pr: '1px' }}
          startIcon={<MenuIcon />}
        />
      </Stack>
    </MobileBar>
  );
};

export default SidebarMobile;
