import { BigNumber } from '@ethersproject/bignumber';

// Constants
import { ZERO } from 'Constants';

// Types
import {
  Asset as AssetV2,
  BorrowerMetadataFragment,
  BorrowerState,
  LoanHistoryMetadataFragment,
  LoanBorrowerMetadataFragment,
  PoolMetaV2Fragment,
  OpenTermLoanBorrowerMetadataFragment,
  DelegateMetadataFragment,
  WithdrawalRequest,
  WithdrawalRequestStatus,
} from 'Graphql/schema-v2';

import {
  SelectInterface,
  ValueInterface,
  DateTime,
  WithdrawWindowFormatted,
  CountdownToTimestamp,
  RedeemableValues,
} from 'Shared/interfaces';

export const defaultAssetV2: AssetV2 = {
  amount: ZERO,
  chainLinkAggregator: '',
  amountAsLiquidity: ZERO,
  decimals: 0,
  id: '',
  isValidCollateralAsset: false,
  isValidLiquidityAsset: false,
  isValidPoolAsset: false,
  isValidStakeAsset: false,
  lastPriceUpdateTimestamp: ZERO,
  lastAggregatorCheckTimestamp: ZERO,
  price: ZERO,
  priceFeed: null,
  symbol: '',
};

export const defaultValueInterface: ValueInterface = {
  formatted: '',
  bigNumber: BigNumber.from(0),
  parsed: '0',
};

export const dashDefaultValueInterface: ValueInterface = {
  formatted: '-',
  bigNumber: BigNumber.from(0),
  parsed: '',
};

export const defaultSelectInterface: SelectInterface = { label: '', value: '' };

export const defaultBorrowerMetaData: BorrowerMetadataFragment = {
  _id: '',
  companyPublicName: 'NA',
  contactName: 'NA',
  ethereumAddress: 'NA',
  state: BorrowerState.Approved,
  website: 'NA',
};

export const defaultDateTime: DateTime = {
  date: '',
  time: '',
  timestamp: 0,
};

export const defaultWithdrawWindowFormatted: WithdrawWindowFormatted = {
  open: { ...defaultDateTime },
  close: { ...defaultDateTime },
};

export const defaultCountdownToTimestamp: CountdownToTimestamp = {
  days: 0,
  hours: 0,
  minutes: 0,
};

export const defaultFeesV2 = {
  delegateOriginationFeeRate: { ...defaultValueInterface },
  platformOriginationFeeRate: { ...defaultValueInterface },
  originationFeesRate: { ...defaultValueInterface },
  delegateServiceFeeRate: { ...defaultValueInterface },
  platformServiceFeeRate: { ...defaultValueInterface },
  borrowerFeesRate: { ...defaultValueInterface },
  delegateManagementFeeRate: { ...defaultValueInterface },
  platformManagementFeeRate: { ...defaultValueInterface },
  managementFeesRate: { ...defaultValueInterface },
  lateFeeRate: { ...defaultValueInterface },
  lateInterestPremium: { ...defaultValueInterface },
  earlyPaymentFeeRate: { ...defaultValueInterface },
};

export const defaultRedeemableValues: RedeemableValues = {
  assets: { ...defaultValueInterface },
  shares: BigNumber.from(0),
};

export const defaultPoolMetaData: PoolMetaV2Fragment = {
  _id: 'id',
  hidden: true,
  overview: null,
  poolName: '',
  reportFileName: null,
  reportFileUrl: null,
  strategy: '',
  priority: 1,
  underwritingBullets: null,
  isTreasuryPool: false,
  collateralType: {
    name: '',
    color: '',
  },
  poolCategory: {
    name: '',
    description: '',
    priority: 1,
    color: '',
  },
  poolDelegate: {
    owner: '',
  },
  state: '' as any,
};

export const defaultLoanHistory: LoanHistoryMetadataFragment = {
  id: '',
  amountFunded: '1',
  borrower: {
    id: '',
    borrowerMeta: {
      _id: '',
      ethereumAddress: '',
      companyPublicName: '',
    },
  },
  claimableAmount: '1',
  collateralAmount: '1',
  collateralAsset: {
    ...defaultAssetV2,
  },
  collateralRatio: '1',
  collateralRequired: '1',
  debtLockerVersion: 0,
  delegateOriginationFee: '1',
  delegateServiceFee: '1',
  drawdownAmount: '1',
  drawdownAvailable: '1',
  earlyFeeRate: '1',
  endingPrincipal: '1',
  fundingPool: {
    id: '',
    delegateManagementFeeRate: '1',
    name: '',
    platformManagementFeeRate: '1',
    platformOriginationFeeRate: '1',
    platformServiceFeeRate: '1',
    poolMeta: {
      ...defaultPoolMetaData,
    },
  },
  fundsRedirected: '1',
  gracePeriodDays: '1',
  interestRate: '1',
  isImpaired: false,
  lateFeeRate: '1',
  lateInterestPremium: '1',
  liquidityAsset: {
    ...defaultAssetV2,
  },
  maturityDate: '1',
  name: '',
  nextPayment: '1',
  nextPaymentBreakdown: {
    id: '',
    interest: '1',
    principal: '1',
    delegateFee: '1',
    treasuryFee: '1',
    total: '1',
    loan: '',
    lateInterest: '1',
    refinanceInterest: '1',
    delegateServiceFee: '1',
    platformServiceFee: '1',
  },
  nextPaymentDue: '1',
  paymentIntervalDays: '1',
  paymentsRemaining: '1',
  principalOwed: '1',
  requestAmount: '1',
  startDate: '1',
  state: 'Active' as any,
  termDays: '1',
  transaction: {
    id: '',
    timestamp: '',
    block: '',
    gasLimit: '',
    gasPrice: '',
  },
  treasuryFees: '1',
  version: 0,
};

export const defaultLoanBorrower: LoanBorrowerMetadataFragment = {
  __typename: 'Loan',
  id: '',
  amountFunded: '1',
  borrower: {
    id: '',
    borrowerMeta: {
      _id: '',
      ethereumAddress: '',
      companyPublicName: '',
    },
  },
  claimableAmount: '1',
  collateralAmount: '1',
  collateralAsset: {
    ...defaultAssetV2,
  },
  collateralRatio: '1',
  collateralRequired: '1',
  debtLockerVersion: 0,
  delegateOriginationFee: '1',
  delegateServiceFee: '1',
  drawdownAmount: '1',
  drawdownAvailable: '1',
  earlyFeeRate: '1',
  endingPrincipal: '1',
  // fundingPool: {
  //   id: '',
  //   delegateManagementFeeRate: '1',
  //   name: '',
  //   platformManagementFeeRate: '1',
  //   platformOriginationFeeRate: '1',
  //   platformServiceFeeRate: '1',
  //   poolMeta: {
  //     hidden: true,
  //     overview: '',
  //     poolName: '',
  //     reportFileName: '',
  //     reportFileUrl: '',
  //     strategy: '',
  //     underwritingBullets: [''],
  //     poolDelegate: {
  //       aboutBusiness: '',
  //       companyName: '',
  //       companySize: 0,
  //       deckFileUrl: '',
  //     },
  //   },
  // },
  fundsRedirected: '1',
  gracePeriodDays: '1',
  initialEndingPrincipal: '1',
  initialInterestRate: '1',
  interestRate: '1',
  initialRequestAmount: '1',
  initialTermDays: '1',
  isImpaired: false,
  lateFeeRate: '1',
  lateInterestPremium: '1',
  liquidityAsset: {
    ...defaultAssetV2,
  },
  maturityDate: '1',
  name: '',
  nextPayment: '1',
  nextPaymentBreakdown: {
    id: '',
    interest: '1',
    principal: '1',
    delegateFee: '1',
    treasuryFee: '1',
    total: '1',
    loan: '',
    lateInterest: '1',
    refinanceInterest: '1',
    delegateServiceFee: '1',
    platformServiceFee: '1',
  },
  nextPaymentDue: '1',
  platformOriginationFee: '1',
  paymentIntervalDays: '1',
  paymentsRemaining: '1',
  principalOwed: '1',
  requestAmount: '1',
  startDate: '1',
  state: 'Active' as any,
  termDays: '1',
  transaction: {
    id: '',
    timestamp: '',
    block: '',
    gasLimit: '',
    gasPrice: '',
  },
  treasuryFees: '1',
  version: 0,
  impairDate: 0,
};

export const defaultOtlBorrower: OpenTermLoanBorrowerMetadataFragment = {
  __typename: 'OpenTermLoan',
  id: '',
  version: 0,
  amountFunded: '1',
  borrower: {
    id: '',
    borrowerMeta: {
      _id: '',
      ethereumAddress: '',
      companyPublicName: '',
    },
  },
  collateral: null,
  defaultDate: '1',
  delegateServiceFeeRate: '0',
  fundingDate: '1',
  fundingPool: {
    id: '',
    delegateManagementFeeRate: '1',
    name: '',
    platformManagementFeeRate: '1',
    platformOriginationFeeRate: '1',
    platformServiceFeeRate: '1',
    poolMeta: {
      ...defaultPoolMetaData,
    },
  },
  gracePeriodSeconds: '1',
  interestRate: '1',
  interestPaid: '0',
  isImpaired: false,
  isCalled: false,
  lateFeeRate: '1',
  lateInterestPaid: '0',
  lateInterestPremium: '1',
  lender: {
    id: '',
  },
  liquidityAsset: {
    ...defaultAssetV2,
  },
  nextPaymentDue: '1',
  noticePeriodSeconds: '0',
  paymentIntervalDays: '1',
  platformServiceFeeRate: '0',
  principalOwed: '1',
  requestAmount: '1',
  startDate: '1',
  impairDate: '1',
  state: 'Active' as any,
  transaction: {
    id: '',
    timestamp: '',
    block: '',
    gasLimit: '',
    gasPrice: '',
  },
  principalCalled: '0',
};

export const defaultDelegateMetadata: DelegateMetadataFragment = {
  __typename: 'DelegateMetadata',
  _id: '',
  aboutBusiness: '',
  allowList: false,
  companyName: '',
  companySize: 0,
  createdAt: '',
  deckFileName: '',
  deckFileUrl: '',
  linkedIn: '',
  name: '',
  owner: '',
  profileUrl: '',
  telegram: '',
  totalAssetsUnderManagement: null,
  twitter: '',
  updatedAt: '',
  website: '',
};

export const defaultWithdrawalRequest: WithdrawalRequest = {
  id: '',
  owner: '',
  shares: BigNumber.from(0),
  initialShares: BigNumber.from(0),
  processedShares: BigNumber.from(0),
  status: WithdrawalRequestStatus.Pending,
  createdAt: BigNumber.from(0),
  processedAt: BigNumber.from(0),
  cancelledAt: BigNumber.from(0),
};
