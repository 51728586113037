import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { initializeConnector } from '@web3-react/core';
import { Connection, ConnectionType } from 'Services/web3/connectors';

import { NETWORKS_V2_RPC_URLS } from 'Constants';

export function buildCoinbaseConnector() {
  const [web3Coinbase, coinbaseHooks] = initializeConnector<CoinbaseWallet>(
    actions =>
      new CoinbaseWallet({
        actions,
        options: {
          url: NETWORKS_V2_RPC_URLS,
          appName: 'Maple',
        },
      }),
  );

  const coinbaseConnection: Connection<CoinbaseWallet> = {
    connector: web3Coinbase,
    hooks: coinbaseHooks,
    type: ConnectionType.COINBASE,
  };

  return coinbaseConnection;
}
