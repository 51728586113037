import { TypeText } from '@mui/material/styles/createPalette';
import {
  PaletteColor,
  backgroundColors,
  destructiveColor,
  informationColor,
  lineColors,
  mapleCobalt,
  mapleOrange,
  successColor,
  textColors,
  warningColor,
  mapleButter,
  neutralColor,
} from 'Components/ds/MapleTheme/designSystem';

interface TypeTextCustom extends TypeText {
  contrast: string;
  default: string;
}

interface DefaultColor {
  icon: string;
  primary: string;
  secondary: string;
  default: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Record<string, string>;
    ds: {
      primary: PaletteColor;
      secondary: PaletteColor;
      tertiary: PaletteColor;
      neutral: PaletteColor;
      butter: PaletteColor;
      error: PaletteColor;
      success: PaletteColor;
      info: PaletteColor;
      warning: PaletteColor;
      warningAlt: PaletteColor;
      text: TypeTextCustom;
      background: DefaultColor;
      line: DefaultColor;
      border: DefaultColor;
      table: {
        highlightedLight: string;
        highlightedDark: string;
      };
    };
  }
  interface PaletteOptions {
    tertiary: Record<string, string>;
    ds: any;
  }
}

const PRIMARY = {
  light: '#FEAA5C',
  main: '#FF8802',
  medium: '#FFA035',
  dark: '#DA6D21',
  contrastText: '#FFFFFF',
};

const TERTIARY = {
  main: '#FFB800',
  light: '#FEF3CC',
};

const ERROR = {
  main: '#FF023F',
  faded: '#FFE6EC',
  fadedGrey: '#F0E6E8',
};

const SUCCESS = {
  main: '#3EB672',
  faded: '#EEF8F2',
};

const WARNING = {
  main: '#FF8802',
  text: '#161616',
  faded: '#FFF3E6',
};

const INFO = {
  main: '#16ABFA',
  faded: '#E8F7FF',
};

const palette = {
  common: { black: '#000', white: '#fff', grey: '#F5F5FA' },
  primary: { ...PRIMARY },
  tertiary: { ...TERTIARY },
  error: { ...ERROR },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  warningAlt: { ...ERROR },
  info: { ...INFO },
  text: { primary: '#0F0F0F', secondary: '#B5B5C8', default: '#666666' },
  input: { default: '#9e9e9e' },
  background: {
    paper: '#FFFFFF',
    default: '#F9F9FC',
    frosted: 'rgba(255, 255, 255, 0.18)',
    transparent: 'rgba(0,0,0,0)',
  },
  loan: {
    ready: {
      main: '#FF5D02',
      light: '#FFEEE5',
    },
    unfunded: {
      main: '#16ABFA',
      light: '#E8F7FF',
    },
    drawdownFunds: {
      main: '#505DDA',
      light: '#EEEFFB',
    },
    repaymentDue: {
      main: '#FF023F',
      light: '#FFEDED',
    },
    active: {
      main: '#3EB672',
      light: '#EEF8F2',
    },
    removeCollateral: {
      main: '#52D3C4',
      light: '#EEFBF9',
    },
    matured: {
      main: '#1C1616',
      light: '#E8E8E8',
    },
    inArrears: {
      main: '#FF023F',
      light: '#FFEDED',
    },
    liquidated: {
      main: '#1C1616',
      light: '#E8E8E8',
    },
    liquidatedAndClaimed: {
      main: '#1C1616',
      light: '#E8E8E8',
    },
    defaulted: {
      main: '#1C1616',
      light: '#E8E8E8',
    },
    expired: {
      main: '#1C1616',
      light: '#E8E8E8',
    },
  },
  ds: {
    //
    // brand colors
    primary: {
      ...mapleOrange,
    },
    secondary: {
      ...mapleCobalt,
    },
    butter: {
      ...mapleButter,
    },
    tertiary: {
      ...neutralColor,
    },
    //
    // semantic colors
    success: {
      ...successColor,
    },
    info: {
      ...informationColor,
    },
    warning: {
      ...warningColor,
    },
    warningAlt: {
      ...destructiveColor,
    },
    error: {
      ...destructiveColor,
    },
    neutral: {
      ...neutralColor,
    },
    //
    // context colors
    text: {
      primary: textColors.mapleDeepBlack,
      secondary: textColors.darkLiver,
      default: textColors.lavenderGrey,
      disabled: lineColors.steel,
      contrast: backgroundColors.mapleWhite, // white text on dark background
    },
    background: {
      primary: backgroundColors.mapleWhite,
      secondary: backgroundColors.cultured,
      default: backgroundColors.ghostWhite,
      icon: textColors.lavenderGrey,
    },
    line: {
      primary: lineColors.platinum,
      secondary: lineColors.steel,
    },
    border: {
      primary: backgroundColors.ghostWhite,
      secondary: neutralColor[200],
    },
    table: {
      highlightedLight: '#FAFAFE',
      highlightedDark: '#F4F4FB',
    },
  },
};

export default palette;

// Table of translation between Figma color names and Webapp:

// Figma name                   webapp name
//
// maple orange [50-900] ------ primary     [50-900]
// maple cobalt [50-900] ------ secondary   [50-900]
// maple butter [50-900] ------ butter      [50-900]
// success      [50-900] ------ success     [50-900]
// information  [50-900] ------ info        [50-900]
// warning      [50-900] ------ warning     [50-900]
// destructive  [50-900] ------ error       [50-900]
//
// Text
// maple deep black ----- text.primary
// dark liver       ----- text.secondary
// lavender grey    ----- text.default
// maple white      ----- text.contrast
// steel            ----- text.disabled
//
// Background
// maple white    ----- background.primary
// cultured       ----- background.secondary
// ghost white    ----- background.default
// lavender grey  ----- background.icon
//
// Line
// platinum      ----- line.primary
// steel         ----- line.secondary

// Border
// ghostWhite    ----- border.primary
