import { FC, ReactElement, useState } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect-v2';
import { InjectedConnector } from '@web3-react/injected-connector';
import { MetaMask } from '@web3-react/metamask';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Assets
import { ReactComponent as MetamaskIcon } from 'Components/ds/assets/icon-metamask.svg';
import { ReactComponent as WalletConnectIcon } from 'Components/ds/assets/icon-wallet-connect.svg';
import { ReactComponent as IconLink } from 'Components/ds/assets/icon-external-link.svg';
import { ReactComponent as CopyIcon } from 'Components/ds/assets/icon-copy.svg';
import { ReactComponent as IconSuccess } from 'Components/ds/assets/icon-check-circle.svg';

// Components
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';
import MapleButton from 'Components/ds/MapleButton';
import Stat from 'Components/ds/Stat';
import ModalContent from 'Components/ds/ModalContent';
import ModalHeader from 'Components/ds/ModalHeader';
import ModalFooter from 'Components/ds/ModalFooter';

// Constants
import { CHAIN_ID_TO_NETWORK, etherscanUrl } from 'Constants';
import { gradient } from 'Components/ds/MapleTheme/designSystem';

// Hooks
import useConnectWalletV2 from 'Hooks/useConnectWalletV2';

// Utils
import { copyTextToClipboard, truncateAddress } from 'Utils/string';

const getStyles = (theme: Theme): { [element: string]: SxProps } => ({
  statsWrapper: { background: gradient.linear1, p: 2.5, borderRadius: '8px' },
  connected: { '&:before': { content: '"• "', color: theme.palette.ds.success[500] } },
  address: {
    borderRadius: '6px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'ds.neutral.200',
    height: '40px',
    px: 1.5,
    width: 'auto',
  },
  copied: { minWidth: '78px' },
  copyButtonWrapper: { minWidth: '78px', textAlign: 'right' },
  copyButton: { height: 'auto', padding: '0', width: 'auto', minWidth: 'auto' },
});

const WalletAccountModalV2: FC = (): ReactElement => {
  const { disconnectWallet, changeWallet } = useConnectWalletV2();
  const { connector, account, chainId } = useWeb3React<Web3Provider>();
  const [accountCopied, setAccountCopied] = useState(false);
  const theme = useTheme();
  const styles = getStyles(theme);
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  const handleCopy = () => {
    copyTextToClipboard(account || '');
    setAccountCopied(true);
  };

  const getLabel = () => {
    if (connector instanceof InjectedConnector || connector instanceof MetaMask) {
      return (
        <Stack direction='row' spacing={1}>
          <MetamaskIcon />
          <Typography variant='h6' component='span' color='ds.text.contrast' weight='extraBold'>
            Metamask
          </Typography>
        </Stack>
      );
    } else if (connector instanceof WalletConnect) {
      return (
        <Stack direction='row' spacing={1}>
          <WalletConnectIcon />
          <Typography variant='h6' component='span' color='ds.text.contrast' weight='extraBold'>
            Wallet Connect
          </Typography>
        </Stack>
      );
    } else return null;
  };

  const getNetwork = () => {
    if (!chainId) return '';

    return CHAIN_ID_TO_NETWORK[chainId];
  };

  return (
    <>
      <ModalHeader withLogo>
        <Typography variant={isDesktopDevice ? 'h4' : 'h6'} weight='extraBold'>
          Your Wallet Account
        </Typography>
      </ModalHeader>
      <ModalContent>
        <Stack direction='column' spacing={{ xs: 3, sm: 5 }}>
          <Box sx={styles.statsWrapper}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 2, sm: 5 }}>
              {isDesktopDevice && <Jazzicon diameter={50} seed={jsNumberForAddress(account || '')} />}
              <Stat
                label='Status'
                value={
                  <Typography
                    variant='h6'
                    component='span'
                    color='ds.text.contrast'
                    weight='extraBold'
                    sx={styles.connected}
                  >
                    Connected
                  </Typography>
                }
                color='contrast'
                size={isDesktopDevice ? 's' : 'xs'}
              />
              <Stat label='Network' value={getNetwork()} color='contrast' size='s' />
              <Stat label='Status' value={getLabel() || ''} color='contrast' size='s' />
            </Stack>
          </Box>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            alignItems='flex-end'
            justifyContent='space-between'
          >
            <Stack direction='column' spacing={0.5} sx={{ width: '100%' }}>
              <Typography variant='paragraphSmall' component='span'>
                Your Wallet Address
              </Typography>
              <Stack direction='row' justifyContent='space-between' alignItems='center' sx={styles.address}>
                <Typography variant='paragraphSmall' component='span' data-testid='wallet-address'>
                  {isDesktopDevice ? account : truncateAddress(account || '')}
                </Typography>
                {accountCopied ? (
                  <Stack direction='row' spacing={1} sx={styles.copied}>
                    <Typography variant='paragraphSmall' component='span' color='ds.success.500'>
                      Copied!
                    </Typography>
                    <IconSuccess fill={theme.palette.ds.success[500]} />
                  </Stack>
                ) : (
                  <Box sx={styles.copyButtonWrapper}>
                    <MapleButton
                      variant='text'
                      color='tertiary'
                      size='small'
                      sx={styles.copyButton}
                      onClick={handleCopy}
                    >
                      <CopyIcon />
                    </MapleButton>
                  </Box>
                )}
              </Stack>
            </Stack>
            <MapleLink
              href={`${etherscanUrl}/address/${account}`}
              target='_blank'
              rel='noreferrer'
              underline='none'
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              <MapleButton
                variant='outlined'
                color='tertiary'
                size='medium'
                endIcon={<IconLink />}
                shadow='small'
                fullWidth={!isDesktopDevice}
              >
                View on Etherscan
              </MapleButton>
            </MapleLink>
          </Stack>
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <MapleButton onClick={disconnectWallet} variant='outlined' color='error' size='medium' fullWidth>
            Disconnect Wallet
          </MapleButton>
          <MapleButton onClick={changeWallet} size='medium' fullWidth>
            Change Wallet
          </MapleButton>
        </Stack>
      </ModalFooter>
    </>
  );
};

export default WalletAccountModalV2;
