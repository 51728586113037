import { useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

// Constants
import { WEB3_CONNECT_CACHED_PROVIDER } from 'Constants';

// Hooks
import { useWalletContext } from 'Context/Wallet';

// Utils
import { ConnectionType, getConnection, tryActivateConnector, tryDeactivateConnector } from 'Services/web3/connectors';
import { clearWeb3ConnectorCache } from 'Services/web3/utils';

const useConnectWalletV2 = () => {
  const { connector, isActive } = useWeb3React<Web3Provider>();
  const [, setCurrentConnectionType] = useState<ConnectionType | null>(null);

  const { setShowModal, walletConnectContent } = useWalletContext();

  const [activatingWallet, setActivatingWallet] = useState<boolean>(false);

  const connectWallet = async (connectionType: ConnectionType) => {
    if (!isActive) {
      setActivatingWallet(true);
      localStorage.setItem(WEB3_CONNECT_CACHED_PROVIDER, connectionType);
      setShowModal(false);
      setActivatingWallet(false);

      const activation = await tryActivateConnector(getConnection(connectionType).connector);
      if (!activation) {
        return;
      }
      setCurrentConnectionType(connectionType);

      return;
    }
  };

  const deactivateWallet = async () => {
    clearWeb3ConnectorCache();

    await tryDeactivateConnector(connector);
    setCurrentConnectionType(null);
  };

  const disconnectWallet = () => {
    setShowModal(false);
    setTimeout(() => deactivateWallet(), 1);
  };

  return {
    connectWallet,
    disconnectWallet,
    changeWallet: deactivateWallet,
    activatingWallet,
    walletConnectContent,
  };
};

export default useConnectWalletV2;
