import { FC, ReactElement } from 'react';
import { SnackbarProvider } from 'notistack';
import { DynamicUserProfile } from '@dynamic-labs/sdk-react-core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Components
import PageContainer from 'Components/PageContainer';
import WalletContainerV2 from 'Components/WalletContainerV2';
import MapleNotification from 'Components/MapleNotification';
import SeoMetaTags from 'Components/SeoMetaTags';

// Context
import { AuthProvider } from 'Context/Auth';
import { WalletProvider } from 'Context/Wallet/index';
import { AnalyticsProvider } from 'Context/SegmentAnalytics';
import { TypeFormProvider } from 'Context/TypeForm';
import { Web3ContextProvider } from 'Context/Web3ContextProvider/Web3ContextProvider';
import { UserDataProvider } from 'Context/UserData/UserData';
import { AccountDataProvider } from 'Context/AccountData/AccountData';

// Apollo
import { ApolloProvider } from '@apollo/client';
import { ApolloClient } from 'Services/ApolloClient';

// Utils
import { SnackbarUtilsConfigurator } from 'Utils/notificationsController';

// MapleTheme
import MapleTheme from 'Components/ds/MapleTheme';

// Date & Time picker settings
dayjs.extend(utc);

const App: FC = (): ReactElement => {
  return (
    <MapleTheme>
      <SeoMetaTags />
      <DynamicUserProfile />
      <ApolloProvider client={ApolloClient}>
        <AuthProvider>
          <Web3ContextProvider>
            <WalletProvider>
              <AnalyticsProvider writeKey={process.env.REACT_APP_SEGMENT_KEY ?? ''}>
                <UserDataProvider>
                  <AccountDataProvider>
                    <SnackbarProvider
                      autoHideDuration={15000}
                      disableWindowBlurListener={true}
                      Components={{
                        success: MapleNotification,
                        error: MapleNotification,
                      }}
                    >
                      <SnackbarUtilsConfigurator />
                      <WalletContainerV2 />
                      <TypeFormProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <PageContainer />
                        </LocalizationProvider>
                      </TypeFormProvider>
                    </SnackbarProvider>
                  </AccountDataProvider>
                </UserDataProvider>
              </AnalyticsProvider>
            </WalletProvider>
          </Web3ContextProvider>
        </AuthProvider>
      </ApolloProvider>
    </MapleTheme>
  );
};

export default App;
