import { ApolloClient, InMemoryCache, HttpLink, DefaultOptions, ApolloLink } from '@apollo/client';
import { getAuthToken } from '@dynamic-labs/sdk-react-core';
import { setContext } from '@apollo/client/link/context';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { API_SNAPSHOT_URL, API_URL } from 'Services/ApolloClient/constants';
import { webappVersion } from 'Constants';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const authLink = setContext((_, { headers }) => {
  const dynamicJwtToken = getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: dynamicJwtToken ? `Bearer ${dynamicJwtToken}` : '',
    },
  };
});

const persistedQueriesLink = createPersistedQueryLink({ sha256 });

const httpLink = new HttpLink({ uri: `${API_URL}/v2/graphql` });

// Enable persisted queries globally
const linkChain = persistedQueriesLink.concat(httpLink);

const v2 = authLink.concat(linkChain);
const snapshot = new HttpLink({ uri: `${API_SNAPSHOT_URL}/graphql` });

export default new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.split(operation => operation.getContext()?.client === 'snapshot', snapshot, v2),
  connectToDevTools: true,
  defaultOptions,
  name: 'WebApp',
  version: webappVersion,
});
