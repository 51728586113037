import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

// Components
import PoolDelegateLogo from 'Components/PoolDelegateLogo';
import CoinIcon, { AssetSymbol } from 'Components/ds/CoinIcon';

// Types
import { CoinIconProps } from 'Components/ds/CoinIcon';

interface PoolLogoWithAssetProps {
  id: string;
  asset: AssetSymbol;
  assetSize?: CoinIconProps['size'];
  height?: number;
  width?: number | string;
}

const PoolLogoWithAsset = ({ id, asset, assetSize = 'mdLg', height = 80, width = 80 }: PoolLogoWithAssetProps) => {
  const theme = useTheme();

  return (
    <Stack direction='row' alignItems='center'>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${theme.palette.ds.neutral['100']}`,
          borderRadius: '8%',
          boxShadow: '0px 1px 2px -1px rgba(16, 24, 40, 0.10), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
          height: height,
          width: width,
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-5px',
            left: '-8px',
          }}
        >
          <CoinIcon asset={asset} size={assetSize} />
        </Box>
        <PoolDelegateLogo id={id} width={(+width * 60) / 100} height={(height * 60) / 100} />
      </Box>
    </Stack>
  );
};

export default PoolLogoWithAsset;
