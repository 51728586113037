import { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

// Constants / Types
import { ValueSansPro } from 'Components/ds/MapleTheme/typography';
import { shadow } from 'Components/ds/MapleTheme/designSystem';
import { getNetworkEnvironment, isBaseDeployment } from 'Constants/index';

// Components / Icons
import Typography from 'Components/ds/Typography';
import { ReactComponent as IconArrowDown } from 'Components/ds/assets/icon-arrow-rounded-down.svg';
import { ReactComponent as IconBase } from 'Components/ds/assets/icon-network-base.svg';
import { ReactComponent as IconEth } from 'Components/ds/assets/icon-network-eth.svg';
import { ReactComponent as IconSol } from 'Components/ds/assets/icon-network-sol.svg';

// Utils
import { getAppUrl } from 'Utils/urls';

// Styles
const fontStyling = {
  fontFamily: ValueSansPro,
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '6px',
  textDecoration: 'none',
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  ...fontStyling,
  padding: '4px',
  color: theme.palette.ds.text.primary,
  minWidth: '60px',
}));

const StyledMenu = styled(Menu)(() => ({
  ...fontStyling,
  padding: '0 !important',
  minWidth: '60px',
  border: '1px solid red',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  ...fontStyling,
  textTransform: 'uppercase',
  color: theme.palette.ds.text.primary,
  minWidth: '60px',
  backgroundColor: theme.palette.ds.neutral[50],
  boxShadow: shadow.small,
}));

// Setup
const urls = {
  eth: {
    'mainnet-dev': getAppUrl('mainnet-dev'),
    'mainnet-prod': getAppUrl('mainnet-prod'),
    'sepolia-dev': getAppUrl('sepolia-dev'),
    'sepolia-prod': getAppUrl('sepolia-prod'),
    localhost: getAppUrl('localhost'),
  },
  base: {
    'mainnet-dev': getAppUrl('base-mainnet-prod'), // todo: add mainnet-dev url
    'mainnet-prod': getAppUrl('base-mainnet-prod'),
    'sepolia-dev': getAppUrl('sepolia-dev'),
    'sepolia-prod': getAppUrl('sepolia-prod'),
    localhost: getAppUrl('localhost'),
  },
  sol: {
    'mainnet-dev': getAppUrl('sol-mainnet'),
    'mainnet-prod': getAppUrl('sol-mainnet'),
    'sepolia-dev': getAppUrl('sepolia-dev'),
    'sepolia-prod': getAppUrl('sepolia-prod'),
    localhost: getAppUrl('localhost'),
  },
};

const NetworkSelector = () => {
  const [network, setNetwork] = useState('eth');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const networks = useMemo(() => {
    const { network, environment } = getNetworkEnvironment();

    const eth = {
      logo: <IconEth />,
      label: 'ETH',
      id: 'eth',
      url: '',
    };

    const base = {
      logo: <IconBase />,
      label: 'BASE',
      id: 'base',
      url: '',
    };

    const sol = {
      logo: <IconSol />,
      label: 'SOL',
      id: 'sol',
      url: '',
    };

    if (environment === 'localhost') {
      eth.url = urls.eth.localhost;
      base.url = urls.base.localhost;
      sol.url = urls.sol.localhost;
    } else {
      const urlKey = `${network}-${environment}` as 'mainnet-dev' | 'mainnet-prod' | 'sepolia-dev' | 'sepolia-prod';
      eth.url = urls.eth[urlKey];
      base.url = urls.base[urlKey];
      sol.url = urls.sol[urlKey];
    }

    return [eth, base, sol];
  }, []);

  useEffect(() => {
    setNetwork(isBaseDeployment ? 'base' : 'eth');
  }, [window.location.origin]);

  const currentNetwork = useMemo(() => {
    const theNetwork = networks.find(({ id }) => id === network);
    if (!network || !theNetwork) {
      return {
        logo: '',
        label: '',
        id: '',
        url: '',
      };
    }

    return theNetwork;
  }, [network, networks]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (id: string) => {
    const network = networks.find(({ id: networkId }) => networkId === id);

    if (!network) return;
    window.location.href = network.url;
  };

  return (
    <>
      <StyledButton onClick={handleClick} startIcon={currentNetwork.logo} endIcon={<IconArrowDown />}>
        {currentNetwork.label}
      </StyledButton>
      <StyledMenu id='network-selector' anchorEl={anchorEl} open={open} onClose={handleClose}>
        {networks.map(({ logo, label, id }, index) => (
          <StyledMenuItem key={`select-${index}`} value={id} onClick={() => handleMenuItemClick(id)}>
            <Stack direction='row' alignItems='center'>
              {logo}
              <Typography variant='paragraphXSmall' sx={{ marginLeft: 1 }}>
                {label}
              </Typography>
            </Stack>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default NetworkSelector;
