import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core';

type Props = { children: React.ReactNode };

export const DynamicProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const onAuthSuccess = useCallback(() => {
    const queryParams = new URLSearchParams(search);
    const redirectParam = queryParams.get('redirect');

    if (redirectParam) return navigate(redirectParam);

    return navigate('/v2/lend/dashboard');
  }, [search]);

  return (
    <DynamicContextProvider
      settings={{
        environmentId: process.env.REACT_APP_DYNAMIC_ENVIRONMENT_ID!,
        initialAuthenticationMode: 'connect-only',
        logLevel: process.env.REACT_APP_PROJECT === 'localhost' ? 'DEBUG' : 'MUTE',
        appName: 'Maple Finance',
        appLogoUrl: 'https://downloads.eth.maple.finance/images/default.png',
        privacyPolicyUrl: 'https://maplefinance.gitbook.io/maple/legal/privacy-policy',
        termsOfServiceUrl: 'https://maplefinance.gitbook.io/maple/legal/interface-terms-of-use',
        shadowDOMEnabled: false,
        eventsCallbacks: { onAuthSuccess },
      }}
    >
      {children}
    </DynamicContextProvider>
  );
};
