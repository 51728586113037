import { FC, ReactElement } from 'react';
import Box from '@mui/material/Box';

// Components
import FooterDisclaimer from 'Components/FooterDisclaimer';
import SeoMetaTags from 'Components/SeoMetaTags';
import { Hero, Contact } from 'Pages/ProtocolPaused';

const OTLTransition: FC = (): ReactElement => {
  return (
    <>
      <SeoMetaTags
        title='Access to the Maple WebApp is currently disabled'
        description='We’ll be live again today, so please check back soon. If you have any questions or concerns, reach out below'
        ogImage='https://downloads.maple.loans/migration-og-metatag.jpg'
        twitterImage='https://downloads.maple.loans/migration-twitter-metatag.jpg'
      />
      <Box sx={{ backgroundColor: 'ds.background.primary' }}>
        <Hero />
        <Contact />
      </Box>
      <FooterDisclaimer />
    </>
  );
};

export default OTLTransition;
