import { ReactElement } from 'react';
import { useSnackbar } from 'notistack';

const InnerSnackbarUtilsConfigurator = (props: any) => {
  props.setUseSnackbarRef(useSnackbar());

  return null;
};

let useSnackbarRef: any;
const setUseSnackbarRef = (useSnackbarRefProp: any) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

interface MsgAndVariant {
  message?: string | ReactElement;
  title?: string;
  txId?: string;
  variant: string;
}

export const snackActions = {
  success(msg: MsgAndVariant) {
    this.toast(msg, 'success');
  },
  warning(msg: MsgAndVariant) {
    this.toast(msg, 'warning');
  },
  info(msg: MsgAndVariant) {
    this.toast(msg, 'info');
  },
  error(msg: MsgAndVariant) {
    this.toast(msg, 'error');
  },
  toast(msg: MsgAndVariant, variant = 'default') {
    useSnackbarRef?.enqueueSnackbar(msg, { variant, persist: false });
  },
};
