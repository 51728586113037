import React, { useEffect, createContext, useState, useMemo } from 'react';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Permission,
  ResourcePermission,
  useGetPermissionsLazyQuery,
  useGetUserDataQuery,
  useGetUserEmailLazyQuery,
} from 'Graphql/schema-v2';
import { PROJECT, POLLING_INTERVAL } from 'Constants/index';

type Impersonated = {
  userId: string;
  email: string;
};

type AuthContextType = {
  delegatePools: string[];
  hasPermission: (args: Array<ResourcePermission>) => boolean;
  isAuthenticated: boolean;
  loading: boolean;
  logout: () => void;
  courierAuth: string;
  impersonated?: Impersonated;
};

const defaultAuthContext: AuthContextType = {
  delegatePools: [],
  hasPermission: () => false,
  isAuthenticated: false,
  loading: false,
  logout: () => {},
  courierAuth: '',
  impersonated: undefined,
};

export const AuthContext = createContext<AuthContextType>(defaultAuthContext);

const doesUserHaveRequiredPermission = (userPerm: ResourcePermission, required: ResourcePermission) => {
  const hasRequiredResourceId = userPerm.resourceId === required.resourceId;
  const hasRequiredPermission = userPerm.permission === required.permission;

  return hasRequiredResourceId && hasRequiredPermission;
};

type Props = { children: React.ReactNode };

export const AuthProvider = ({ children }: Props) => {
  const { isAuthenticated, handleLogOut, user: dynamicUser } = useDynamicContext();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<Array<ResourcePermission>>([]);
  const [courierAuth, setCourierAuth] = useState<string>('');

  const [getPermissions, { data, error, loading }] = useGetPermissionsLazyQuery({
    pollInterval: PROJECT === 'localhost' ? POLLING_INTERVAL : undefined,
    errorPolicy: 'all',
  });

  const {
    data: userData,
    error: userError,
    loading: userLoading,
    startPolling,
    stopPolling,
  } = useGetUserDataQuery({
    variables: { id: dynamicUser?.userId ?? '' },
    skip: !dynamicUser?.userId,
    errorPolicy: 'all',
  });

  const [getImpersonatedUser, { data: impersonatedUserData }] = useGetUserEmailLazyQuery();

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const impersonateParam = queryParams.get('impersonateUserId');

    if (!impersonateParam || loading || !permissions.length) return;

    const isMapleAdmin = hasPermission([{ resourceId: '', permission: Permission.MapleAdmin }]);

    if (isMapleAdmin) {
      getImpersonatedUser({ variables: { id: impersonateParam } });
    } else {
      navigate('/v2/lend/dashboard', { replace: true });
    }
  }, [search, permissions]);

  const impersonated = useMemo(() => {
    if (impersonatedUserData?.userById) {
      return {
        userId: impersonatedUserData.userById.id,
        email: impersonatedUserData.userById.email,
      };
    }
  }, [impersonatedUserData?.userById]);

  useEffect(() => {
    if (!isAuthenticated) setPermissions([]);
    getPermissions();
  }, [isAuthenticated]);

  useEffect(() => {
    if (dynamicUser?.userId && !courierAuth) {
      startPolling(POLLING_INTERVAL);
    } else {
      stopPolling();
    }
  }, [dynamicUser?.userId, courierAuth]);

  useEffect(() => {
    setTimeout(() => setCourierAuth(userData?.userById?.courierAuth ?? ''), 1000);
  }, [userData?.userById?.courierAuth]);

  useEffect(() => {
    const thePermissions = [...(data?.myPermissions ?? [])];

    setPermissions(thePermissions);

    // eslint-disable-next-line no-console
    if (error) console.error(error);
  }, [data, error]);

  const hasPermission = (requiredPermissions: Array<ResourcePermission>) => {
    return requiredPermissions.some(required =>
      permissions.some(userPerm => doesUserHaveRequiredPermission(userPerm, required)),
    );
  };

  const delegatePools: Array<string> = useMemo(() => {
    return permissions
      .filter(({ resourceId, permission }) => Boolean(resourceId) && permission === Permission.PoolRead)
      .map(p => p.resourceId) as string[];
  }, [permissions]);

  const logout = async () => {
    await handleLogOut();
    // Redirect user to marketing page when log out.
    window.location.href = 'https://www.maple.finance/';
  };

  useEffect(() => {
    const isUnauthorized = userError?.graphQLErrors[0]?.extensions?.code === 'UNAUTHORIZED';

    if (isUnauthorized) {
      logout();
    }
  }, [userError?.graphQLErrors]);

  return (
    <AuthContext.Provider
      value={{
        delegatePools,
        hasPermission,
        isAuthenticated: isAuthenticated && !!permissions.length,
        loading: loading || userLoading,
        logout,
        courierAuth,
        impersonated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
