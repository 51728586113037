import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';

// Components
import Typography from 'Components/ds/Typography';
import MapleButton from 'Components/ds/MapleButton';
import PageIcon from 'Components/PageIcon';
import PoolNav from 'Pages/PoolDashboardV2/PoolNav';

// Icons
import { ReactComponent as IconDashboard } from 'Components/ds/assets/icon-dashboard.svg';
import { ReactComponent as IconExplorePools } from 'Components/ds/assets/icon-compass-circle.svg';
import { ReactComponent as IconLegalEntity } from 'Components/ds/assets/icon-users.svg';
import { ReactComponent as IconMaple } from 'Components/ds/assets/icon-maple-black-white.svg';
import { ReactComponent as IconCog } from 'Components/ds/assets/icon-cog.svg';
import { ReactComponent as IconUser } from 'Components/ds/assets/icon-user-outlined.svg';
import { ReactComponent as IconXMPL } from 'Components/ds/assets/icon-xmpl.svg';

// Hooks
import useAuth from 'Hooks/useAuth';

const pathNameTitleMap: { [key: string]: string } = {
  // Borrow
  '/v2/borrow': 'Borrower Dashboard',

  // Delegate
  '/v2/delegate': 'Delegate Dashboard',

  // Lending
  '/v2/lend/dashboard': 'Dashboard',
  '/v2/lend': 'Explore Lending Pools',
  '/v2/lend/pools': 'Explore Lending Pools',

  // Account Mgmt
  '/v2/lend/legal-entity': 'Legal Entity Details',
  '/v2/lend/management': 'Account Management',

  // Account Overview
  '/v2/account': 'Account Overview',
  '/v2/lend/account': 'Account Overview',

  // Other
  '/mpl': 'MPL',
  '/xmpl': 'xMPL',
};

const pathNameIconMap: { [key: string]: ReactNode } = {
  '/v2/borrow': <PageIcon size='medium' type='letter' letter='B' />,

  '/v2/delegate': <PageIcon size='medium' type='letter' letter='D' />,

  '/v2/lend/dashboard': <IconDashboard width={25} height={25} />,
  '/v2/lend': <IconExplorePools />,
  '/v2/lend/pools': <IconExplorePools />,

  '/v2/lend/legal-entity': <IconLegalEntity />,
  '/v2/lend/management': <IconCog />,

  '/v2/account': <IconUser />,
  '/v2/lend/account': <IconUser />,

  '/mpl': <IconMaple />,
  '/xmpl': <IconXMPL />,
};

const poolUrlRegex = /\/v2\/lend\/pool\/[a-zA-Z0-9]+/;

const PageTitle = () => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  const isPoolPage = useMemo(() => {
    const matches = poolUrlRegex.test(pathname);

    return matches;
  }, [pathname]);

  const { title, icon } = useMemo(() => {
    const isDelegate = pathname.includes('/v2/delegate');
    const isBorrower = pathname.includes('/v2/borrow');

    if (isDelegate) {
      return {
        title: pathNameTitleMap['/v2/delegate'],
        icon: pathNameIconMap['/v2/delegate'],
      };
    }

    if (isBorrower) {
      return {
        title: pathNameTitleMap['/v2/borrow'],
        icon: pathNameIconMap['/v2/borrow'],
      };
    }

    return {
      title: pathNameTitleMap[pathname] || '',
      icon: pathNameIconMap[pathname] || '',
    };
  }, [pathname]);

  return (
    <Stack direction='row' justifyContent='space-between' spacing={2} sx={{ width: { xs: '100%', md: 'auto' } }}>
      <Stack direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
        {isPoolPage ? (
          <PoolNav />
        ) : (
          <>
            <PageIcon size='medium' type='icon' icon={icon} />
            <Typography variant={isDesktopDevice ? 'h4' : 'h5'} weight='bold'>
              {title}
            </Typography>
          </>
        )}
      </Stack>
      {!isAuthenticated && !isDesktopDevice && (
        <MapleButton href='/#/login' size='medium'>
          Log In
        </MapleButton>
      )}
    </Stack>
  );
};

export default PageTitle;
