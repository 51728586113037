import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// Assets
import { ReactComponent as MapleLogotype } from 'Components/ds/assets/icon-maple-logotype.svg';
import { ReactComponent as MapleBase } from 'Components/ds/assets/icon-maple-base.svg';
import { ReactComponent as MapleEth } from 'Components/ds/assets/icon-maple-eth.svg';

// Components
import NetworkSelector from 'Components/NetworkSelector';

// Constants
import { isBaseDeployment } from 'Constants';

interface SidebarRoleSelectorProps {
  isExpanded: boolean;
}

const SidebarMapleIcon = ({ isExpanded }: SidebarRoleSelectorProps) => {
  const theme = useTheme();
  const isDesktopDevice = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack direction='column' spacing={1}>
      {isDesktopDevice && (
        <Stack direction='row' justifyContent={isExpanded ? 'flex-start' : 'center'}>
          <Link to='/v2/lend'>
            <Stack direction='row' spacing={2}>
              {isBaseDeployment ? <MapleBase /> : <MapleEth />}
              {isExpanded && <MapleLogotype />}
            </Stack>
          </Link>
          {isExpanded && (
            <Stack justifyContent='center' sx={{ ml: 1 }}>
              <NetworkSelector />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default SidebarMapleIcon;
