import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.ds.neutral[100],
  borderRadius: '17px',
  height: '34px',
  color: theme.palette.ds.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.ds.neutral[200],
  },
  '&:active': {
    backgroundColor: theme.palette.ds.neutral[300],
  },

  '&.tableControl': {
    height: '30px',
    width: '30px',
    padding: 0,
  },

  '&.filterOption': {
    backgroundColor: 'initial',
  },
}));

export default StyledIconButton;
