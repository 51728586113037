import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

// Components
import Typography from 'Components/ds/Typography';

type CustomLoaderSize = 'extraSmall' | 'small' | 'medium' | 'large';

export interface CustomCircularProgressProps extends CircularProgressProps {
  size?: CircularProgressProps['size'] | CustomLoaderSize;
  label?: string;
}

const sizeStyles = {
  extraSmall: {
    width: '32px',
    height: '32px',
  },
  small: {
    width: '48px',
    height: '48px',
  },
  medium: {
    width: '56px',
    height: '56px',
  },
  large: {
    width: '64px',
    height: '64px',
  },
};

const StyledBox = styled(Box)(() => ({
  position: 'relative',
  marginBottom: '12px',
}));

const CircularProgressBackground = styled(CircularProgress)<CircularProgressProps>(({ size, theme }) => {
  const customStyles = size ? sizeStyles[size.toString() as CustomLoaderSize] : {};

  return {
    position: 'absolute',
    color: theme.palette.ds.background.default,
    ...customStyles,
  };
});

const StyledCircularProgress = styled(CircularProgress)<CircularProgressProps>(({ size, theme }) => {
  const customStyles = size ? sizeStyles[size.toString() as CustomLoaderSize] : {};

  return {
    color: theme.palette.ds.primary[500],
    ...customStyles,
  };
});

const CustomCircularProgress = ({ sx, label, variant, size, ...props }: CustomCircularProgressProps) => {
  const typographyVariant = size?.toString().toLowerCase().includes('small') ? 'paragraphSmall' : 'paragraphMedium';

  return (
    <Stack alignContent='center' alignItems='center' sx={sx}>
      <StyledBox>
        <CircularProgressBackground size={size} variant='determinate' value={100} />
        <StyledCircularProgress size={size} variant={variant ?? 'indeterminate'} {...props} />
      </StyledBox>
      {label && (
        <Typography variant={typographyVariant} weight='regular' component='span'>
          {label}
        </Typography>
      )}
    </Stack>
  );
};

export default CustomCircularProgress;
