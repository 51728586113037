import { BigNumber } from '@ethersproject/bignumber';

// Schema
import { PoolV2EarnFragment, PoolV2OverviewFragment } from 'Graphql/schema-v2';

// Constants
import { RATE_DECIMALS, TEN } from 'Constants';

export function getLifetimeOrSpotApy(
  lifetimeApy: PoolV2EarnFragment['lifetimeApy'],
  spotApy: PoolV2EarnFragment['apy'],
): string {
  // Use Lifetime APY if non-zero, otherwise current APY
  if (lifetimeApy && lifetimeApy !== '0') return lifetimeApy;

  return spotApy ?? '0';
}

// Pool(s) where we wish to show Current APY instead of Monthly APY
// TODO: add feature to Dashboard to set showing current vs. monthly APY for pools
const showCurrentApy = (poolId: string): boolean => {
  return [''].includes(poolId); // e.g. [POOL_IDS.mapleDirect, POOL_IDS.highYieldUSDC1]
};

export function getMonthlyOrSpotApy(
  poolId: string,
  monthlyApy: PoolV2EarnFragment['monthlyApy'] | PoolV2OverviewFragment['monthlyApy'],
  spotApy: PoolV2EarnFragment['apy'] | PoolV2OverviewFragment['apy'],
): string {
  // Use Current APY if flagged, otherwise use Monthly APY if non-zero, or fall back to current APY
  if (showCurrentApy(poolId)) return spotApy ?? '0';

  return monthlyApy ?? '0';
}

export function getApyLabel(poolId: string): string {
  return showCurrentApy(poolId) ? 'Current APY' : '30-Day APY';
}

export function getApyTooltip(poolId: string): string {
  return showCurrentApy(poolId) ? 'The current annualized APY.' : 'The annualized average APY over the last 30 days.';
}

export function getApyDescription(poolId: string): string[] {
  return showCurrentApy(poolId)
    ? [
        'Interest rate calculated as an current return for each lender. This number takes into account all fees charged in the pool.',
        'Interest earned is automatically compounded back into the pool to maximize earnings.',
      ]
    : [
        'Interest rate calculated as an average return for each lender over the past 30 days. This number takes into account all fees charged in the pool.',
        'Interest earned is automatically compounded back into the pool to maximize earnings.',
      ];
}

function calculateGrossApy(
  apy: string,
  delegateManagementFeeRate: string,
  platformManagementFeeRate: string,
): BigNumber {
  const managementFeeRate = BigNumber.from(delegateManagementFeeRate).add(BigNumber.from(platformManagementFeeRate));
  const SCALED_ONE = TEN.pow(RATE_DECIMALS);
  const numerator = BigNumber.from(apy).mul(SCALED_ONE);
  const denominator = SCALED_ONE.sub(managementFeeRate);

  return numerator.div(denominator); // grossApy = apy / (1 - managementFeeRate)
}

interface ApyMetrics {
  managementFees: string;
  grossApy: string;
}
function calculateApyMetrics(pool: PoolV2EarnFragment): ApyMetrics {
  const { id: poolId, apy: spotApy, monthlyApy, delegateManagementFeeRate, platformManagementFeeRate } = pool;
  const apy = getMonthlyOrSpotApy(poolId, monthlyApy, spotApy);

  if (apy === '0')
    return {
      managementFees: '0',
      grossApy: '0',
    };

  const grossApy = calculateGrossApy(apy, delegateManagementFeeRate, platformManagementFeeRate);
  const managementFees = grossApy.sub(BigNumber.from(apy));

  return {
    managementFees: managementFees.toString(),
    grossApy: grossApy.toString(),
  };
}

export function getManagementFees(pool: PoolV2EarnFragment): string {
  return calculateApyMetrics(pool).managementFees;
}

export function getGrossApy(pool: PoolV2EarnFragment): string {
  return calculateApyMetrics(pool).grossApy;
}
