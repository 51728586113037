import { FC, ReactElement, useEffect } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';

// Context
import { useWalletContext } from 'Context/Wallet';
import { INTERCOM_APP_ID } from 'Constants';

// Components
import WalletAccountModalV2 from 'Components/WalletAccountModalV2';
import WalletConnectModalV2 from 'Components/WalletConnectModalV2';
import Modal from 'Components/ds/Modal';

const WalletContainerV2: FC = (): ReactElement => {
  const { isActive, account } = useWeb3React<Web3Provider>();
  const { showModal, setShowModal } = useWalletContext();

  useEffect(() => {
    if (!account) {
      (window as any).Intercom('shutdown');

      (window as any).Intercom('boot', {
        app_id: INTERCOM_APP_ID,
      });
    } else {
      (window as any).Intercom('shutdown');

      (window as any).Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        user_id: account,
      });
    }
  }, [account]);

  return (
    <Modal open={showModal} hidden={!showModal} onClose={() => setShowModal(false)} width={700}>
      {isActive ? <WalletAccountModalV2 /> : <WalletConnectModalV2 />}
    </Modal>
  );
};

export default WalletContainerV2;
