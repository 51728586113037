import { FC, useMemo, ReactNode, ReactElement } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';

import palette from 'Components/ds/MapleTheme/palette';
import typography from 'Components/ds/MapleTheme/typography';
import breakpoints from 'Components/ds/MapleTheme/breakpoints';
import componentsOverride from 'Components/ds/MapleTheme/overrides/overrides';

interface ThemeConfigProps {
  children: ReactNode;
}

const ThemeConfig: FC<ThemeConfigProps> = ({ children }: ThemeConfigProps): ReactElement => {
  const themeOptions = useMemo(
    () => ({
      palette,
      typography,
      breakpoints,
    }),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
