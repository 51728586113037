import { FC, ReactElement } from 'react';
import Stack from '@mui/material/Stack';

import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';

// Types/Contants
import { etherscanUrl } from 'Constants';
import { StyledTypographyProps } from 'Components/ds/Typography';

// Utils
import { truncateAddress } from 'Utils/string';

export interface AddressLinkProps {
  address: string;
  copy?: string;
  variant?: StyledTypographyProps['variant'];
  justifyContent?: 'flex-start' | 'center' | 'flex-end';
  component?: 'span' | 'div';
  onClick?: () => void;
}

const AddressLink: FC<AddressLinkProps> = ({ address, copy, variant, onClick }: AddressLinkProps): ReactElement => {
  return (
    <>
      <Stack component='span' direction='row' sx={{ display: 'inline-block' }}>
        <Typography variant={variant} fontWeight='bold' component='span'>
          {copy}
        </Typography>

        {address.length > 0 && (
          <MapleLink
            href={`${etherscanUrl}/address/${address}`}
            target='_blank'
            rel='noreferrer'
            link='info'
            variant={variant}
            sx={{ padding: '0 3px !important' }}
            onClick={onClick}
          >
            {`(${truncateAddress(address, 4)})`}
          </MapleLink>
        )}
      </Stack>
    </>
  );
};

export default AddressLink;
