import { ReactElement, useState, memo, useCallback } from 'react';
import { Widget } from '@typeform/embed-react';
import Stack from '@mui/material/Stack';

// Components
import MapleButton from 'Components/ds/MapleButton';
import Typography from 'Components/ds/Typography';
import MapleLink from 'Components/ds/MapleLink';
import Loader from 'Components/ds/Loader';
import Modal from 'Components/ds/Modal';
import ModalHeader from 'Components/ds/ModalHeader';
import ModalContent from 'Components/ds/ModalContent';
import ModalFooter from 'Components/ds/ModalFooter';

// Types
import { HiddenData } from 'Context/TypeForm/TypeForm';

interface TypeformDialogProps {
  isFormDialogOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  hasError: boolean;
  isReady: boolean;
  widgetId: string;
  widgetHiddenData: HiddenData;
  onSubmit: (widgetId: string, isSubmitted: boolean) => void;
  onReady: () => void;
}

interface MemoizedWidgetProps {
  isReady: boolean;
  widgetId: string;
  widgetHiddenData: HiddenData;
  onSubmit: () => void;
  onReady: () => void;
}

const MemoizedWidget = memo(({ isReady, widgetId, widgetHiddenData, onSubmit, onReady }: MemoizedWidgetProps) => {
  return (
    <Widget
      id={widgetId}
      style={{ height: isReady ? '500px' : '0px' }}
      onSubmit={onSubmit}
      hidden={widgetHiddenData}
      onReady={onReady}
      redirectTarget='_blank'
    />
  );
});

const TypeformDialog = ({
  isFormDialogOpen,
  onClose,
  isLoading,
  hasError,
  isReady,
  widgetId,
  widgetHiddenData,
  onSubmit,
  onReady,
}: TypeformDialogProps): ReactElement => {
  const [footerButtonCopy, setFooterButtonCopy] = useState('Cancel');

  const handleOnClose = useCallback(() => {
    onClose();
    setFooterButtonCopy('Cancel');
  }, [onClose, setFooterButtonCopy]);

  const handleSubmit = useCallback(() => {
    onSubmit(widgetId, true);
    setFooterButtonCopy('Close');
  }, [onSubmit, widgetId, setFooterButtonCopy]);

  return (
    <Modal fullWidth width={840} scroll='body' open={isFormDialogOpen} onClose={handleOnClose} disableClose={!hasError}>
      {isLoading && (
        <ModalContent>
          <Stack direction='row' justifyContent='center'>
            <Loader size='large' label='Loading form' />
          </Stack>
        </ModalContent>
      )}

      {!hasError && (
        <ModalContent>
          <MemoizedWidget
            widgetId={widgetId}
            onSubmit={handleSubmit}
            widgetHiddenData={widgetHiddenData}
            onReady={onReady}
            isReady={isReady}
          />
        </ModalContent>
      )}

      {hasError && (
        <>
          <ModalHeader>
            <Typography variant='h4' weight='extraBold'>
              Something went wrong
            </Typography>
          </ModalHeader>
          <ModalContent>
            <Stack direction='row' justifyContent='center'>
              <Typography color='ds.neutral.500'>
                The form is not available. Meanwhile you could contact us{' '}
                <MapleLink link='info' href='mailto:contact@maple.finance'>
                  here
                </MapleLink>
                .
              </Typography>
            </Stack>
          </ModalContent>
        </>
      )}

      <ModalFooter>
        <Stack direction='row' justifyContent='center'>
          <MapleButton variant='outlined' onClick={handleOnClose} size='medium' sx={{ width: '150px' }}>
            {footerButtonCopy}
          </MapleButton>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};

export default TypeformDialog;
