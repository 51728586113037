import { FC, ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material/styles';

// Assets
import { ReactComponent as IconDiscord } from 'Components/ds/assets/icon-contact-discord.svg';
import { ReactComponent as IconMessage } from 'Components/ds/assets/icon-contact-message.svg';
import { ReactComponent as IconTelegram } from 'Components/ds/assets/icon-contact-telegram.svg';
import { ReactComponent as IconTwitter } from 'Components/ds/assets/icon-contact-twitter.svg';

// Components
import MapleLink from 'Components/ds/MapleLink';

type Icon = 'message' | 'twitter' | 'telegram' | 'discord';

interface ContactIconProps {
  icon: Icon;
}

const getStyles = (theme: Theme, icon: Icon): SxProps => ({
  width: '40px',
  height: '40px',
  svg: {
    fill: theme.palette.ds.neutral[300],
  },
  ...(icon === 'twitter' && {
    '&:hover': {
      svg: {
        fill: '#1DA1F2',
      },
    },
  }),
  ...(icon === 'message' && {
    '&:hover': {
      svg: {
        fill: theme.palette.ds.primary[500],
      },
    },
  }),
  ...(icon === 'discord' && {
    '&:hover': {
      svg: {
        fill: '#7289DA',
      },
    },
  }),
  ...(icon === 'telegram' && {
    '&:hover': {
      svg: {
        fill: 'url(#telegram-gradient) #2AABEE',
      },
    },
  }),
});

const ContactIcon: FC<ContactIconProps> = ({ icon }: ContactIconProps): ReactElement | null => {
  const theme = useTheme();
  const styles = getStyles(theme, icon);

  if (icon === 'discord') {
    return (
      <MapleLink sx={styles} href='https://discord.com/invite/Xy6sqxRhFG' target='_blank' rel='noreferrer'>
        <IconDiscord />
      </MapleLink>
    );
  }

  if (icon === 'message') {
    return (
      <MapleLink sx={styles} href='mailto:contact@maple.finance'>
        <IconMessage />
      </MapleLink>
    );
  }

  if (icon === 'telegram') {
    return (
      <MapleLink sx={styles} href='https://t.me/maplefinance' target='_blank' rel='noreferrer'>
        <IconTelegram />
      </MapleLink>
    );
  }

  if (icon === 'twitter') {
    return (
      <MapleLink sx={styles} href='https://twitter.com/maplefinance' target='_blank' rel='noreferrer'>
        <IconTwitter />
      </MapleLink>
    );
  }

  return null;
};

export default ContactIcon;
