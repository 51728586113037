import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { styled, Theme } from '@mui/material/styles';
import { PaletteColor, shadow } from 'Components/ds/MapleTheme/designSystem';

// Triangle Asset
import { ReactComponent as TriangleInner } from 'Components/ds/assets/icon-triangle-inner.svg';
import { ReactComponent as TriangleWrapper } from 'Components/ds/assets/icon-triangle-wrapper.svg';

// Styles
import { shadow as Shadow, zIndex } from 'Components/ds/MapleTheme/designSystem';

export interface Icon3DProps extends BoxProps {
  icon: ReactNode;
  borderintensity?: keyof PaletteColor;
  intensity?: keyof PaletteColor;
  shape?: 'square' | 'circle' | 'triangle';
  shadow?: keyof typeof Shadow;
  size?: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'error' | 'info' | 'warning' | 'warningAlt' | 'success' | 'butter';
  innercircle?: 'primary' | 'neutral';
  innercircleSize?: 'xxSmall' | 'xSmall' | 'small' | 'smallMedium' | 'medium' | 'large';
}

interface InnerCircleProps {
  size?: Icon3DProps['innercircleSize'];
  color?: Icon3DProps['innercircle'];
}

interface BorderBoxProps {
  borderintensity: keyof PaletteColor;
  shape?: 'square' | 'circle' | 'triangle';
  shadow?: keyof typeof Shadow;
  size?: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'error' | 'info' | 'warning' | 'warningAlt' | 'success' | 'butter';
}

interface StyledBoxProps {
  borderintensity?: keyof PaletteColor;
  intensity?: keyof PaletteColor;
  shape?: 'square' | 'circle' | 'triangle';
  shadow?: keyof typeof Shadow;
  size?: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'error' | 'info' | 'warning' | 'warningAlt' | 'success' | 'butter';
  innercircle?: Icon3DProps['innercircle'];
}

const getBorderElementSize = (theme: Theme, size: BorderBoxProps['size']) => {
  let themeSize = theme.spacing(8 + 1.5);
  if (size === 'xxSmall') {
    themeSize = theme.spacing(1.5 + 0.2);
  }
  if (size === 'xSmall') {
    themeSize = theme.spacing(3 + 0.5);
  }
  if (size === 'small') {
    themeSize = theme.spacing(4 + 0.8);
  }
  if (size === 'medium') {
    themeSize = theme.spacing(6 + 1);
  }

  return {
    minHeight: themeSize,
    minWidth: themeSize,
  };
};

const getFill = (
  theme: Theme,
  innercircle?: Icon3DProps['innercircle'],
  variant?: StyledBoxProps['variant'],
  intensity: keyof PaletteColor = 500,
): string => {
  const { ds } = theme.palette;

  if (innercircle) return ds.background.primary;
  if (variant) return ds[variant][intensity];

  return ds.text.disabled;
};

const getShape = (shape: 'square' | 'circle' | 'triangle') =>
  shape === 'square' ? { borderRadius: '6px' } : { borderRadius: '100px' };

const getSize = (theme: Theme, size: 'xxSmall' | 'xSmall' | 'small' | 'medium' | 'large') => {
  let themeSize = theme.spacing(8);
  if (size === 'xxSmall') {
    themeSize = theme.spacing(1.5);
  }
  if (size === 'xSmall') {
    themeSize = theme.spacing(3);
  }
  if (size === 'small') {
    themeSize = theme.spacing(4);
  }
  if (size === 'medium') {
    themeSize = theme.spacing(6);
  }

  return {
    minHeight: themeSize,
    maxHeight: themeSize,
    minWidth: themeSize,
    maxWidth: themeSize,
  };
};

const getSizeAndPosition = (theme: Theme, size: Icon3DProps['innercircleSize']) => {
  let themeSize = theme.spacing(6);
  if (size === 'xxSmall') {
    themeSize = theme.spacing(1);
  }
  if (size === 'xSmall') {
    themeSize = theme.spacing(2);
  }
  if (size === 'small') {
    themeSize = theme.spacing(2);
  }
  if (size === 'smallMedium') {
    themeSize = theme.spacing(3.5);
  }
  if (size === 'medium') {
    themeSize = theme.spacing(4);
  }

  return {
    minHeight: themeSize,
    maxHeight: themeSize,
    minWidth: themeSize,
    maxWidth: themeSize,
  };
};

const InnerCircle = styled(Box)<InnerCircleProps>(({ theme, color, size = 'medium' }) => ({
  boxShadow: shadow.innerXsmall,
  backgroundColor: color === 'neutral' ? theme.palette.ds.neutral[400] : theme.palette.ds.primary[500],
  position: 'absolute',
  ...getShape('circle'),
  ...getSizeAndPosition(theme, size),
}));

const StyledBox = styled(Box)<StyledBoxProps>(({
  theme,
  intensity = 500,
  shape = 'square',
  shadow = 'small',
  size = 'medium',
  variant,
  innercircle,
}) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.ds.background.primary,
    boxShadow: Shadow[shadow],
    svg: {
      zIndex: zIndex.low,
    },
    'svg path': {
      fill: getFill(theme, innercircle, variant, intensity),
      filter: innercircle ? 'none' : 'url(#inset-shadow)',
    },
    ...getShape(shape),
    ...getSize(theme, size),
  };
});

const BorderBox = styled(Box)<BorderBoxProps>(
  ({ theme, borderintensity, shape = 'square', shadow = 'small', size = 'medium', variant }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: Shadow[shadow],
    backgroundColor: variant ? theme.palette.ds[variant][borderintensity] : theme.palette.ds.text.disabled,
    ...getBorderElementSize(theme, size),
    ...getShape(shape),
  }),
);

const TriangleBox = styled(Box)<StyledBoxProps>(
  ({ theme, intensity = 500, shape = 'square', size = 'medium', variant }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...getShape(shape),
    ...getSize(theme, size),
    svg: {
      transform: 'translateY(1px)',
    },
    'svg path': {
      fill: variant ? theme.palette.ds[variant][intensity] : theme.palette.ds.text.disabled,
      filter: 'url(#inset-shadow)',
    },
    '.inner': {
      position: 'absolute',
      overflow: 'visible',
      transform: 'translateY(0)',
      path: {
        fill: theme.palette.ds.background.primary,
        filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));',
      },
      ...getSize(theme, size),
    },
    '.border': {
      position: 'absolute',
      overflow: 'visible',
      transform: 'translateY(0)',
      path: {
        fill: variant ? theme.palette.ds[variant][intensity] : theme.palette.ds.text.disabled,
        filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.1));',
      },
      ...getBorderElementSize(theme, size),
    },
  }),
);

const SvgFilter = () => (
  <>
    <svg height='0' width='0'>
      <filter id='inset-shadow'>
        {/* <!-- Shadow offset --> */}
        <feOffset dx='0' dy='0' />
        {/* <!-- Shadow blur --> */}
        <feGaussianBlur stdDeviation='1' result='offset-blur' />
        {/* <!-- Invert drop shadow to make an inset shadow--> */}
        <feComposite operator='out' in='SourceGraphic' in2='offset-blur' result='inverse' />
        {/* <!-- Cut colour inside shadow --> */}
        <feFlood floodColor='black' floodOpacity='.9' result='color' />
        <feComposite operator='in' in='color' in2='inverse' result='shadow' />
        {/* <!-- Placing shadow over element --> */}
        <feComposite operator='over' in='shadow' in2='SourceGraphic' />
      </filter>
    </svg>
  </>
);

const Icon3D = ({ icon, innercircle, innercircleSize, borderintensity, ...props }: Icon3DProps) => {
  if (props.shape === 'triangle') {
    return (
      <TriangleBox {...props}>
        {innercircle && <InnerCircle color={innercircle} size={innercircleSize || props.size} />}
        {borderintensity && <TriangleWrapper className='border' />}
        <TriangleInner className='inner' />
        {icon}
        <SvgFilter />
      </TriangleBox>
    );
  }

  if (borderintensity) {
    return (
      <BorderBox borderintensity={borderintensity} {...props}>
        <StyledBox {...props} innercircle={innercircle}>
          {icon}
          <SvgFilter />
        </StyledBox>
        {innercircle && <InnerCircle color={innercircle} size={innercircleSize || props.size} />}
      </BorderBox>
    );
  }

  return (
    <StyledBox {...props} innercircle={innercircle}>
      {icon}
      <SvgFilter />
      {innercircle && <InnerCircle color={innercircle} size={innercircleSize || props.size} />}
    </StyledBox>
  );
};

export default Icon3D;
