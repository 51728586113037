import { ReactElement, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';

// Context
import { UserDataContext } from 'Context/UserData/UserData';

// Schema
import {
  useGetAccountDataQuery,
  PoolV2PositionAccountFragment,
  AccountApyFragment,
  AccountTransactionFragment,
  AccountXmplTransactionFragment,
  TxType,
  Asset,
  // AccountMplTransactionFragment,
} from 'Graphql/schema-v2';

// Types
import { ValueInterface } from 'Shared/interfaces';
import {
  CHAINLINK_PRICE_DECIMALS,
  EMPTY_PERCENTAGE,
  ISSUANCE_RATE_DECIMALS,
  PERCENTAGE_DECIMALS,
  POLLING_INTERVAL,
  TEN,
  STABLECOIN_DECIMALS,
  ZERO,
  ZERO_USDC,
} from 'Constants';

// Utils
import { defaultValueInterface } from 'Utils/defaultValues';
import {
  buildPercentageValueInterfaceFromBigNumber,
  buildValueInterfaceFromBigNumberValues,
} from 'Utils/valueInterface';
import { getAverageAPY, getLossesIncurredUsdc, getTotalInterestEarned } from 'Utils/account';
import { getAssetDecimalsUI, getUsdcValueByAsset, isStablecoin, isWETH } from 'Utils/assets';
import { convertToExitAssets } from 'Utils/pool';
import {
  GroupedChartData,
  accumulateTransactionsByTimeframe,
  calculateStackedChartData,
  groupTransactionsByPool,
  sortPoolsByFirstNonZeroBalance,
} from 'Utils/charts';

interface PoolPositionsData {
  poolV2Positions: PoolV2PositionAccountFragment[];
  totalLendingBalance: ValueInterface;
  totalInterestEarned: ValueInterface;
}

interface StatsData {
  totalAPY: ValueInterface;
  totalLosses: ValueInterface;
}

interface TxHistoryData {
  txes: AccountTransactionFragment[];
  xMPLTxes: AccountXmplTransactionFragment[];
  migrationTxes: AccountTransactionFragment[];
  // mpltxes: AccountMplTransactionFragment[];
}

// 🧪 Testing charts //
const emptyChartData = [
  { x: 1, y: 0, y0: 0, label: '0', date: '' },
  { x: 2, y: 0, y0: 0, label: '0', date: '' },
  { x: 3, y: 0, y0: 0, label: '0', date: '' },
  { x: 4, y: 0, y0: 0, label: '0', date: '' },
  { x: 5, y: 0, y0: 0, label: '0', date: '' },
];

const defaultGroupedChartData: GroupedChartData = {
  '123': [...emptyChartData],
  '456': [...emptyChartData],
};

interface AccountData extends PoolPositionsData, StatsData, TxHistoryData {
  groupedChartData: GroupedChartData;
}

// Default Data Structures

const defaultPoolPositionsData: PoolPositionsData = {
  poolV2Positions: [],
  totalLendingBalance: defaultValueInterface,
  totalInterestEarned: defaultValueInterface,
};

const defaultStatsData: StatsData = {
  totalAPY: defaultValueInterface,
  totalLosses: defaultValueInterface,
};

const defaultTxHistoryData: TxHistoryData = {
  txes: [],
  xMPLTxes: [],
  migrationTxes: [],
  // mpltxes: [],
};

const defaultAccountData: AccountData = {
  ...defaultPoolPositionsData,
  ...defaultStatsData,
  ...defaultTxHistoryData,
  groupedChartData: {
    ...defaultGroupedChartData,
  },
};

export interface AccountDataProps {
  children: ReactNode;
}

export const AccountDataContext = createContext<AccountData>({ ...defaultAccountData });

export const AccountDataProvider = ({ children }: AccountDataProps): ReactElement => {
  const { account: web3Account } = useWeb3React<Web3Provider>();
  const { selectedWallet } = useContext(UserDataContext);
  const activeWallet = selectedWallet || web3Account;

  const { data: queryData } = useGetAccountDataQuery({
    variables: {
      id: activeWallet?.toLowerCase() || '',
      accountId: activeWallet?.toLowerCase() || '',
    },
    skip: !activeWallet,
    fetchPolicy: 'cache-first',
    pollInterval: POLLING_INTERVAL,
  });

  const [statsData, setStatsData] = useState<StatsData>({ ...defaultStatsData });
  const [poolPositionsData, setPoolPositionsData] = useState<PoolPositionsData>({ ...defaultPoolPositionsData });
  const [txHistoryData, setTxHistoryData] = useState<TxHistoryData>({ ...defaultTxHistoryData });
  const [groupedChartData, setGroupedChartData] = useState<GroupedChartData>({ ...defaultGroupedChartData });

  const calculatePoolPositionsData = (
    poolV2Positions: PoolV2PositionAccountFragment[],
    txes: AccountTransactionFragment[],
  ): PoolPositionsData => {
    const totals = poolV2Positions.reduce(
      (acc, poolPosition) => {
        const { lockedShares, shares, pool } = poolPosition;
        const { asset } = pool;

        // lending balance
        const allShares = BigNumber.from(shares).add(BigNumber.from(lockedShares));
        const allAssets = convertToExitAssets(allShares, pool);
        const decimalsAdjustment = TEN.pow(asset.decimals + CHAINLINK_PRICE_DECIMALS - STABLECOIN_DECIMALS);
        const allAssetsUsdc = allAssets.mul(asset.price).div(decimalsAdjustment);

        // lifetime interest earned
        // TODO: consider whether we should exclude interest earned (also losses) on pools where user no longer maintains current balance
        const poolV2Id = pool.id;
        const poolV1Id = pool.poolV1?.id;
        const currentPoolTxes = txes.filter(tx => tx.poolV2?.id === poolV2Id || (poolV1Id && tx.pool?.id === poolV1Id));

        const interestEarned = getTotalInterestEarned(pool, poolPosition, currentPoolTxes);

        let interestEarnedUsdc = interestEarned;

        if (isWETH(asset.symbol)) {
          interestEarnedUsdc = getUsdcValueByAsset(interestEarned, asset);
        }

        return {
          totalAssets: acc.totalAssets.add(allAssetsUsdc),
          totalInterestEarned: acc.totalInterestEarned.add(interestEarnedUsdc),
        };
      },
      { totalAssets: ZERO, totalInterestEarned: ZERO },
    );

    const totalLendingBalance = buildValueInterfaceFromBigNumberValues(
      totals.totalAssets,
      STABLECOIN_DECIMALS,
      getAssetDecimalsUI('USDC'),
    );

    const safeInterestEarned = totals.totalInterestEarned.isNegative() ? ZERO : totals.totalInterestEarned;
    const totalInterestEarned = buildValueInterfaceFromBigNumberValues(
      safeInterestEarned,
      STABLECOIN_DECIMALS,
      getAssetDecimalsUI('USDC'),
    );

    return {
      totalLendingBalance,
      totalInterestEarned,
      poolV2Positions,
    };
  };

  const calculateAverageAPY = (
    poolV2Positions: PoolV2PositionAccountFragment[],
    txes: AccountTransactionFragment[],
    accountData: AccountApyFragment,
    totalLendingBalance: ValueInterface,
  ) => {
    if (totalLendingBalance.bigNumber.eq(ZERO)) {
      return EMPTY_PERCENTAGE;
    }

    const totalApy = poolV2Positions.reduce((acc, poolPosition) => {
      const { lockedShares, shares, pool } = poolPosition;
      const { asset } = pool;

      // early return since contribution will be zero if user has no current balance
      const allShares = BigNumber.from(shares).add(BigNumber.from(lockedShares));
      if (!allShares.gt(ZERO)) return acc;

      // lending balance per pool
      const lendingBalance = convertToExitAssets(allShares, pool);
      const lendingBalanceUsdc = isStablecoin(asset.symbol)
        ? lendingBalance
        : getUsdcValueByAsset(lendingBalance, asset);

      // txes per pool
      const poolV2Id = pool.id;
      const poolV1Id = pool.poolV1?.id;
      const currentPoolTxes = txes.filter(tx => tx.poolV2?.id === poolV2Id || (poolV1Id && tx.pool?.id === poolV1Id));

      // average APY per pool
      const currentAverageAPY = getAverageAPY(
        currentPoolTxes,
        lendingBalance,
        BigNumber.from(accountData?.currentAverageApy ?? '0'),
        asset,
      );

      return acc.add(currentAverageAPY.mul(lendingBalanceUsdc));
    }, ZERO);

    const totalApyWeighted = totalApy.div(totalLendingBalance.bigNumber);

    const averageAPY = totalApyWeighted.gt(ZERO)
      ? buildPercentageValueInterfaceFromBigNumber(totalApyWeighted, ISSUANCE_RATE_DECIMALS, PERCENTAGE_DECIMALS)
      : EMPTY_PERCENTAGE;

    return averageAPY;
  };

  const calculateTotalLosses = (txes: AccountTransactionFragment[], assets: Asset[]) => {
    const totalLosses = getLossesIncurredUsdc(txes, assets);

    return buildValueInterfaceFromBigNumberValues(totalLosses, STABLECOIN_DECIMALS, getAssetDecimalsUI('USDC'));
  };

  const init = (
    poolV2Positions: PoolV2PositionAccountFragment[],
    account: AccountApyFragment,
    allTxes: AccountTransactionFragment[],
    xMPLTxes: AccountXmplTransactionFragment[],
    // mpltxes: AccountMplTransactionFragment[],
    assets: Asset[],
  ) => {
    const thePoolPositionsData = calculatePoolPositionsData(poolV2Positions, allTxes);
    const theAPY = calculateAverageAPY(poolV2Positions, allTxes, account, thePoolPositionsData.totalLendingBalance);
    const theTotalLosses = calculateTotalLosses(allTxes, assets);

    setStatsData({ totalAPY: theAPY, totalLosses: theTotalLosses });
    setPoolPositionsData(thePoolPositionsData);

    // console.log('💀 ::: inside AccountData:init', { allTxes });

    // Testing charts
    // const groupedLendingBalanceChartData = calculateLendingBalanceByPoolChartData(allTxes);
    // setGroupedChartData(groupedLendingBalanceChartData);

    // console.log('🚨🚨🚨', { groupedLendingBalanceChartData });

    // poolRecognizeLosses appears in 2 cases:
    // 1. when the user has a loss in a pool V1
    // 2. during migration on a pool V2
    // knowing the migration block by poolMigrateBalance, we can filter out the needed poolRecognizeLosses and poolReinvestInterest txes

    const poolMigrateBalanceBlockNumbers = allTxes
      .filter(tx => tx.type === TxType.PoolMigrateBalance)
      .map(tx => tx.transaction.block);
    const migrationTxes = allTxes.filter(
      tx => tx.poolV2 && poolMigrateBalanceBlockNumbers.includes(tx.transaction.block),
    );

    // Exclude poolReinvestInterest and poolRecognizeLosses which are in the migration modal
    const txesHistory = allTxes.filter(
      tx =>
        tx.type !== TxType.PoolReinvestInterest &&
        (tx.type === TxType.PoolRecognizeLosses
          ? !poolMigrateBalanceBlockNumbers.includes(tx.transaction.block)
          : true),
    );
    setTxHistoryData({ txes: txesHistory, xMPLTxes, migrationTxes }); // mplTxes ?
  };

  const initDefaultData = () => {
    setPoolPositionsData({
      ...defaultPoolPositionsData,
      totalLendingBalance: ZERO_USDC,
      totalInterestEarned: ZERO_USDC,
    });

    setStatsData({
      totalAPY: EMPTY_PERCENTAGE,
      totalLosses: ZERO_USDC,
    });
  };

  useEffect(() => {
    if (!activeWallet || !queryData?.poolV2Positions || !queryData?.account || !queryData?.txes || !queryData?.assets) {
      initDefaultData();

      return;
    }

    init(
      queryData.poolV2Positions,
      queryData.account,
      queryData.txes,
      queryData?.xMPLTxes || [],
      // queryData?.mpltxes || [],
      queryData.assets,
    );
  }, [activeWallet, queryData]);

  return (
    <AccountDataContext.Provider
      value={{
        // Pool Positions Data
        ...poolPositionsData,

        // Totals Data
        ...statsData,

        // Tx History Data
        ...txHistoryData,

        // 🧪 Testing Charts Data 🧪
        groupedChartData: {
          ...groupedChartData,
        },
      }}
    >
      {children}
    </AccountDataContext.Provider>
  );
};
